.modal-dialog {
    &.policy-wrapper {
        width: 100%;
        max-width: 850px;
        max-height: 80%;
        @media (max-width: 950px) {
            max-width: 80%;
        }
        @media (max-width: 767px) {
            max-width: 95%;
        }
        .modal-content {
            .modal-header {
                .modal-title {
                    padding: 0;
                    background-color: transparent;
                    > .modal-close {
                        top: 6px !important;
                    }
                    @media (max-width: 767px) {
                        .modal-headername {
                            font-size: 18px !important;
                        } 
                        > .modal-close {
                            top: 4px !important;
                        }
                    }
                }
            }

            .modal-body {
                color: $grey;
                padding: 40px 50px !important;
                font-size: 18px;
                max-height: calc(80vh - 84px - 104px);
                overflow-y: auto;

                @extend .lato-regular;

                .modal-content {
                    .content-section {
                        .heading {
                            font-weight: 700;
                        }
                        .content {
                            // color: $black;
                        }
                        margin-bottom: 20px;
                    }
                }
                @media (max-width: 767px) {
                    padding: 30px 25px !important;
                }
            }
            .modal-footer {
                display: flex;
                align-items: center;
                .btn-cancel {
                    color: $danger;
                    background-color: transparent !important;
                    min-width: 0;
                    padding: 0;
                    min-height: 0;
                }
                button {
                    min-width: 140px;
                    &:not(:first-child) {
                        margin-left: 28px;
                    }
                }
            }
        }
    }
}

.terms-heading{
    font-size: 20px;
    font-weight: bold;
}
.heading{
    font-size: 18px;
    font-weight: 700;
    margin-bottom:5px;
}
.terms-content{
    font-size: 18px;
    text-align: justify;
}
