.navbar-top{
    .navbar{
        padding: 0;
        .navbar-brand{
            height: 65px;
            margin: 0;
            padding: 0;
            @include flex(flex-start, center);
            .cypress-logo{
                width: 158px;
                height: auto;
                object-fit: contain;
            }
        }
        .navbar-collapse{
            .navbar-nav{
                .nav-item{
                    .navbar-link{
                        color: #000000;
                        height: 65px;
                        padding: 0 16px;
                        @include flex(center, center);
                        @include transition(all 0.3s ease);
                        &:hover, &:focus{
                            color: $brand-primary;
                        }
                        &.active{
                            color: $brand-primary;
                            background-color: $theme-table-nth;
                            @extend .lato-bold;
                        }
                    }
                    .navbar-color{
                        &:hover, &:focus{
                            color:#000000;
                        }
                    }
                    &.dropdown{
                        &.show{
                            .navbar-link{
                                .fas{
                                    @include transform(rotate(-180deg));
                                }
                            }
                        }
                        .navbar-link{
                            .nav-nameimage{
                                color: $brand-secondary;
                                margin-right: 0.5rem;
                                background-color: rgba($brand-secondary, 0.1);
                                @include circle(40px);
                                @include flex(center, center);
                            }
                            .nav-name{
                                color: $theme-text;
                                font-size: 1rem;
                                margin-right: 0.5rem;
                                @extend .lato-bold;
                            }
                            .fas{
                                color: $theme-placeholder-text;
                                font-size: 12px;
                                @include transition(all 0.3s ease);
                            }
                        }
                        .dropdown-menu{
                            width: 100%;
                            border: 0;
                            padding: 0;
                            @include border-radius(0);
                            @include box-shadow(0 7px 10px 0 rgba(0, 0, 0, 0.19));
                            .dropdown-link{
                                color: $theme-text;
                                height: 50px;
                                padding: 0 16px;
                                display: block;
                                @include flex(flex-start, center);
                                @include transition(all 0.3s ease);
                                &:hover{
                                    opacity: 0.75;
                                }
                                &:not(:last-child){
                                    border-bottom: 1px solid rgba($theme-placeholder-text, 0.2);
                                }
                                &.sign-out{
                                    color: $cinnabar;
                                }
                            }
                            .dropdown-link-color{
                                color: $theme-text;
                            }
                        }
                    }
                }
            }
        }
    }
    .disable-cursor {
        cursor: not-allowed;
    }

    .disable-link{
        pointer-events:none;
    }
}


@media (max-width: 767px) and (min-width: 320px){
    .navbar-top{
        .navbar{
            .navbar-brand{
                height: 58px;
                .cypress-logo{
                    width: 103px;
                }
            }
            .navbar-collapse{
                margin: 0 -15px;
                background-color: $white;
                @include box-shadow(0 3px 15px rgba(0,0,0,0.1));
            }
            .navigation-toggler{
                cursor: pointer;
                > i{
                    color: $theme-placeholder-text;
                }
            }
        }
    }
}