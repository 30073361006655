@import "./theme.scss";

.footer {
    margin: 0;
    padding-top: 0px;
    color: #898989;
    background: #fff;
    position: relative;
    z-index: 1;
    background-position: 100% 100%;
    background-repeat: no-repeat;
    background-size: auto;

    .upper-footer {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center;
        border-bottom: 1px solid #ebebeb;

        .upper-footer__text {
            text-align: center;
            padding-bottom: 1.125rem;
            margin: 0 1rem;
            text-transform: uppercase;
            border-bottom: 7px solid #ebebeb;
        }
        .copyRightWrapper {
            margin-bottom: 1rem;
            .copyRightLink {
                color: black;
                margin: 0rem 1.5rem 0rem 1.5rem;
            }
        }
        .upper-footer__logos {
            display: -ms-flexbox;
            display: flex;
            width: 100%;
            -ms-flex-pack: distribute;
            justify-content: space-around;
            max-width: 1500px;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        }

        .upper-footer__logos-container{
            margin: 0px 1.5rem;
        }

        .upper-footer__logo{
            width:200px;
            height:200px;
        }

        .upper-footer__logo-phone-number {
            color: #000;
            text-align: center;
            line-height: 1.5;
            margin: 1rem 0;
            a {
                color: inherit;
                padding-left: 0.5rem;
            }
        }
        
    }

    h3 {
        font-family: "Montserrat","Arial",sans-serif;
        font-weight: 700;
        color: #000;
        font-size: 1rem;
        letter-spacing: .5rem;
        text-indent: 0.5rem;
    }
}

@media (max-width: 567px) {
    .copyRightLink {
        margin: 0rem 0rem 0rem 73px !important;
        display: inline-block;
    }
}