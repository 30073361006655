.certificates-wrapper {
    .certificates-form-wrapper {
        position: relative;
        > div {
            background-color: $white;
            &:not(:last-of-type) {
                margin-bottom: 40px;
            }
            .custom-check {
                margin: 0 15px 0 0;
                .checkbox-inline {
                    margin: 0;
                }
            }
            .custom-radio {
                margin: 0;
                .radio-inline {
                    margin: 0 0 0 5px;
                }
            }
            .btn-add-nok {
                min-height: 39px;
                padding: 0 16px;
                font-weight: 400 !important;
            }
            .license-number {
                width: 50% !important;
            }
            .subdetails-wrapper {
                .subdetails-content {
                    @include flex(flex-start, center);
                }
                h5 {
                    color: $brand-primary;
                    margin: 0;
                    padding: 20px;
                    font-size: 18px;
                    text-decoration: underline;
                    @extend .lato-bold;

                    .subdetail-hl {
                        text-decoration: none;
                    }
                }
                h6 {
                    color: $theme-text;
                    margin: 0;
                    padding: 0;
                    font-size: 18px;
                    @extend .lato-bold;
                }
                .inputcard-body {
                    .inputcard-group {
                        &:first-of-type {
                            padding-top: 0px;
                        }
                    }
                }
            }
        }
        .certificates-submit-wrapper {
            bottom: 0;
            height: 90px;
            z-index: 2;
            padding: 0 5px 0 10px;
            position: static;
            margin-top: -40px;
            justify-content: space-between;
            background: none;

            &.certificates-submit-wrapper-center{
                justify-content: center;
                margin-top: 20px;

                #submit-button{
                    margin-left: 25px;
                }
            }

            .error-input-msg {
                color: red;
                font-size: 16px;
                line-height: 16px;
            }
        }
    }
}
.disable-radio {
    opacity: 0.6;
    cursor: not-allowed;

    label {
        cursor: not-allowed;
        input,
        span {
            cursor: not-allowed !important;
        }
    }
}

@media (max-width: 767px) and (min-width: 320px) {
    .certificates-wrapper {
        .certificates-form-wrapper {
            position: relative;
            > div {
                background-color: $white;
                &:not(:last-of-type) {
                    margin-bottom: 40px;
                }
                .custom-check {
                    margin: 0 15px 0 0;
                    .checkbox-inline {
                        margin: 0;
                    }
                }
                .custom-radio {
                    width: 100%;
                    div {
                        margin: 0 !important;
                        > span {
                            font-size: 14px;
                        }
                    }
                }
                .btn-add-nok {
                    padding: 0 8px;
                    font-size: 13px;
                    min-width: auto;
                    min-height: 30px;
                }
                .subdetails-wrapper {
                    h5 {
                        padding: 20px 0;
                        font-size: 15px;
                    }
                }
                .license-number {
                    width: 100% !important;
                }
            }
            .certificates-submit-wrapper {
                padding: 0 12px;
                button {
                    width: 100%;
                }
            }
        }
    }
}
@media (max-width: 575px) and (min-width: 320px) {
    .certificates-wrapper {
        .certificates-form-wrapper {
            > div {
                .custom-radio {
                    margin: 10px 0 0 0 !important;
                }
            }
        }
    }
}

.modal-open .modal .modal-program-template-slide.modal-dialog {
    max-width: 100% !important;
    width: 45%;
    background-color: #ffffff;
    padding: 30px;
    border: 1px solid transparent;
    border-radius: 4px;

    .modal-content {
        background-color: transparent;
        width: 100%;
        .modal-header {
            padding: 0;
            .close {
                margin: 0;
                padding: 5px;
                position: absolute;
                z-index: 9;
                right: 90px;
                top: 10px;
                opacity: 1;
                background-color: $brand-primary;
                span {
                    color: $white;
                    font-size: 25px;
                }
                @media (max-width: 1023px) {
                    top: 12px;
                    right: 50px;
                    span {
                        font-size: 20px;
                    }
                }
            }
        }
        .modal-body {
            color: #878787;
            padding: 10px 30px 0 !important;
            font-size: 18px;
        }
    }
}
#primaryForm,#notifierForm,#nokForm {
    .country-code-input {
        padding-left: 0px !important;
    }
    .countryCode {
        text-align: center;
        padding: 0px !important;
        color: black !important;
    }
    @media (max-width: 767px) and (min-width: 320px) {
        .choose-reason {
            padding-right: 5px;
        }
    }
}
