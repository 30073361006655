.authenication-section {
    width: 100%;
    min-height: 100vh;

    // background-color: $authenication-bg;
    .authenication-content {
        position: relative;
        min-height: 100vh;

        &.limited-height{
            min-height: 70vh !important;
            height: 75vh;
        }

        @media (max-width: 480px) {
            &.limited-height{
                min-height: 100vh !important;
                height: 100vh;
            }
        }
        .btn-auth {
            width: 50px;
            height: 50px;
            position: absolute;
            z-index: 100;
            top: 29px;
            right: 60px;
            cursor: pointer;
            font-size: 16px;
            font-weight: 500;
            line-height: 1.43;
            letter-spacing: 0.2px;
            background-color: black;
            opacity: 0.4;
            border-radius: 50%;
            &.login-admin-extra{
                display: none;
                border-radius: 50%;
            }
            @media (max-width: 480px) {
                &.btn-auth:hover::after {
                    color: white !important;
                    background-color: black;
                }
                &.login-admin-main{
                    display: none;
                }
                &.login-admin-extra{
                    display: block;
                    position: relative;
                    float: right;
                    right: 3px;
                    margin: 20px 0px;
                    border: 1px solid #e9ecef;
                    background-color: white;
                    border-radius: 50%;
                    img {
                        opacity: 0.5;
                    }
                }
            }
        }
        .btn-auth:hover::after {
            font-size: 12px;
            color: black;
            content: "Login As Admin";
            position: absolute;
            top: 14px; /* Position it vertically centered to the button */
            right: 100%; /* Position it to the right of the button */
            background-color: #fff; /* Background color for the text */
            padding: 5px 10px; /* Padding around the text */
            border: 1px solid #ccc; /* Border around the text */
            border-radius: 10px; /* Rounded corners */
            white-space: nowrap; /* Prevent text from wrapping */
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow for better visibility */
            z-index: 111; /* Ensure it appears above other elements */
            // opacity: 1 !important;
        }

        .auth-leftsection {
            // width: 40.625%;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            bottom: 0;
            z-index: 0;
            position: absolute;

            .authenication-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &:after {
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                bottom: 0;
                content: "";
                position: absolute;
                background: rgba(0, 0, 0, 0.3);
            }
        }

        .auth-rightsection {
            width: 100%;
            z-index: 1;
            position: relative;

            // width: calc(100% - 40.625%);

            .auth-content-wrapper {
                @media (min-width: 768px) {
                    padding: 40px 0;
                }

                .content-renderer {
                    > div {
                        width: 100%;
                        background-color: white;

                        @media (min-width: 768px) {
                            padding: 32px;
                            max-width: 600px;
                            border-radius: 8px;
                        }

                        @media (min-width: 992px) {
                            &.createprofile-wrapper {
                                width: 900px;
                                max-width: none;
                            }
                        }
                    }
                }

                > div {
                    .form-group {
                        width: 100%;
                        margin-bottom: 1rem;
                    }
                }

                .form-label-theme {
                    font-size: 1rem;
                    line-height: 1.1875rem;
                    margin-bottom: 1rem;
                }

                .form-input-theme {
                    height: 59px;
                    padding: 0 16px;
                    border-color: $theme-placeholder-text;

                    @include box-shadow(none);

                    @include border-radius(4px);
                }

                .theme-green {
                    width: 100%;
                    margin-top: 40px;
                }
            }
        }
    }
}

@media (max-width: 767px) and (min-width: 480px) {
    .authenication-section {
        .authenication-content {
            .auth-rightsection {
                .auth-content-wrapper {
                    > .content-renderer {
                        margin: 0 auto;
                        max-width: 480px;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) and (min-width: 320px) {
    .authenication-section {
        .authenication-content {
            .auth-rightsection {
                width: calc(100%);
                padding: 2rem 0;

                .auth-content-wrapper {
                    margin: 0 auto;
                    padding: 0 1rem;
                    max-width: none;

                    > div {
                        .form-group {
                            max-width: none;
                            margin-bottom: 32px;
                        }
                    }

                    .form-label-theme {
                        font-size: 0.875rem;
                        line-height: 1.0625rem;
                        margin-bottom: 0.625rem;
                    }

                    .form-input-theme {
                        height: 39px;
                    }

                    .theme-green {
                        width: 100%;
                        padding: 0;
                        min-height: 38px;
                        margin-top: 32px;
                    }
                }
            }
        }
    }
}
