.loader-section {
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    bottom: 0;
    height: 100vh;
    z-index: 2000;
    position: fixed;
    background-color: rgba($black, 0.3);
    &.loader-block {
        width: auto;
        height: auto;
        position: absolute;
        background-color: transparent;
        .loader {
            height: auto;
            > div {
                background-color: $white;
            }
        }
    }
    .loader {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        > div {
            width: 13px;
            height: 13px;
            margin: 32px 3px;
            background: $white;
            border-radius: 50%;
            animation: bouncing-loader 0.6s infinite alternate;
            &:nth-child(2) {
                animation-delay: 0.2s;
            }
            &:nth-child(3) {
                animation-delay: 0.4s;
            }
        }
    }
}
@keyframes bouncing-loader {
    to {
        opacity: 0.1;
        transform: translate3d(0, -16px, 0);
    }
}

.loader-section-save {
    top: 0;
    left: 0px;
    right: 0;
    width: 100vw;
    bottom: 0;
    height: 100vh;
    z-index: 2000;
    position: fixed;
    background-color: rgba($black, 0.3);
    &.loader-block {
        width: auto;
        height: auto;
        position: absolute;
        background-color: transparent;
        .loader {
            height: auto;
            > div {
                background-color: $white;
            }
        }
        .loader-save {
            height: auto;
            > div {
                background-color: $white;
            }
        }
    }
    .loader {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        > div {
            width: 13px;
            height: 13px;
            margin: 32px 3px;
            background: $white;
            border-radius: 50%;
            animation: bouncing-loader 0.6s infinite alternate;
            &:nth-child(2) {
                animation-delay: 0.2s;
            }
            &:nth-child(3) {
                animation-delay: 0.4s;
            }
        }
        .save-word {
            border-radius: none !important;
        }
    }
    .loader-save {
        height: 100%;
        position: absolute;
        z-index: 3000;
        left: 60%;
        top: 50%;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        > div {
            color: white;
            margin: 32px 3px;
            font-weight: bold;
            font-size: 30px;
            animation: bouncing-loader 0.6s infinite alternate;
        }
       
    }
}


.loader-save {
    @keyframes bouncing-loader {
        to {
           
            transform: translate3d(0, -16px, 0);
        }
    }
}