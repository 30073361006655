.signin-section {
    > form {
        .cypress-logo-wrapper {
            .cypress-logo {
                width: 247px;
                height: 69px;
                object-fit: contain;
                margin-bottom: 1rem;
            }
        }

        > h1 {
            color: $theme-text;
            font-size: 2.3rem;
            margin-bottom: 0.5rem;

            @extend .lato-bold;
        }

        p {
            font-size: 2rem;
        }

        .form-group {
            margin-bottom: 2rem;

            &.custom-check {
                margin-bottom: 0px;

                label {
                    margin: 0;
                    cursor: pointer;
                }

                .forgot-password {
                    color: $theme-text;
                    text-decoration: none;
                }
            }
        }

        .admin-header-wrapper {
            background: #ffffff;
            border-radius: 10px;
            width: 500px;
            max-width: 100%;
            padding: 1rem;
            margin-bottom: 1rem;

            .heading {
                font-size: 36px;
            }
            .sub-heading {
                font-size: 18px;
            }
        }
    }
}

// @media (max-width: 767px) and (min-width: 480px) {
//     .signin-section {
//         margin: 0 auto;
//         max-width: 480px;
//     }
// }

@media (max-width: 767px) and (min-width: 320px) {
    .signin-section {
        > form {
            .cypress-logo-wrapper {
                .cypress-logo {
                    width: 158px;
                    height: 42px;
                }
            }

            > h1 {
                font-size: 1.25rem;
            }

            > p {
                font-size: 1.125rem;
                margin-bottom: 2.5rem;
            }

            .form-group {
                &.custom-check {
                    max-width: none;
                    margin-bottom: 0;

                    .checkbox-inline {
                        font-size: 14px;
                        line-height: 14px;

                        > span {
                            width: 14px !important;
                            height: 14px !important;
                            margin-right: 5px !important;

                            > i {
                                font-size: 9px !important;
                            }
                        }
                    }

                    .forgot-password {
                        font-size: 14px;
                        line-height: 14px;
                    }
                }
            }
        }
    }
}
