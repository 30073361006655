.servicesdetails-wrapper{
    .services-available-wrapper{
        margin-top: 40px;
        .service-wrapper{
            .cardbox-wrapper{
                .cardbox-body{
                    .service-content-wrapper{
                        position: relative;
                        @media (min-width: 992px) {
                            padding-left: 340px;
                            min-height:400px;
                            max-height:500px;
                        }
                        .service-image-wrapper{
                            height: 100%;
                            margin-bottom: 24px;
                            background-color: $theme-bg;
                            @include flex(center, center);
                            @media (min-width: 992px) {
                                height:400px;
                                top: 0;
                                left: 0;
                                position: absolute;
                                margin-bottom: 0;
                            }
                            .service-image{
                                width: 300px;
                                max-width: 100%;
                                object-fit: cover;
                            }
                        }
                        .service-notes-wrapper{
                            p{
                                margin: 0 0 24px;
                                padding: 0 0 24px;
                                font-size:18px;
                                color:#000000;
                                opacity:0.7;
                            }
                            .service-notes-container{
                                display:flex;
                                justify-content:space-between;
                                @media (max-width:580px) and (min-width:250px){
                                    display:flex;
                                    flex-direction: column;
                                    justify-content: center;
                                    align-items:center;
                                }
                                .service-notes-content{
                                    label{
                                        font-size: 16px;
                                        line-height: 19px;
                                        font-weight:600;
                                    }
                                    textarea{
                                        width: 100%;
                                        resize: none;
                                        height: 120px;
                                        padding: 16px;
                                        display: block;
                                        border-color: #979797;
                                        @include border-radius(4px);
                                    }
                                    div {
                                        padding: 24px 0 0;
                                        button {
                                            max-width: 150px;
                                            min-height: 54px;
                                        }
                                    }
                                    .error-input-msg {
                                        color: red;
                                        font-size: 12px;
                                        line-height: 16px;
                                    }
                                    @media(max-width:580px) and (min-width:250px){
                                        label{
                                            margin-top:15px;
                                        }
                                        textarea{
                                            margin-bottom: 10px;;
                                        }
                                    }
                                    @media (min-width:767px){
                                        width:55%;
                                    }
                                }
                            }
                            .dropdown-container{
                                margin-right:20px;
                                .service-dropdown-heading{
                                    font-size:16px;
                                    font-weight:600;
                                    color:#000000;
                                    margin-bottom:15px;
                                }
                            }
                            .service-label{
                                padding:5px 15px;
                                border:1px solid #bdbdbd;
                                border-radius:4px;
                                background-color:#fff;
                                color:#000000;
                                opacity:0.4;
                                font-size:14px;
                            }
                            .btn-theme{
                                &:hover{
                                    opacity:1;
                                }
                            }
                            .disable-button{
                                opacity:0.5;
                                &:hover{
                                    opacity: 0.5;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.cardbox-wrapper{
    .cardbox-heading{
        .service-heading{
            font-size:21px;
            color:#000000;
        }
    }
    .service-sub-heading{
        font-size:19px;
        color:#000000;
        font-weight:600;
        margin-bottom:5px;
    }
    .service-sub-image{
        width:300px;
        margin-right:25px;
        float:left;
        object-fit:cover;
        @media (max-width:500px) and (min-width:280px){
            margin-bottom:20px;
            margin-right:0;
            float:none;
        }
    }
    .service-text{
        color:#000000;
        opacity:0.7;
        font-size:18px;
        margin-bottom:10px;
        text-align:justify;
        @media (max-width: 767px){
            font-size:16px;
        }
    }
}

@media (max-width: 767px){
    .servicesdetails-wrapper{
        .services-available-wrapper{
            .service-wrapper{
                .cardbox-wrapper{
                    .cardbox-body{
                        .service-content-wrapper{
                            .service-image-wrapper{
                                margin-bottom: 16px;
                            }
                            .service-notes-wrapper{
                                p{
                                    margin: 0 0 16px;
                                    padding: 0 0 16px;
                                    font-size: 16px;
                                }
                                .service-notes-content{
                                    label{
                                        font-size: 14px;
                                        line-height: 19px;
                                    }
                                    textarea{
                                        padding: 12px;
                                        font-size: 14px;
                                    }
                                    div {
                                        padding: 16px 0 0;
                                        button {
                                            width: 100%;
                                            padding: 0; 
                                            max-width: none;
                                            min-height: 38px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}