/* --  Transition  -- */
@mixin transition($transition) {
  transition        : $transition;
  -ms-transition    : $transition;
  -o-transition     : $transition;
  -moz-transition   : $transition;
  -webkit-transition: $transition;
}

/* --  Border Radius  -- */
@mixin border-radius($radius) {
  border-radius        : $radius;
  -o-border-radius     : $radius;
  -ms-border-radius    : $radius;
  -moz-border-radius   : $radius;
  -webkit-border-radius: $radius;
}

/* --  Transform  -- */
@mixin transform($content) {
  transform        : $content;
  -o-transform     : $content;
  -ms-transform    : $content;
  -moz-transform   : $content;
  -webkit-transform: $content;
}

/* --  Translate  -- */
@mixin translate($translateX, $translateY) {
  transform        : translate($translateX, $translateY);
  -o-transform     : translate($translateX, $translateY);
  -ms-transform    : translate($translateX, $translateY);
  -moz-transform   : translate($translateX, $translateY);
  -webkit-transform: translate($translateX, $translateY);
}

/* --  Placeholder Color  -- */
@mixin placeholder-color($color){
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    /* Firefox 18- */
    color: $color;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}

/* --  Background Size  -- */
@mixin background-size($size){
  background-size        : $size;
  -o-background-size     : $size;
  -moz-background-size   : $size;
  -webkit-background-size: $size;
}

/* --  Circle -- */
@mixin circle($size){
  width : $size;
  height: $size;
  @include border-radius($size);
}

/* --  Box Shadow  -- */
@mixin box-shadow($shadow){
  box-shadow        : $shadow;
  -moz-box-shadow   : $shadow;
  -webkit-box-shadow: $shadow;
}

/* --  User Select  -- */
@mixin user-select($select-option){
  user-select        : $select-option;
  -ms-user-select    : $select-option;
  -moz-user-select   : $select-option;
  -webkit-user-select: $select-option;
}


/* --  appearance   -- */
@mixin appearance($value){
  appearance        : $value;
  -moz-appearance   : $value;
  -webkit-appearance: $value;
}

/* --  Flex   -- */
@mixin flex($value_h, $value_v){
  display: flex;
  align-items: $value_v;
  justify-content: $value_h;
}

@mixin flex-d($value_h, $value_v, $direction){
  display: flex;
  align-items: $value_v;
  justify-content: $value_h;
  flex-direction: $direction;
}

@mixin title_h2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: $brand-primary;
  text-transform: capitalize;
}

@mixin font($ff, $fw, $fs, $color){
  font-family: $ff;
  font-weight: $fw;
  font-size: $fs;
  color: $color;
}

@mixin center($position) {
  position: absolute;
  @if $position == 'verticle' {
      top: 50% !important;
      -webkit-transform: translateY(-50%) !important;
      -ms-transform: translateY(-50%) !important;
      transform: translateY(-50%) !important;
  }
  @else if $position == 'horizontal' {
      left: 50% !important;
      -webkit-transform: translateX(-50%) !important;
      -ms-transform: translateX(-50%) !important;
      transform: translate(-50%) !important;
  }
  @else if $position == "both" {
      left: 50% !important;
      top: 50% !important;
      -webkit-transform: translate(-50%, -50%) !important;
      -ms-transform: translate(-50%, -50%) !important;
      transform: translate(-50%, -50%) !important;
  }
}

@mixin custom-scroll{
  &::-webkit-scrollbar {
    width: 0.5em;
  }

  &::-webkit-scrollbar-track {
    @include box-shadow(inset 0 0 6px rgba(0,0,0,0.3));
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
}
