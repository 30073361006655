@import '../../../assets/styles/_color.scss';

.dropzone {
  border: 1px dashed $brand-primary;
  margin-bottom: 30px;
  min-height: 185px;
  border-radius: 4px;
  background-color: $snow;
  padding: 15px;

  &.dropzone-file-reject {
    border: 2px dashed $red;
  }

  .dropzone-file-selector {
    cursor: pointer;
  }

  .dropzone-text-reject {
    padding-top: 50px;
  }

  .dropzone-text {
    color: $grey1;
    text-align: center;

    .dropzone-text-heighlight {
      @extend .theme-primary;
      text-decoration: underline;
    }
  }

  .dropzone-icon {
    color: $grey1;
    padding: 25px;
    text-align: center;
  }
}
