.navbar-bottom{
    .navbar{
        margin: 0;
        padding: 0;
        .branding-content{
            width: 100%;
            margin: 1rem 0;
            text-align: center;
            .navbar-brand{
                margin: 0;
                padding: 0;
                .cypress-logo{
                    width: 158px;
                    height: 42px;
                    object-fit: contain;
                }
            }
            > p{
                color: $theme-text;
                margin: 37px 0 0 0;
                font-size: 1rem;
                line-height: 19px;
                > a {
                    color: black;
                }          
            }  
        }
        .terms-information{
            .nav-item{
                &:not(:last-child){
                    margin-right: 45px;
                }
                .navbar-link{
                    color: $theme-text;
                    font-size: 16px;
                    @include transition(all 0.3s ease);
                    &:hover{
                        opacity: 0.75;
                    }
                }
            }
            @media (min-width: 992px) {
                left: 0;
                position: absolute;
            }
        }
        .social-media-icon{
            .nav-item{
                &:not(:last-child){
                    margin-right: 24px;
                }
                .navbar-link{
                    @include circle(43px);
                    @include transition(all 0.3s ease);
                    &:hover{
                        opacity: 0.75;
                    }
                    .fab{
                        color: $white;
                        font-size: 25px;
                    }
                    &.facebook-link{
                        background-color: $facebook-color;
                    }
                    &.yelp-link{
                        background-color: $yelp-color;
                    }
                }
            }
            @media (min-width: 992px) {
                right: 0;
                position: absolute;
            }
        }
    }
}

@media (max-width: 991px){
    .navbar-bottom{
        .navbar{
            .branding-content{
                margin: 1rem 0;
                text-align: center;
                > p{
                    color: $theme-text;
                    margin: 37px 0 0 0;
                    font-size: 14px;
                }            
            }
            .terms-information{
                margin: 24px 0 16px;
                width: 100%;
                .nav-item{
                    &:not(:last-child){
                        margin-right: 0;
                        margin-bottom: 24px;
                    }
                }
            }
            .social-media-icon{
                margin-top: 16px;
                width: 100%;
            }
        }
    }
}