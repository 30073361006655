.share-a-memory-wrapper {
    padding: 70px 150px;
    .cardbox-wrapper {
        .content-wrapper {
            padding: 48px 96px;
        }
        .cardbox-body {
            padding: 47px 96px;
            .upload-wrapper {
                margin: 0 -75px;
                .dropzone {
                    padding: 23px 58px 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-height: 150px;
                    height: auto;
                    color: rgba(0, 0, 0, 0.5);
                    background-color: #f0f0f0;
                    p {
                        margin: 0;
                        font-size: 16px;
                        line-height: 22px;
                    }
                    svg {
                        width: auto;
                        height: auto;
                        margin-right: 10px;
                    }
                }
                .upload-content-wrapper {
                    max-width: 106px;
                    height: 103px;
                    position: relative;
                    margin-right: 20px;
                    margin-bottom: 25px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    span {
                        font-family: Lato;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 14px;
                        white-space: nowrap;
                        max-width: 103px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: rgba(0, 0, 0, 0.5);
                    }
                    i {
                        position: absolute;
                        top: -7px;
                        right: -7px;
                    }
                    .modal-close {
                        color: white;
                        background-color: black;
                        height: 16px;
                        width: 16px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-content: center;
                        flex: 1;
                    }
                }
            }
        }
    }
    .inputcard-wrapper {
        .inputcard-body {
            &.custom-inputcard-body {
                .inputcard-group {
                    padding: 0;
                    .choose-reason {
                        width: 34.33%;
                        padding: 0;
                        padding-right: 48px;
                        padding-bottom: 48px;

                        &.full-width {
                            padding-right: 0;
                            padding-bottom: 30px;
                            width: 100% !important;
                        }

                        &:nth-of-type(3) {
                            padding-right: 0;
                            width: 31.34%;
                        }
                        &:nth-of-type(4) {
                            padding-right: 0;
                            width: 100%;
                        }
                        &:nth-of-type(5) {
                            padding-right: 0;
                            width: 100%;
                        }

                        .inputtag-field {
                            .error-msg {
                                color: red;
                                font-size: 14px;
                            }

                            .input-tag {
                                background: white;
                                border: 1px solid #979797;
                                border-radius: 4px;
                                display: flex;
                                flex-wrap: wrap;
                                padding: 5px 5px 0;
                                width: 100%;
                            }

                            .input-tag input {
                                border: none;
                                width: 100%;
                            }

                            .input-tag__tags {
                                display: inline-flex;
                                flex-wrap: wrap;
                                margin: 0;
                                padding: 0;
                                width: 100%;
                            }

                            .input-tag__tags li {
                                align-items: center;
                                background: rgba(132, 176, 132, 0.4);
                                border-radius: 4px;
                                color: #000000;
                                display: flex;
                                font-weight: 300;
                                list-style: none;
                                margin-bottom: 5px;
                                margin-right: 5px;
                                padding: 0px 10px;
                                height: 45px;
                            }

                            .input-tag__tags li button {
                                align-items: center;
                                appearance: none;
                                background: #333333;
                                border: none;
                                border-radius: 50%;
                                color: white;
                                cursor: pointer;
                                display: inline-flex;
                                font-size: 12px;
                                height: 15px;
                                justify-content: center;
                                line-height: 0;
                                margin-left: 8px;
                                padding: 0;
                                transform: rotate(45deg);
                                width: 15px;
                            }

                            .input-tag__tags li.input-tag__tags__input {
                                background: none;
                                flex-grow: 1;
                                padding: 0;
                                input {
                                    height: 45px;
                                }
                            }
                        }

                        .inputcard-withlabel {
                            .memory-input {
                                height: 200px;
                                display: block;
                                padding: 1rem;
                                width: 100%;
                                background-color: #fff;
                                background-clip: padding-box;
                                border: 1px solid #979797;
                                border-radius: 0.25rem;
                                transition: border-color 0.15s ease-in-out,
                                    box-shadow 0.15s ease-in-out;

                                color: #212121 !important;
                                line-height: 26px;

                                -webkit-writing-mode: horizontal-tb !important;
                                text-rendering: auto;
                                letter-spacing: normal;
                                word-spacing: normal;
                                text-transform: none;
                                text-indent: 0px;
                                text-shadow: none;
                                appearance: textfield;
                                -webkit-rtl-ordering: logical;
                                cursor: text;
                            }
                        }
                    }

                    .thumbnail-wrapper {
                        width: 50%;
                        margin: 1rem;
                        border-radius: 8px;

                        .thumbnail-image {
                            height: 100px;
                            border-radius: 8px;

                            img {
                                max-width: unset;
                                height: 100%;
                                border-top-left-radius: 8px;
                                border-bottom-left-radius: 8px;
                            }

                            svg {
                                fill: dimgrey;
                                background-color: lightgray;
                                border: 5px solid gray;
                                padding: 15px;
                                max-width: unset;
                                height: 100px;
                                border-top-left-radius: 8px;
                                border-bottom-left-radius: 8px;
                            }
                        }

                        .video-content {
                            background-color: #f1f1f1;
                            border-top-right-radius: 8px;
                            border-bottom-right-radius: 8px;
                            .content {
                                padding: 1rem 0.5rem 1rem 1rem;
                                min-width: 220px;
                            }
                            .close {
                                align-items: center;
                                padding: 1rem 1rem 1rem 0.5rem;
                            }
                        }
                    }

                    &.personal-details-wrapper {
                        .choose-reason {
                            width: 34.33%;
                            padding: 0;
                            padding-right: 48px;
                            padding-bottom: 48px;
                            margin-bottom: 0;
                            &:nth-of-type(3) {
                                padding-right: 0;
                                width: 31.34%;
                            }
                            &:nth-of-type(6) {
                                padding-right: 0;
                                width: 31.34%;
                            }
                            &:nth-of-type(9) {
                                padding-right: 0;
                                width: 31.34%;
                            }
                        }
                    }
                }

                .label-input {
                    color: rgba(0, 0, 0, 0.5);
                    padding: 33px 0 25px;
                    margin: 0;
                    cursor: pointer;
                }
            }
        }
        .note-wrapper {
            font-family: "Lato";
            font-style: italic;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: $theme-note-color;
        }
        .inputcard-footer {
            padding: 21px 0 84px;
            .btn-cancel-wrapper {
                margin-right: 20px;
                background: $brand-default;
                color: $theme-cancel-btn-bg;
            }
        }
    }
}

@media (max-width: 1111px) and (min-width: 768px) {
    .share-a-memory-wrapper {
        padding: 40px;
        .cardbox-wrapper {
            .cardbox-body {
                padding: 40px;
                .upload-wrapper {
                    margin: 0 -75px;
                    .dropzone {
                        p {
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 20px;
                        }
                        svg {
                            width: 20px;
                            height: 20px;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
        .inputcard-wrapper {
            .inputcard-body {
                &.custom-inputcard-body {
                    .inputcard-group {
                        padding: 0;
                        .choose-reason {
                            width: 50%;
                            padding-right: 10px;
                            padding-bottom: 20px;
                            &.full-width {
                                padding-bottom: 11px;
                                width: 100% !important;
                            }
                            &:nth-of-type(3) {
                                width: 100%;
                            }
                        }


                        &.personal-details-wrapper {
                            .choose-reason {
                                width: 33.34%;
                                padding-right: 10px;
                                padding-bottom: 20px;
                            }
                        }
                    }

                    .label-input {
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 18px;
                    }
                }
            }
            .note-wrapper {
                font-size: 16px;
                line-height: 18px;
            }
            .inputcard-footer {
                padding: 20px 0 30px;
            }
        }
    }
}

@media (max-width: 767px) and (min-width: 320px) {
    .share-a-memory-wrapper {
        padding: 0;
        .cardbox-wrapper {
            .content-wrapper {
                padding: 48px 96px;
            }
            .cardbox-body {
                padding: 17px 16px;
                .upload-wrapper {
                    margin: 0 -75px;
                    .dropzone {
                        height: 108px;
                        p {
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 14px;
                        }
                        svg {
                            width: 12px;
                            height: 12px;
                            margin-right: 10px;
                        }
                    }
                    .upload-content-wrapper {
                        max-width: 106px;
                        height: 103px;
                        position: relative;
                        margin-right: 20px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        span {
                            font-family: Lato;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 14px;
                            white-space: nowrap;
                            max-width: 103px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            color: rgba(0, 0, 0, 0.5);
                        }
                        i {
                            position: absolute;
                            top: -7px;
                            right: -7px;
                        }
                        .modal-close {
                            color: white;
                            background-color: black;
                            height: 16px;
                            width: 16px;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-content: center;
                            flex: 1;
                        }
                    }
                }
            }
        }
        .inputcard-wrapper {
            .inputcard-body {
                &.custom-inputcard-body {
                    .inputcard-group {
                        padding: 0;
                        .choose-reason {
                            width: 100%;
                            padding-right: 0;
                            padding-bottom: 11px;
                            &.full-width {
                                padding-bottom: 11px;
                                width: 100% !important;
                            }
                            &:nth-of-type(3) {
                                width: 100%;
                            }
                            .inputtag-field {
                                .error-msg {
                                    color: red;
                                    font-size: 14px;
                                }

                                .input-tag {
                                    background: white;
                                    border: 1px solid #979797;
                                    border-radius: 4px;
                                    display: flex;
                                    flex-wrap: wrap;
                                    padding: 5px 5px 0;
                                    width: 100%;
                                }

                                .input-tag input {
                                    border: none;
                                    width: 100%;
                                }

                                .input-tag__tags {
                                    display: inline-flex;
                                    flex-wrap: wrap;
                                    margin: 0;
                                    padding: 0;
                                    width: 100%;
                                }

                                .input-tag__tags li {
                                    align-items: center;
                                    background: rgba(132, 176, 132, 0.4);
                                    border-radius: 4px;
                                    color: #000000;
                                    display: flex;
                                    font-weight: 300;
                                    list-style: none;
                                    margin-bottom: 5px;
                                    margin-right: 5px;
                                    padding: 0px 10px;
                                    height: 45px;
                                }

                                .input-tag__tags li button {
                                    align-items: center;
                                    appearance: none;
                                    background: #333333;
                                    border: none;
                                    border-radius: 50%;
                                    color: white;
                                    cursor: pointer;
                                    display: inline-flex;
                                    font-size: 12px;
                                    height: 15px;
                                    justify-content: center;
                                    line-height: 0;
                                    margin-left: 8px;
                                    padding: 0;
                                    transform: rotate(45deg);
                                    width: 15px;
                                }

                                .input-tag__tags li.input-tag__tags__input {
                                    background: none;
                                    flex-grow: 1;
                                    padding: 0;
                                    input {
                                        height: 45px;
                                    }
                                }
                            }

                            .inputcard-withlabel {
                                .memory-input {
                                    height: 200px;
                                    display: block;
                                    padding: 1rem;
                                    width: 100%;
                                    background-color: #fff;
                                    background-clip: padding-box;
                                    border: 1px solid #979797;
                                    border-radius: 0.25rem;
                                    transition: border-color 0.15s ease-in-out,
                                        box-shadow 0.15s ease-in-out;

                                    color: #212121 !important;
                                    line-height: 26px;

                                    -webkit-writing-mode: horizontal-tb !important;
                                    text-rendering: auto;
                                    letter-spacing: normal;
                                    word-spacing: normal;
                                    text-transform: none;
                                    text-indent: 0px;
                                    text-shadow: none;
                                    appearance: textfield;
                                    -webkit-rtl-ordering: logical;
                                    cursor: text;
                                }
                            }
                        }

                        .thumbnail-wrapper {
                            width: 50%;
                            margin: 1rem;
                            border-radius: 8px;

                            .thumbnail-image {
                                height: 100px;
                                border-radius: 8px;

                                img {
                                    max-width: unset;
                                    height: 100%;
                                    border-top-left-radius: 8px;
                                    border-bottom-left-radius: 8px;
                                }
                            }

                            .video-content {
                                background-color: #f1f1f1;
                                border-top-right-radius: 8px;
                                border-bottom-right-radius: 8px;
                                .content {
                                    padding: 1rem 0.5rem 1rem 1rem;
                                    min-width: 220px;
                                }
                                .close {
                                    align-items: center;
                                    padding: 1rem 1rem 1rem 0.5rem;
                                }
                            }
                        }

                        &.personal-details-wrapper {
                            .choose-reason {
                                width: 100%;
                                padding-right: 0;
                                padding-bottom: 11px;
                                &:nth-of-type(3) {
                                    width: 100%;
                                }
                                &:nth-of-type(6) {
                                    width: 100%;
                                }
                                &:nth-of-type(9) {
                                    width: 100%;
                                }
                            }
                        }
                    }

                    .label-input {
                        padding: 16px 0 24px;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 14px;
                    }
                }
            }
            .note-wrapper {
                font-size: 10px;
                line-height: 12px;
            }
            .inputcard-footer {
                padding: 30px 0 10px;
                justify-content: center;
                .btn-cancel-wrapper {
                    margin-right: 20px;
                    background: $brand-default;
                    color: $theme-cancel-btn-bg;
                }
            }
        }
    }
}
