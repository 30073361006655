@import '../node_modules/bootstrap/scss/bootstrap';

// common styles
@import './assets/styles/_fonts.scss';
@import './assets/styles/_mixins.scss';
@import './assets/styles/_color.scss';
@import './assets/styles/_common.scss';
@import './styles/scss/theme.scss';
@import './assets/styles/_date-picker.scss';
@import './assets/styles/_custom-modal.scss';
@import './styles/scss/footer.scss';

// authenication styles
@import './components/authentication/_auth.scss';
@import './components/authentication/signin/_signin.scss';
@import './components/authentication/resetPassword/_resetPassword.scss';
@import './components/authentication/createProfile/_createProfile.scss';

// main content styles
@import './components/appLayout/mainContent';

// navigation styles
@import './components/navigation/navbar/_navbar.scss';
@import './components/navigation/footer/_footer.scss';

// Common components style
@import './components//utils/loader/loader.scss';
@import './components//utils/AlertMessage/AlertMessage.scss';
// dashboard styles
@import './components/features/dashboard/dashboard';

// arrangement styles
@import './components/features/arrangements/_arrangements.scss';

// what to expect styles
@import './components/features/whatToExpect/_whatToExpect.scss';

// certificate styles
@import './components/features/certificateDetails/_certificateDetails.scss';

// available services styles
@import './components/features/servicesDetails/_servicesDetails.scss';

// scheduled services styles
@import './components/scheduledServices/_scheduledServices.scss';

// prayer creation styles
// @import "./components/features/prayerCreation/_prayerCreation.scss";
@import './components/features/prayerCard/prayerCreation';

//Program creation styles
@import './components/features/programCreation/_programCreation.scss';

//Disclaimer styles
@import './components/acceptPolicies/policies.scss';

// memories styles
@import './components/features/memories/memories.scss';

// share a memory styles
@import './components/features/memories/shareMemory/shareMemory';

// admin styles
@import './components/admin/admin';

// family-website styles
@import './components/features/familyWebsite/memories.scss';
@import './components/website/leftPanel.scss';
@import './components/features/familyWebsite/shareMemory/shareMemory';
@import './components/features/familyWebsite/memories.scss';
@import './components/website/login/loginForm.scss';
@import './components/website/ForgotPassword/forgotPassword.scss';
@import './components/features/imageDisplay/imagesDisplay.scss';

// new program card styles
@import './components/features/programCreation/newProgram/newProgramCard.scss';
@import './components/common/serviceCard/serviceCardActionPanel/serviceCardActionPanel.scss';
@import './components/common/dropzoneFileSelect/dropzoneFileSelect.scss';
@import './components/common/dropzoneFileCard/dropzoneFileCard.scss';
@import './components/common/serviceCard/serviceCard.scss';
