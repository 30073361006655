@import '../../../../assets/styles/_color.scss';

.new-program-card {
  background-color: $white;
  padding: 15px 0;
  overflow-x: hidden;

  h6 {
    font-weight: bold;
  }

  h4 {
    margin-bottom: 30px;
  }
  .displayLoader {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
