.scheduledservices-wrapper{
    .cardbox-wrapper{
        &:not(:last-child){
            margin-bottom: 30px;
        }
    }
    .dashboard-card-decedentinfo{
        .dashboard-card-keyvalue{
            margin: 0;
            font-size: 16px;
            &:not(:first-child){
                margin-top: 20px;
            }
            .dashboard-card-key {
                color: $theme-placeholder-text;
                white-space: nowrap;
            }
            .dashboard-card-value {
                color: $theme-text;
                padding-left: 5px;
            }
        }
    }
}

@media (max-width: 767px) and (min-width: 320px){
    .scheduledservices-wrapper{
        .cardbox-wrapper{
            &:not(:last-child){
                margin-bottom: 16px;
            }
        }
        .dashboard-card-decedentinfo{
            .dashboard-card-keyvalue{
                font-size: 14px;
                line-height: 17px;
                &:not(:first-child){
                    margin-top: 14px;
                }
            }
        }
    }
}