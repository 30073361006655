@import "./_fonts.scss";
@import "./_color.scss";
@import "./_mixins.scss";

* {
    &:focus {
        outline: none !important;
    }
}

html {
    font-size: 16px !important;
}

body {
    color: $theme-text;
    background-color: $theme-bg !important;

    @extend .lato-regular;
}

input {
    color: $theme-text !important;

    @include placeholder-color($theme-placeholder-text !important);

    &[disabled] {
        color: $theme-placeholder-text !important;
    }
}

img {
    max-width: 100%;
}

.form-group,
.form-check,
.inputcard-withlabel {
    position: relative;

    .error-input-msg {
        top: 96px;
        left: 0;
        color: red;
        display: block;
        overflow: hidden;
        position: absolute;
        font-size: 14px;
        line-height: 16px;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .error-memory-input-msg {
        bottom: -17px;
        left: unset;
        color: red;
        display: block;
        overflow: hidden;
        position: absolute;
        font-size: 14px;
        line-height: 16px;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    @media (min-width: 767px){
        .certificate-error-msg{
            margin-top:15px;
        }
    }
    
}

.theme-primary {
    color: $brand-primary !important;
}

.theme-secondary {
    color: $brand-secondary !important;
}

.theme-text {
    color: $theme-text !important;
}

.theme-grey {
    color: $suva-grey !important;
}

.theme-success {
    color: $theme-success !important;
}

.theme-error {
    color: $error-msg !important;
}

.form-control {
    box-shadow: none !important;
}

// Font Families
.lato-light {
    font-family: $lato !important;
    font-weight: 300 !important;
}

.lato-regular {
    font-family: $lato !important;
    font-weight: 400 !important;
}

.lato-bold {
    font-family: $lato !important;
    font-weight: 700 !important;
}

.lato-black {
    font-family: $lato !important;
    font-weight: 900 !important;
}

.custom-fontawesome {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: "Font Awesome\ 5 Free";
}

a:hover {
    text-decoration: none !important;
}

.input-mandatory {
    color: $error-msg !important;
}

//removed input default styles
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

// Theme Buttons

.btn-transparent {
    border: none;
    padding: 0;
    background: none;
    text-transform: capitalize;
    min-height: 0;
    padding: 0;
    color: $brand-secondary;
}

.btn-theme {
    color: $white;
    cursor: pointer;
    border: 0;
    padding: 15px 10px;
    font-size: 16px;
    min-width: 150px;
    min-height: 56px;
    line-height: 1.43;
    letter-spacing: 0.2px;
    background-color: $brand-primary;

    @include box-shadow(none);

    @include transition(all 0.3s ease-in-out);

    @include border-radius(4px);

    @extend .text-center;

    @extend .lato-bold;

    @extend .d-flex;

    @extend .align-items-center;

    @extend .justify-content-center;

    &.theme-transparent {
        color: $brand-primary;
        border: 1px solid $brand-primary;
        background-color: transparent;
    }

    &.btn-theme-success {
        background-color: $theme-success;
    }

    &.theme-link {
        background: none;
        color: #298A45;
    }

    &.theme-orange {
        color: $white;
        background-color: $brand-secondary;

        &.orange-transparent {
            color: $brand-secondary;
            border: 1px solid $brand-secondary;
            background-color: transparent;

            &[disabled],
            &:disabled,
            &.disabled {
                background-color: transparent;
            }

            &.orange-only-text {
                padding: 0;
                border-width: 0;
                border-bottom-width: 1px;
                border-radius: 0;
                min-height: 0;
                min-width: 0;
                font-size: 14px;

                @extend .lato-regular;
            }
        }

        &:hover,
        &:active,
        &:focus:active,
        &:focus:hover,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled):active:focus {
            opacity: 0.7;

            @include box-shadow(none);
        }

        &[disabled],
        &:disabled,
        &.disabled {
            background-color: $brand-secondary;
        }
    }

    &.theme-green-outlined {
        border: 2px solid $brand-primary;
        background-color: white;
        color: $brand-primary;
        padding: 6px;
    }

    &.theme-cancel{
        background: #C4C4C4;
        border-radius: 4px;
        color:#000000;
    }

    &.theme-green {
        color: $white;
        background-color: $theme-success;

        &.green-transparent {
            color: $theme-success;
            border: 1px solid $theme-success;
            background-color: transparent;

            &[disabled],
            &:disabled,
            &.disabled {
                background-color: transparent;
            }
        }

        &:hover,
        &:active,
        &:focus:active,
        &:focus:hover,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled):active:focus {
            opacity: 0.7;

            @include box-shadow(none);
        }

        &[disabled],
        &:disabled,
        &.disabled {
            background-color: $theme-success;
        }
    }


    &.theme-green-dark {
        color: $white;
        background-color: #298A45;
        text-transform: uppercase;
        padding: 0px 25px;

        &.green-transparent {
            color: $theme-success;
            border: 1px solid $theme-success;
            background-color: transparent;

            &[disabled],
            &:disabled,
            &.disabled {
                background-color: transparent;
            }
        }

        &:hover,
        &:active,
        &:focus:active,
        &:focus:hover,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled):active:focus {
            opacity: 0.7;

            @include box-shadow(none);
        }

        &[disabled],
        &:disabled,
        &.disabled {
            background-color: $theme-success;
        }
    }

    &.theme-black {
        color: $suva-grey-2;
        background-color: $gainsboro;

        &.black-transparent {
            color: $theme-placeholder-text;
            border: 1px solid $theme-placeholder-text;
            background-color: transparent;
        }

        &:hover,
        &:active,
        &:focus:active,
        &:focus:hover,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled):active:focus {
            opacity: 0.7;

            @include box-shadow(none);
        }

        &[disabled],
        &:disabled,
        &.disabled {
            background-color: $gainsboro;
        }
    }

    &:hover,
    &:active,
    &:focus:active,
    &:focus:hover,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus {
        opacity: 0.7;

        @include box-shadow(none);
    }

    &[disabled],
    &:disabled,
    &.disabled {
        color: $white;
        opacity: 0.5;
        cursor: not-allowed;
        background-color: $brand-primary;
    }
}

.back-btn-section {
    padding: 16px 12px 0 0;
    display: block;
    .back-btn {
        border: 0;
        padding: 0;
        background-color: transparent;

        @include box-shadow(none);

        > i {
            color: $theme-secondary-text;
            font-size: 14px;
            margin-right: 8px;
        }

        > span {
            color: $theme-secondary-text;
            font-size: 14px;
            line-height: 17px;

            @extend .lato-bold;
        }
    }
}

// Custom Checbox and Radio UI
.custom-check {
    margin-bottom: 5px;

    label {
        &.checkbox-inline {
            input[type="checkbox"] {
                display: none;

                &:checked {
                    + span {
                        border: 1px solid $theme-success;
                        padding: 2px 0;
                        background-color: $theme-success;

                        @include border-radius(2px);

                        @include transition(all 0.3s ease-in-out);

                        i {
                            opacity: 1;
                        }
                    }
                }

                + span {
                    width: 16px;
                    border: 1px solid $theme-placeholder-text;
                    cursor: pointer;
                    height: 16px;
                    margin: -1px 10px 0 0;
                    display: inline-block;
                    padding: 3px;
                    position: relative;
                    text-align: center;
                    line-height: 14px;
                    margin-right: 0;
                    vertical-align: middle;
                    background-color: $white;

                    @include border-radius(2px);

                    @include transition(all 0.3s ease-in-out);

                    i {
                        color: $white;
                        width: 100%;
                        height: 100%;
                        display: block;
                        opacity: 0;
                        overflow: visible;
                        position: relative;
                        font-size: 12px;

                        @include transition(all 0.3s ease-in-out);
                    }
                }

                &[disabled] {
                    + span {
                        cursor: not-allowed;
                        opacity: 0.5;
                    }
                }
            }
        }
    }

    .checkbox-inline {
        padding-left: 0;
    }
}

.custom-radio {
    margin-bottom: 5px;

    label {
        &.radio-inline {
            input[type="radio"] {
                display: none;

                &:checked {
                    + span {
                        border: 1px solid $theme-success;
                        padding: 3px;
                        background-color: $theme-success;

                        @include border-radius(50%);

                        @include transition(all 0.3s ease-in-out);

                        i {
                            opacity: 1;
                        }
                    }
                }

                + span {
                    width: 16px;
                    border: 1px solid $theme-placeholder-text;
                    cursor: pointer;
                    height: 16px;
                    display: inline-block;
                    padding: 3px;
                    position: relative;
                    text-align: center;
                    line-height: 14px;
                    margin-right: 10px;
                    vertical-align: middle;
                    background-color: transparent;

                    @include border-radius(50%);

                    @include transition(all 0.3s ease-in-out);

                    i {
                        top: 50%;
                        left: 50%;
                        width: 10px;
                        color: $white;
                        margin: 0;
                        display: block;
                        opacity: 0;
                        overflow: visible;
                        position: relative;
                        font-size: 8px;
                        font-weight: 900;

                        @include translate(-50%, -50%);

                        @include transition(all 0.3s ease-in-out);
                    }
                }
            }
        }
    }

    .radio-inline {
        padding-left: 0;
    }
}

.search-container {
    position: relative;

    .input-fields {
        padding-right: 15px;
    }

    &::after {
        content: "\f002";
        font-weight: 900;
        font-size: 12px;
        color: $theme-placeholder-text;
        position: absolute;
        top: 35%;
        right: 8px;
        pointer-events: none;

        @extend .custom-fontawesome;
    }
}

.text-ellipsis {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.checkbox-container {
    padding: 0.8rem 0 0.5rem 0;
}

.successalert-card {
    margin: 80px 0 0 0;
    padding: 14px 14px 14px 44px;
    position: relative;
    // max-width: 400px;
    // min-height: 52px;
    background-color: rgba($theme-success, 1);
    @include flex(flex-start, center);
    > i.fas {
        top: 13px;
        left: 11px;
        color: $white;
        position: absolute;
        font-size: 25px;
    }
    > span {
        color: $white;
        font-size: 16px;
        @extend .lato-bold;
        > a {
            color: $white;
            padding-left: 5px;
            text-decoration: underline;
        }
    }
    + .resend-wrapper {
        color: $theme-text;
        margin: 32px 0 0;
        button {
            color: $brand-secondary;
            border: 0;
            padding: 0;
            box-shadow: none;
            background-color: transparent;
            border-bottom: 1px solid $brand-secondary;
        }
    }
}

// Custom Card inputs styles Starts
.cardbox-wrapper {
    @include box-shadow(0 3px 10px 0 rgba(144, 137, 137, 0.18));
  /*  margin-bottom: 30px; */
    .cardbox-heading {
        .BetaButton {
            display: inline-block;
            padding: 0px 4px 0 4px;
            background-color: #298a45;
            border-radius: 20%;
            color: white;
            font-size: 10px;
            margin-left: 10px;
        }
        height: 58px;
        padding: 0 40px;
        position: relative;
        background-color: $theme-table-nth;
        @include flex(space-between, center);

        @include box-shadow(0 3px 10px 0 rgba(144, 137, 137, 0.18));
        .cardbox-heading-name {
            color: $brand-primary;
            margin: 0;
            font-size: 20px;
            line-height: 24px;
            &.text-black{
                color: #000000;

                .item-numbering{
                    color: #000000;
                    background: #FFFFFF;
                    border: 1px solid #000000;
                    box-sizing: border-box;
                    border-radius: 50%;
                    padding: 3px 10px;
                    margin-right: 12px;
                }
            }

            @extend .lato-bold;
        }
        .cardbox-heading-right-text {
            color: $black;
            margin: 0;
            font-size: 18px;
            line-height: 24px;
            font-weight: 400;

            @extend .lato-bold;
        }
    }
    .cardbox-body {
        padding: 0 20px 24px;
        background-color: $white;
        &.cardbox-body-text {
            padding: 1.5rem 2.5rem;
        }
        .MuiSwitch-colorSecondary.Mui-checked {
            color: #1f870e;
        }
        .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
            background-color: #1f870e;
        }
    }
}

.custom-search {
    position: relative;

    input {
        height: 55px;
        padding-left: 40px;
        font-size: 16px;
        line-height: 19px;
        color: $theme-placeholder-text;
        border: 1px solid $theme-placeholder-text;
        border-radius: 4px;
    }

    .fa-search {
        color: $theme-placeholder-text;
        position: absolute;
        z-index: 1;
        left: 16px;
        top: 50%;
        font-size: 18px;

        @include translate(0, -50%);
    }
}

// Auto Complete Wrapper

.custom-autocomplete-wrapper {
    width: 100%;
    display: block;
    position: relative;
    &.show-autocomplete-menu {
        // z-index: 1;
        .custom-autocomplete-menu {
            opacity: 1;
            width: 100%;
            @include transform(scale(1));
        }
    }
    input {
        background-color: transparent;
    }
    .custom-autocomplete-menu {
        opacity: 0;
        z-index: 1;
        position: absolute;
        max-width: 522px;
        max-height: 205px;
        overflow-y: auto;
        background-color: $white;
        transform-origin: top left;
        @include transform(scale(0));
        @include box-shadow(0 7px 10px 0 rgba(0, 0, 0, 0.19));
        @include transition(all 0.3s ease);
        .custom-autocomplete-menulist {
            height: 59px;
            cursor: pointer;
            padding-left: 31px;
            &:not(:last-child) {
                border-bottom: 1px solid $gainsboro;
            }
            span {
                color: $primary-text-color;
                font-size: 13px;
                line-height: 16px;
                &.create-new-organization {
                    color: $brand-primary;
                }
            }
        }
    }
}

// Custom Form inputs styles Starts
.inputcard-wrapper {
    .inputcard-body {
        &.custom-inputcard-body {
            .inputcard-group {
                padding: 40px 0 0;

                @extend .flex-wrap;

                &.two-cardbox {
                    .choose-reason {
                        width: 50%;
                    }
                }
                .choose-reason {
                    width: 33.33%;
                    padding: 0 20px;
                    &.inputfield {
                        .custominputfield {
                            width: 100%;
                            height: 55px;
                            border: 1px solid $theme-placeholder-text;
                            padding: 0 1rem;
                            font-size: 16px;

                            @include border-radius(4px);

                            &[disabled] {
                                cursor: not-allowed;
                                background-color: #f2f2f2;
                            }
                        }
                        .custominputfieldfortext {
                            width: 900px;
                            height: 223px;
                            border: 1px solid #979797;
                            padding: 20px;
                            font-size: 16px;
                            @include border-radius(4px);
                        }
                        .custominputfieldforMemory {
                            //width: 900px;
                            border: 1px solid #979797;
                            padding: 0 1rem;
                            font-size: 16px;
                            @include border-radius(4px);
                        }
                    }
                    &.textareafield {
                        height: 120px;
                        &.textareafield-label {
                            height: auto;
                            > .inputcard-withlabel {
                                height: auto;
                            }
                            .customtextareafield {
                                width: 100%;
                                border: 1px solid $theme-placeholder-text;
                                resize: none;
                                height: 120px;
                                padding: 16px;
                                font-size: 16px;
                                @include border-radius(4px);
                            }
                        }
                    }

                    > span {
                        color: $theme-text;
                        display: block;
                        font-size: 16px;
                        line-height: 19px;

                        @extend .lato-regular;
                    }

                    .inputcard-withlabel {
                        width: 100%;
                        position: relative;
                        &.add-new-feature-wrapper {
                            .add-new-feature {
                                top: 0;
                                color: $brand-secondary;
                                right: 0;
                                cursor: pointer;
                                position: absolute;
                                font-size: 14px;
                                line-height: 24px;

                                @extend .lato-bold;
                            }
                            .custom-autocomplete-wrapper {
                                .select-dropdown__control {
                                    border-color: $theme-placeholder-text;
                                    &:hover,
                                    &:focus {
                                        border-color: $theme-placeholder-text !important;
                                        box-shadow: none !important;
                                    }
                                }
                                .select-dropdown__control {
                                    .select-dropdown__placeholder,
                                    .select-dropdown__single-value,
                                    .select-dropdown__input {
                                        padding-left: 35px;
                                    }
                                }
                                .select-dropdown__indicators {
                                    display: none;
                                }
                            }
                        }

                        input {
                            width: 100%;
                            height: 55px;
                            border: 1px solid $theme-placeholder-text;
                            padding: 0 1rem;
                            font-size: 16px;

                            @include border-radius(4px);
                        }

                        .inputcard-label {
                            color: $theme-text;
                            font-size: 16px;
                            line-height: 19px;
                            white-space: nowrap;
                            margin-bottom: 17px;
                            margin-top: 17px;

                            @extend .lato-regular;
                            .input-mandatory {
                                line-height: 14px;
                            }
                        }

                        .select-wrapper {
                            position: relative;

                            > .select-dropdown--is-disabled {
                                cursor: not-allowed;
                                pointer-events: initial;
                            }

                            .select-dropdown__control {
                                cursor: pointer;
                                padding: 0 1rem;
                                min-height: auto;
                                border-color: $theme-placeholder-text;
                                background-color: $white;

                                @include box-shadow(none);

                                @include border-radius(4px);

                                &.select-dropdown__control--is-disabled {
                                    cursor: not-allowed;
                                    pointer-events: none;
                                    background-color: #f2f2f2;
                                }

                                .select-dropdown__value-container {
                                    height: 53px;
                                    padding: 0;

                                    .select-dropdown__placeholder {
                                        color: $theme-placeholder-text;
                                        margin: 0;
                                        font-size: 16px;
                                        white-space: nowrap;
                                    }

                                    .select-dropdown__single-value {
                                        color: $theme-text;
                                        margin: 0;
                                        font-size: 16px;
                                    }

                                    > div {
                                        margin: 0;
                                        padding: 0;
                                    }

                                    .select-dropdown__input {
                                        > input {
                                            color: $theme-text !important;
                                            font-size: 16px !important;
                                        }
                                    }
                                }

                                .select-dropdown__indicators {
                                    line-height: 20px;

                                    .select-dropdown__indicator {
                                        padding: 0;
                                    }
                                }
                            }

                            .select-dropdown__menu {
                                width: calc(100%);

                                @include border-radius(0);

                                @include box-shadow(
                                    0 7px 10px 0 rgba(0, 0, 0, 0.19)
                                );

                                .select-dropdown__menu-list {
                                    padding: 0;

                                    .select-dropdown__option {
                                        color: $primary-text-color;
                                        height: 59px;
                                        padding: 8px 16px;
                                        font-size: 14px;

                                        @extend .d-flex;

                                        @extend .align-items-center;
                                    }
                                }
                            }
                        }
                        &.your-memory-wrapper {
                            .error-input-msg {
                                top: 240px;
                            }
                        }
                    }

                    &.multiselect-headings {
                        .multiselect-dropdown-container {
                            width: 100%;

                            .dropdown-heading {
                                height: 55px !important;
                                border: 1px solid $theme-placeholder-text !important;

                                @include border-radius(4px !important);

                                .dropdown-heading-value {
                                    height: 53px !important;
                                    padding: 0 16px !important;

                                    > span {
                                        color: $theme-text !important;
                                        font-size: 16px;
                                        line-height: 19px;

                                        @extend .text-ellipsis;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .common-address-wrapper {
                .inputcard-body {
                    .inputcard-group {
                        .choose-reason-address {
                            &:not(:last-of-type) {
                                // margin-bottom: 40px;
                            }
                        }
                        .choose-reason {
                            margin-bottom: 30px;
                        }
                    }
                }
            }
        }
    }

    .inputcard-footer {
        padding: 24px 20px 0;

        @include flex(flex-end, center);

        .btn-search-wrapper {
            color: $white;
            max-width: 150px;
            min-height: 54px;
            background-color: $brand-primary;
        }
    }
    .transfer-button{
        color: $white;
        cursor: pointer;
        border: 0;
        padding: 15px 10px;
        font-size: 14px;
        min-width: 190px;
        min-height: 40px;
        line-height: 1.43;
        letter-spacing: 0.2px;
        background-color: $brand-primary;
        border-radius:4px;
        display:flex;
        align-items: center;
        justify-content: center;

        &[disabled],
        &:disabled,
        &.disabled {
            background-color: #c4c4c4;
            cursor: not-allowed;
        }
    }
}
// Custom Form inputs styles Ends

/* ==============================================================================
******************   Animation Styles For the Application   ********************
============================================================================== */

/* Slides Move Animation */
@-webkit-keyframes slides-move {
    0% {
        opacity: 0;

        @include transform(translate(0, 0) scale(0));
    }

    25% {
        opacity: 0.5;

        @include transform(translate(-200px, 200px) scale(0.5));
    }
}

@keyframes slides-move {
    0% {
        opacity: 0;

        @include transform(translate(0, 0) scale(0));
    }

    25% {
        opacity: 0.5;

        @include transform(translate(-200px, 200px) scale(0.5));
    }
}

.slides-move {
    -webkit-animation-name: slides-move;
    -moz-animation-name: slides-move;
    animation-name: slides-move;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
}

.form-group .form-control:focus {
    box-shadow: none;
}

//selectoptions styles
.select-wrapper {
    width: 100%;

    .label-in-select {
        @extend .lato-bold;

        color: $theme-text;
        margin: 0;
        position: absolute;
        left: 30px;
        top: 10px;
    }

    .select-dropdown__option {
        cursor: pointer;
    }

    > .select-dropdown--is-disabled {
        cursor: not-allowed;
        pointer-events: initial;

        .select-dropdown__single-value--is-disabled {
            color: $theme-placeholder-text !important;
        }
    }

    .select-dropdown__control {
        &.select-dropdown__control--is-disabled {
            background-color: #f2f2f2;
            cursor: not-allowed;
            pointer-events: none;
        }

        .select-dropdown__placeholder {
            color: $theme-placeholder-text;
            font-size: 16px;
        }

        .select-dropdown__single-value,
        .select-dropdown__input {
            color: $theme-text;
            font-size: 16px;
        }

        .select-dropdown__value-container .select-dropdown__input input {
            width: 100% !important;
            color: $theme-text !important;
            font-size: 16px !important;
        }

        .select-dropdown__indicator-separator {
            display: none;
        }
    }

    .select-dropdown__value-container--is-multi {
        height: 32px;
        overflow-y: auto;

        .select-dropdown__multi-value {
            background-color: transparent;
            color: $table-th-color;

            .select-dropdown__multi-value__label {
                color: $table-th-color;
            }
        }
    }

    .select-dropdown__clear-indicator {
        display: none;
    }

    .display-clear-indicator {
        .select-dropdown__clear-indicator {
            margin-right: 3px;
            display: block !important;
        }
        .select-dropdown__indicator {
            display: none;
        }
    }

    .select-dropdown__menu {
        border: solid 1px $multi-selector-border;
        z-index: 3;
        box-shadow: none;

        &::before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            top: 0;
            left: 94%;
            box-sizing: border-box;
            border: 3px solid black;
            border-color: transparent transparent $white $white;
            transform-origin: 0 0;
            transform: rotate(135deg);
            box-shadow: -2px 2px 2px 0 rgba(0, 0, 0, 0.07);
        }

        .select-dropdown__menu-list {
            .select-dropdown__option {
                color: $table-th-color;
                font-size: 12px;
                padding: 14px 17px;
                border-bottom: solid 1px $multi-selector-border;
                background-color: transparent;

                &:last-child {
                    border-bottom: none;
                }
            }

            .select-dropdown__group {
                border-bottom: solid 1px $multi-selector-border;
                padding: 0;

                .select-dropdown__group-heading {
                    color: $theme-text;
                    font-size: 14px;
                    font-weight: 700;
                    margin: 0;
                    padding-top: 8px;
                    text-transform: capitalize;
                }

                .select-dropdown__option {
                    padding: 10px 10px 10px 22px;
                }
            }
        }
    }
}

//Multi selectoptions styles
.multiselect-wrapper {
    width: 100%;
    position: relative;

    .label-in-multiselect {
        @extend .lato-bold;

        color: $theme-text;
        margin: 0;
        z-index: 0;
        position: absolute;
        left: 28px;

        @media (max-width: 1500px) {
            left: 18px;
        }

        top: 10px;
    }

    .multiselect-dropdown-container {
        z-index: 1;
        position: relative;

        .multi-select {
            .dropdown {
                .dropdown-heading {
                    height: 64px !important;

                    .dropdown-heading-value {
                        padding: 0 30px 0 28px !important;
                        align-items: flex-end;

                        @media (max-width: 1500px) {
                            padding: 0 30px 0 18px !important;
                        }

                        > span {
                            width: 100%;
                            color: $theme-placeholder-text !important;
                            height: 38px;

                            @extend .text-ellipsis;
                        }

                        &:after {
                            top: 50%;
                            right: 17px;
                            color: hsl(0, 0%, 80%);
                            position: absolute;
                            font-size: 18px;
                            transform: translate(0, -50%);
                        }
                    }
                }

                .dropdown-content {
                    width: 100% !important;
                    margin-top: 0 !important;
                }
            }
        }
    }
}

//dashboard header
.title-wrapper {
    line-height: 16px;
    a {
        @extend .lato-bold;

        color: $black !important;
        font-size: 14px;
        line-height: 17px;

        &:hover,
        &:focus {
            text-decoration: underline !important;
        }
    }

    .home-icon,
    .next-icon {
        color: $brand-secondary;

        &::before {
            font-size: 14px;
        }
    }

    .next-icon {
        color: $next-icon;
        font-size: 12px;
        margin: 0 8px;
    }

    span {
        color: $primary-text-color;
        font-size: 14px;

        @extend .lato-bold;
    }
}

//Dashboard Tabs
.tabs-wrapper {
    .nav-tabs-block {
        border: none;
        margin-bottom: 20px;

        .nav-link.active,
        .nav-tabs .nav-item.show .nav-link {
            border: none;
            
        }
    }

    .nav-tabs .nav-link {
        color: $h1-color;
        border: 0;
        cursor: pointer;
        font-family: $lato;
        line-height: 1;
        font-size: 24px;
        padding: 0;
        background-color: transparent;

        &:first-child {
            padding-right: 22px;
        }

        &.active {
            color: $brand-primary;
            background-color: transparent;
        }

        .focus {
            border: none;
        }
    }
}

// Notes Section
.notes-wrapper {
    padding: 48px 0;

    .notesForm {
        padding: 0 56px 35px;
        border-bottom: 1px solid rgba($theme-placeholder-text, 0.2);

        &.notes-zero {
            border-bottom: 0;
            padding-bottom: 0;
        }

        .notes-textarea {
            color: $theme-text;
            width: 100%;
            height: 220px;
            resize: none;
            border: 1px solid $theme-placeholder-text;
            display: block;
            padding: 24px;
            font-size: 16px;
            line-height: 19px;

            @include border-radius(4px);

            @include placeholder-color($theme-placeholder-text);
        }

        button {
            margin-top: 24px;

            &:last-child {
                margin-left: 16px;
            }
        }
    }

    .notes-content-wrapper {
        margin-top: 20px;

        .notes-list-group {
            overflow: hidden;
            overflow-y: auto;
            max-height: 318px;

            .notes-list {
                padding: 16px 56px 0 0;
                margin-left: 56px;

                &:first-child {
                    padding-top: 30px;
                }

                &:not(:last-child) {
                    margin-bottom: 0;
                    border-bottom: 1px solid rgba($theme-placeholder-text, 0.2);
                }

                .notes-header {
                    margin: 0;
                    height: 47px;
                    background-color: transparent;

                    > span {
                        color: $theme-placeholder-text;
                        display: inline-block;
                        font-size: 16px;
                        line-height: 19px;

                        @extend .lato-bold;
                    }

                    > small {
                        color: $theme-placeholder-text;
                        font-size: 16px;
                        line-height: 16px;
                        margin-right: 10px;

                        @extend .lato-regular;
                    }
                }

                .notes-body {
                    color: $theme-text;
                    margin: 0;
                    padding: 0;
                    font-size: 16px;
                    text-align: justify;
                    word-break: break-word;
                }
            }
        }
    }
}

// Multiselector Styles
.multiselect-dropdown-container {
    width: 200px;

    .dropdown-content {
        left: 0 !important;
        width: 180px !important;
        border: 1px solid $multi-selector-border !important;
        position: absolute !important;
        margin-top: 17px !important;
        overflow-y: auto !important;
        max-height: 200px !important;

        .select-panel {
            .select-list label[aria-selected="true"],
            .item-renderer,
            .select-item .item-renderer {
                width: 100%;
                cursor: pointer;
                display: flex;
                align-items: center;
                flex-direction: row-reverse;
                justify-content: space-between;

                input[type="checkbox"] {
                    width: auto !important;
                    display: none;

                    &:checked {
                        + span {
                            width: 100%;
                            display: flex !important;
                            justify-content: space-between;
                            align-items: center;
                            flex-direction: row;
                            padding: 0;

                            &::after {
                                content: "\f00c";
                                font-weight: 900;
                                color: $white;
                                margin-left: 10px;
                                border: 1px solid $brand-primary;
                                font-size: 10px;
                                padding: 2px;
                                border-radius: 50%;
                                background-color: $brand-primary;

                                @extend .custom-fontawesome;
                            }
                        }
                    }
                }
            }

            .select-list label[aria-selected="false"] .item-renderer,
            label[aria-selected="false"] .item-renderer {
                flex-direction: row;

                > input {
                    display: none;
                }
            }

            .select-item {
                margin: 0 !important;
                padding: 5px 17px !important;
                min-height: 50px;
                border-bottom: 1px solid $multi-selector-border !important;
                background-color: transparent !important;

                @extend .d-flex;

                @extend .align-items-center;

                @extend .justify-content-start;

                .item-renderer > span {
                    color: $multi-selector-text;
                    cursor: pointer !important;
                    padding: 0 !important;
                    font-size: 16px;
                }
            }

            .select-list li {
                &:last-child .select-item {
                    border: none !important;
                }
            }
        }

        &::before {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            top: 0;
            left: 20px;
            box-sizing: border-box;
            border: 3px solid black;
            border-color: transparent transparent $white $white;
            transform-origin: 0 0;
            transform: rotate(135deg);
            box-shadow: -2px 2px 2px 0 rgba(0, 0, 0, 0.07);
        }
    }

    .dropdown-heading {
        cursor: pointer !important;
        height: 36px !important;
        padding: 0 0 !important;
        border-radius: 0 !important;

        .dropdown-heading-value {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &::after {
                content: "\f107";
                font-weight: 900;
                color: $table-th-color;
                cursor: pointer;

                @extend .custom-fontawesome;
            }
        }

        .dropdown-heading-dropdown-arrow {
            display: none !important;
        }
    }
}

// Datepicker styles

.react-datepicker-popper {
    z-index : 10 !important;
    .react-datepicker {
        flex-direction: column;
        max-height: none !important;
    }

    .react-datepicker__input-time-container {
        .react-datepicker-time__input {
            height: auto !important;
            padding: 4px !important;
            width: auto !important;
            margin-left: 10px !important;
        }

        .react-datepicker-time__caption {
            font-size: 16px;
            color: #6b7897;
        }
    }
}

.react-datepicker__input-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &::after {
        font-size: 14px;
        font-weight: 900;
        position: absolute;
        color: $table-th-color;
        content: "\f133";
        cursor: pointer;
        right: 10px;

        @extend .custom-fontawesome;
    }
}

// Card Section Styles
.cardui-cards {
    width: 50%;
    margin-bottom: 30px;

    &:nth-child(even) {
        padding-right: 15px;
    }

    &:nth-child(odd) {
        padding-left: 15px;
    }

    .cardui-content {
        background-color: $white;

        @include box-shadow(0 3px 10px 0 rgba(199, 199, 199, 0.5));

        .cardui-header {
            margin: 0 0 0 24px;
            padding: 16px 24px 16px 0;
            border-bottom: 1px solid rgba($theme-placeholder-text, 0.2);

            @extend .d-flex;

            @extend .align-items-center;

            @extend .justify-content-between;

            .cardui-header-content {
                .cardui-header-name {
                    font-size: 16px;
                    line-height: 19px;

                    @extend .lato-bold;

                    @extend .theme-primary;

                    @extend .text-uppercase;
                }

                .btn-theme {
                    height: 28px;
                    padding: 0;
                    min-width: 100px;
                    min-height: 28px;
                }
            }
        }

        .cardui-body {
            margin: 0 0 0 24px;
            padding: 16px 24px 16px 0;

            .cardui-body-content {
                overflow: auto;
                min-height: 216px;
                max-height: 216px;
            }

            &.cardui-has-footer {
                overflow: auto;
                position: relative;
                padding-bottom: 88px;

                .cardui-footer {
                    left: 0;
                    width: calc(100% - 24px);
                    bottom: 16px;
                    position: absolute;
                }
            }

            &.cardui-has-nodata {
                .cardui-body-content {
                    min-height: 288px;
                    max-height: 288px;

                    @extend .d-flex;

                    @extend .flex-column;

                    @extend .align-items-center;

                    @extend .justify-content-center;

                    p {
                        color: $theme-placeholder-text;
                        margin: 0;
                        font-size: 24px;
                        line-height: 33px;

                        &:last-of-type {
                            margin-bottom: 40px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) and (min-width: 320px) {
    .successalert-card {
        margin: 40px auto 0;
        padding: 13px 14px 13px 30px;
        max-width: none;
        @include flex(flex-start, center);
        > i.fas {
            top: 12px;
            left: 6px;
            font-size: 18px;
        }
        > span {
            font-size: 12px;
            > a {
                padding-left: 5px;
                text-decoration: underline;
            }
        }
        + .resend-wrapper {
            margin: 16px 0 0;
            text-align: center;
        }
    }
    // Custom Card inputs styles Starts
    .cardbox-wrapper {
        .cardbox-heading {
            height: 50px;
            padding: 0 16px;
            .cardbox-heading-name {
                font-size: 12px;
                line-height: 14px;
                font-weight: 600 !important;

                &.text-black{
                    color: #000000;

                    .item-numbering{
                        color: #000000;
                        background: #FFFFFF;
                        border: 1px solid #000000;
                        box-sizing: border-box;
                        border-radius: 50%;
                        padding: 3px 10px;
                        margin-right: 12px;
                    }
                }
              
            }
        }
        .cardbox-body {
            padding: 0 12px 24px;
            &.cardbox-body-text {
                padding: 0.75rem;
            }
            .MuiSwitch-colorSecondary.Mui-checked {
                color: #1f870e;
            }
            .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
                background-color: #1f870e;
            }
        }
    }

    // Custom Form inputs styles Starts
    .inputcard-wrapper {
        .inputcard-body {
            &.custom-inputcard-body {
                .inputcard-group {
                    padding: 30px 0 0;

                    &.two-cardbox {
                        .choose-reason {
                            width: 100%;
                        }
                    }

                    .choose-reason {
                        width: 100%;
                        padding: 0;
                        &:not(:last-child) {
                            padding: 0 0 30px 0;
                        }

                        &.inputfield {
                            .custominputfield {
                                width: 100%;
                                height: 39px;
                                padding: 0 1rem;
                                font-size: 12px;
                                line-height: 14px !important;
                            }
                            .custominputfieldfortext {
                                width: 100%;
                                height: 223px;
                                border: 1px solid #979797;
                                padding: 0 1rem;
                                font-size: 12px;
                                line-height: 14px !important;
                                @include border-radius(4px);
                            }
                        }
                        &.textareafield {
                            height: 120px;
                            &.textareafield-label {
                                height: auto;
                                > .inputcard-withlabel {
                                    height: auto;
                                }
                                .customtextareafield {
                                    font-size: 14px;
                                }
                            }
                        }

                        > span {
                            font-size: 14px;
                            line-height: 17px;
                            white-space: nowrap;
                        }

                        .inputcard-withlabel {
                            width: 100%;
                            position: relative;

                            &.add-new-feature-wrapper {
                                .add-new-feature {
                                    font-size: 12px;
                                    line-height: 19px;
                                }
                            }
                            input {
                                height: 39px;
                                font-size: 12px;
                                line-height: 14px;
                            }
                            .inputcard-label {
                                font-size: 14px;
                                line-height: 19px;
                                margin-bottom: 10px;
                                .input-mandatory {
                                    line-height: 14px;
                                }
                            }
                            .select-wrapper {
                                position: relative;

                                .select-dropdown__control {
                                    .select-dropdown__value-container {
                                        height: 37px;

                                        .select-dropdown__placeholder {
                                            font-size: 12px;
                                            line-height: 14px;
                                        }

                                        .select-dropdown__single-value {
                                            font-size: 14px;
                                        }

                                        .select-dropdown__input {
                                            > input {
                                                font-size: 14px !important;
                                            }
                                        }
                                    }
                                }

                                .select-dropdown__menu {
                                    .select-dropdown__menu-list {
                                        .select-dropdown__option {
                                            height: 40px;
                                            padding: 8px 16px;
                                            font-size: 14px;
                                        }
                                    }
                                }
                            }
                        }

                        &.multiselect-headings {
                            .multiselect-dropdown-container {
                                width: 100%;

                                .dropdown-heading {
                                    height: 39px !important;

                                    .dropdown-heading-value {
                                        height: 37px !important;
                                        padding: 0 16px !important;

                                        > span {
                                            font-size: 14px;
                                            line-height: 19px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .common-address-wrapper {
                    .inputcard-body {
                        .inputcard-group {
                            .choose-reason-address {
                                &:not(:last-of-type) {
                                    margin-bottom: 0px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .inputcard-footer {
            padding: 24px 0px 0;

            @include flex(flex-end, center);
        }
    }
    .btn-theme {
        padding: 15px 10px;
        font-size: 12px;
        font-weight: 400 !important;
        min-width: 75px;
        min-height: 34px;
        line-height: 14px;
        margin: 10px 0;
    }
    .back-btn-section {
        display: none;
    }
}
@media (max-width: 480px) and (min-width: 320px) {
    .inputcard-wrapper {
        .inputcard-footer {
            .btn-search-wrapper {
                width: 100%;
                padding: 0;
                max-width: none;
                min-height: 38px;
            }
        }
    }
    .back-btn-section {
        display: none;
    }
}
