.admin-wrapper,
.pagination-wrapper {
  padding: 5rem;
  .pagination {
    text-align: center;
    padding: 2rem;
    display: inline-flex;
  }
  .pagination a {
    color: black;
    float: left;
    padding: 5px 10px;
    text-decoration: none;
    transition: background-color 0.3s;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 0 3px;
    font-size: 13px;
    text-align: center;
  }

  .pagination a.active {
    background-color: #4caf50;
    color: white;
    border: 1px solid #4caf50;
  }

  .pagination a:hover:not(.active) {
    background-color: #ddd;
  }
  .admin-form {
    .cardbox-wrapper {
      .cardbox-heading {
        // To do: clear filter
      }
      .inputcard-wrapper {
        .inputcard-body {
          .inputcard-group {
            .filter-div {
              .inputcard-field {
                width: 75%;
                padding-right: 0.5rem;
                input {
                  height: 45px;
                }
              }
              .inputcard-action {
                width: 25%;
                padding-top: 3.2rem;
                .btn-theme {
                  min-width: 100%;
                  min-height: 45px;
                  padding: 10px;
                }
              }
            }
          }
        }
      }
    }
  }

  .btn-icon {
    background: transparent;
    border: none;
    padding: 0;
    order: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    color: #2e7d32;
    margin-left: 0.5rem;

    &:hover {
      color: white;
      background-color: #2e7d32;
    }
  }

  .memory-wrapper {
    margin-top: 3rem;
    .cardbox-wrapper {
      .nav {
        background-color: white;
        display: flex;
        a {
          color: lightgray;
          font-size: 15px;
          line-height: 24px;
          // width:30%;
          flex: 1;
          border-top-width: 0px;
          border-bottom-width: 0px;
          border-right-width: 0px;
          border-left-width: 0px;
          font-weight: bold;
          text-align: center;
        }
        a.nav-item.nav-link.active {
          color: $brand-primary;
          border-bottom: 3px solid orange;
          background-color: none;
          text-align: center;
        }
        a.nav-item.nav-link {
          align-items: center;
        }
      }
      .memory-list-wrapper {
        padding: 2rem;
        .header-label {
          color: #979797;
          font-size: 16px;
        }
        .memory-card-wrapper {
          .decedent-list-wrapper {
            margin-bottom: 0.5rem;
            padding: 1rem;
            .container {
              margin-left: 0;
              margin-right: 0;
              max-width: 1190px;

              .row {
                .name-wrapper {
                  display: flex;
                  .index-wrapper {
                    padding-right: 1rem;
                  }
                }
              }
            }
            &:hover {
              // border: 1px solid red;
              box-shadow: 0px 15px 35px rgba($black, 0.1);
              cursor: pointer;
            }
          }
        }
        .memory-card {
          box-shadow: 0px 10px 30px rgba($black, 0.05);
          padding: 2rem;
          .memory-header {
            .header-text {
              display: inline-block;
              color: #212121;
              font-size: 24px;
              margin-left: 0.5rem;

              .edit-header-title {
                width: 450px;
              }
            }
          }

          .memory-content {
            .media-div {
              width: 100%;

              .file-display {
                .error-div {
                  border: 3px solid gray;
                  display: flex;
                  justify-content: center;
                }
                .video-file-div {
                  display: flex;
                  justify-content: center;
                  border-radius: 5px;
                  height: 270px;
                  // max-height: 600px;
                  width: auto;
                  // width: 600px;
                  max-width: 100%;
                  position: relative;
                  .video-file,
                  div,
                  iframe {
                    width: 100%;
                    height: 100%;
                  }
                }
                .displayFileContiner {
                  display: flex;
                  .icons-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-left: auto;
                  }
                }

                .image-file-div {
                  display: flex;
                  justify-content: center;
                  height: 270px;
                  // max-height: 600px;
                  max-width: 100%;
                  position: relative;
                  img {
                    border-radius: 5px;
                    // height: 264px;
                    // width: 600px;
                    object-fit: contain;
                  }
                }
                .audio-file-div {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 5px;
                  height: 264px;
                  width: 474px;
                  position: relative;
                  max-width: 100%;
                  .audio-file {
                    width: 100%;
                  }
                }
                .application-file-div {
                  display: flex;
                  align-items: center;
                  padding-left: 20px;
                  border-radius: 5px;
                  height: 64px;
                  font-weight: bold;
                  width: 474px;
                  position: relative;
                  max-width: 100%;
                  .fileName {
                    cursor: pointer;
                  }
                }
                // i {
                //     position: absolute;
                //     top: -7px;
                //     right: -7px;
                // }
                .ellipse-container {
                  position: absolute;
                  top: -4px;
                  right: -4px;
                  padding: 5px;
                }
                .noFiles-ecllipse {
                  position: absolute;
                  top: 16px !important;
                  right: 5px !important;
                  padding: 5px;
                }
                .modal-close {
                  color: white;
                  background-color: red;
                  height: 18px;
                  width: 18px;
                  border-radius: 50%;
                  display: flex;
                  justify-content: center;
                  align-content: center;
                  //  flex: 1;
                  // position: absolute;
                  // top: -7px;
                  // right: -7px;
                }
                .rotate-image {
                  height: 25px !important;
                  width: 25px !important;
                  margin-top: 10px;
                  margin-left: 1px;
                  //   position: absolute;
                  cursor: pointer;
                  //  right: -9px;
                  //top: 25px;
                }
                .download-image {
                  height: 25px !important;
                  width: 25px !important;
                  margin-top: 10px;
                  margin-left: 1px;
                  //   position: absolute;
                  cursor: pointer;
                  //  right: -9px;
                  // top: 65px;
                }
              }

              .file-navigation {
                .rowClass {
                  margin: 0px;
                }
                .documentThumbnail {
                  height: 72px;
                  width: auto;
                  object-fit: cover;
                  border-radius: 5px;
                }
                .showIndex {
                  display: flex;
                  justify-content: center;
                  font-size: 12px;
                  font-weight: bold;
                }
                padding: 0.5rem 0rem;
                display: flex;
                width: 100%;
                justify-content: center;
                .carousel.slide {
                  // margin-left: 21px;
                  // margin-left: auto;
                  // margin-right: auto;
                  .carousel-indicators {
                    display: none;
                  }

                  .carousel-inner {
                    max-width: 400px;
                    display: flex;
                    align-items: center;
                    max-height: 100%;
                    position: relative;
                    box-shadow: 0px 7.52257px 22.5677px rgba(0, 0, 0, 0.15);
                    border-radius: 3.00903px;
                  }
                  .carousel-item {
                    background-color: #f1f3f4;
                  }

                  .carousel-control-prev,
                  .carousel-control-next {
                    width: 40px;
                    height: 40px;
                    opacity: 1;
                    bottom: auto;
                    top: calc(50% - 20px);
                    background-color: $white;
                    border-radius: 50%;
                    span:not(.sr-only) {
                      display: none;
                    }
                    &:before {
                      content: '';
                      width: 10px;
                      height: 10px;
                    }
                  }
                  .carousel-control-prev {
                    left: -40px;
                    &:before {
                      background: url(/assets/images/arrow-left.svg) no-repeat
                        center;
                      background-size: contain;
                    }
                  }
                  .carousel-control-next {
                    right: -40px;
                    &:before {
                      background: url(/assets/images/arrow-right.svg) no-repeat
                        center;
                      background-size: contain;
                    }
                  }
                }
                .memory-file-nav {
                  padding: 0.2rem;
                  height: 80px;
                  width: 75px;
                  .error-div {
                    border: 2px solid gray;
                    height: 100%;
                    width: 100%;
                    padding: 7px;
                  }
                  .image-file-nav-div {
                    height: 100%;
                    width: 100%;
                    img {
                      height: 100%;
                      width: 100%;
                      border-radius: 5px;
                      object-fit: cover;
                    }
                  }
                  .video-nav-file {
                    width: 100%;
                    height: 100%;
                  }
                  .video-file-nav-div {
                    width: 100%;
                    height: 100%;
                    .thumbnail-image {
                      width: 100%;
                      height: 100%;
                      img {
                        width: 100%;
                        height: 100%;
                      }
                      svg {
                        fill: lightgray;
                        background-color: black;
                        height: 100%;
                        width: 100%;
                        border-radius: 5px;
                        padding: 10px;
                      }
                    }
                  }
                  .application-file-nav-div {
                    width: 100%;
                    height: 100%;
                    border-radius: 5px;
                  }
                }

                .audio-file-nav-div {
                  background: lightgrey;
                  border-radius: 4px;
                  padding: 0.5rem;
                  height: 100%;
                  width: 100%;
                  border: 3px solid darkgrey;

                  svg {
                    fill: grey;
                  }
                }
              }
              .add-image {
                width: 70px;
                height: 80px;
                position: relative;
                white-space: nowrap;
                text-overflow: ellipsis;
                margin-left: 0.2rem;
                background: #c4c4c4;
                border-radius: 8px;
                display: flex;
                align-items: center;
                cursor: pointer;
                span {
                  display: table;
                  margin-right: auto;
                  margin-left: auto;
                  width: 2;
                  font-size: 30px;
                }
              }
              .disabled {
                cursor: not-allowed;
              }
            }

            .header-text {
              color: #212121;
              font-size: 18px;
              margin-left: 0.5rem;
            }

            .content-div {
              // padding: 2rem;
              padding-left: 15px;
              padding-right: 15px;
              .detail-div {
                margin-top: 1rem;
                line-height: 1.6;
              }

              .description-textarea {
                height: 450px;
                width: 430px;
                margin-left: 0.5rem;
                padding: 0.5rem 1rem;
              }
            }
            .label-wrapper {
              display: inline-block;
            }
            .value-wrapper {
              display: inline-block;
            }
          }

          .memory-card-footer {
            justify-content: flex-end;
            .btn-decline {
              background-color: #eb5757;
              margin-right: 1rem;
            }
            .rejected-message {
              background: #ffcccb;
              width: 250px;
              padding: 15px;
              box-sizing: border-box;
              text-align: center;
              font-size: 15px;
              color: #eb5757;
              margin-right: 1rem;
            }
            .approved-message {
              background: #def3df;
              width: 250px;
              padding: 15px;
              box-sizing: border-box;
              text-align: center;
              font-size: 15px;
              color: #2e7d32;
              margin-right: 1rem;
            }
          }

          textarea {
            display: block;
            width: 100%;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out,
              box-shadow 0.15s ease-in-out;

            color: #212121 !important;

            -webkit-writing-mode: horizontal-tb !important;
            text-rendering: auto;
            letter-spacing: normal;
            word-spacing: normal;
            text-transform: none;
            text-indent: 0px;
            text-shadow: none;
            appearance: textfield;
            -webkit-rtl-ordering: logical;
            cursor: text;
          }
        }
      }
    }
  }
  .loginReport-wrapper {
    .select-modal {
      min-width: 40%;
      .modal-content {
        background-color: transparent !important;
        .modal-header {
          background-color: transparent !important;
          padding: 30px 10px 30px 30px !important;
          .modal-close {
            color: white !important;
          }
        }
        .modal-body {
          padding: 0 !important;
        }
        .select-input {
          div[class*='-control'] {
            display: none;
          }
          div[class*='-menu'] {
            margin-bottom: 0;
            margin-top: 0;
          }
          div[class*='-MenuList'] {
            div {
              display: flex;
              justify-content: center;
            }
          }
        }
      }
    }

    .users-wrapper {
      margin-top: 50px;
      .users-list-wrapper {
        padding: 1rem;
      }
      td {
        color: #212121;
        font-size: 1rem;
      }
    }
    .pagination {
      text-align: center;
      padding: 2rem;
      display: inline-flex;
    }
    .pagination a {
      color: black;
      float: left;
      padding: 5px 10px;
      text-decoration: none;
      transition: background-color 0.3s;
      border: 1px solid #ddd;
      border-radius: 5px;
      margin: 0 3px;
      font-size: 16px;
      text-align: center;
    }
    .pagination a.active {
      background-color: #4caf50;
      color: white;
      border: 1px solid #4caf50;
    }
    .pagination a:hover:not(.active) {
      background-color: #ddd;
    }
  }
}
.addvideoUrl-modal {
  max-width: none !important;
  .videoPreviewContainer {
    max-height: 300px;
    overflow-y: auto;
  }
  .inputcard-group .choose-reason {
    padding-bottom: 0px !important;
  }
  @media (max-width: 820px) and (min-width: 320px) {
    max-width: 90% !important;
    .videoPreviewContainer {
      max-height: 200px !important;
    }
  }
  .custom-close-button {
    width: 30px;
    height: 30px;
    z-index: 1;
    position: absolute;
    top: -15px;
    right: -10px;
    cursor: pointer;
    object-fit: contain;
    color: #212529;
  }
  .AddVideoUrl-container {
    .save-button {
      background-color: green;
      margin-top: 5px;
      margin-bottom: 20px;
      padding: 10px 5px;
      color: white;
      width: 100px;
      height: 50px;
      text-transform: uppercase;
      border: 6px;
      border-radius: 6px;
      font-size: 14px;
      @media (max-width: 767px) and (min-width: 320px) {
        width: 60px;
        height: 30px;
        padding: 5px;
        font-size: 12px;
      }
    }
  }
}
.btn-video {
  cursor: pointer;
  border: 0;
  color: white;
  padding: 15px 10px;
  font-size: 16px;
  line-height: 1.43;
  letter-spacing: 0.2px;
  background-color: #2e7d32;
  margin-right: 5px;
}
.AddingVideoUrl {
  min-width: 140px;
  max-height: 50px;
  margin-top: 50px;
  padding-left: 23px;
}
.AddingVideoUrlFirst {
  min-width: 140px;
  max-height: 50px;
  margin-top: 25px;
  margin-left: 10px !important;
}
.ellipse-container {
  .optionsDropdown {
    z-index: 2;
  }
  .dropdown {
    transition: none !important;
    .ToggleButton {
      background-color: white !important;
      border: none !important;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      padding: 0px !important;
      transition: none !important;
    }
    .ToggleButton-banner {
      background-color: transparent !important;
      margin: 0;
      border: none !important;
      height: 36px;
      width: 38px;
      padding: 0px !important;
      transition: none !important;
    }
    .CameraIcon {
      height: 40px;
      width: auto;
    }
    .ToggleButton:hover {
      border: none !important;
      background-color: none !important;
      box-shadow: none !important;
    }
    .ToggleButton:focus {
      border: none !important;
      box-shadow: none !important;
    }
    .ToggleButton-banner:hover {
      border: none !important;
      background-color: none !important;
      box-shadow: none !important;
    }
    .ToggleButton-banner:focus {
      border: none !important;
      box-shadow: none !important;
    }
  }
  .dropdown-menu {
    padding: 0px !important;
    .menuItem {
      border-bottom: 1px solid rgba(151, 151, 151, 0.3) !important;
      padding: 0.5rem 1rem !important;
      font-weight: 500 !important;
      background-color: white !important;
    }
    .menuItem:hover {
      background-color: #a8c090 !important;
    }
  }
}

.pagination-wrapper {
  padding: 0;
  text-align: center;
}
