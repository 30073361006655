.modal-open {
    .modal{
        display: flex !important;
        align-items: center;
        justify-content: center;
        .modal-dialog{
            margin: 0;
            .modal-content{
                border: 1px solid transparent;
                background-color: $white;
                .modal-header{
                    border: 0;
                    padding: 30px;
                    background-color: $theme-table-nth;
                    > h5{
                        width: 100%;
                        display: block;
                        position: relative;
                        .modal-headername{
                            color: $brand-primary;
                            display: block;
                            font-size: 24px;
                            line-height: 1;
                            @extend .lato-bold;
                        }
                        .modal-close{
                            top: 4px;
                            right: 0;
                            color: $nero;
                            margin: 0;
                            cursor: pointer;
                            opacity: 1;
                            z-index: 1;
                            position: absolute;
                            font-size: 16px;
                            text-shadow: none;
                        }
                    }
                }
                .modal-body{
                    padding: 0 30px;
                }
                .modal-footer{
                    border: 0;
                    padding: 20px 30px;
                }
            }
        }
    }
}
