.createprofile-wrapper{
    @media (min-width: 1200px) {
        max-width: 600px;
    }
    .createprofile-content{
        .positioned-back{
            position: relative;
            margin-bottom: 2rem;
            @media (min-width: 768px) {
                padding-left: 39px;
            }
            @media (max-width: 575px) {
                margin-bottom: 16px;
            }
            > a{
                top: 50%;
                left: 0;
                margin: 0;
                padding: 0;
                position: absolute;
                @include transform(translate(0, -50%));
                > i{
                    color: $brand-primary;
                    font-size: 0.9375rem;
                }
            }
            .createprofile-name{
                color: $theme-text;
                font-size: 32px;
                margin-bottom: 24px;
                @extend .lato-bold;
                @media (min-width: 320px) and (max-width: 767px){
                    font-size: 24px;
                }
            }
            .btn-logout{
                left: auto;
                right: 0;
                color: red;
                font-size: 12px;
            }
        }
        .createprofile-form-wrapper{
            > form{
                &:not(:last-of-type){
                    margin-bottom: 30px;
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 991px){
    .createprofile-wrapper{
        .createprofile-content{
            .createprofile-form-wrapper{
                .inputcard-wrapper .inputcard-body.custom-inputcard-body .inputcard-group{
                    .choose-reason{
                        width: 100%;
                        &:last-child{
                            margin-top: 40px;
                        }
                    }
                } 
            }
        }
    }
}