.whattoexpect-wrapper{
    .whattoexpect-banner{
        @include flex(center, center);
        background-color: $white;
        .whattoexpect-banner-image{
            max-width: 100%;
            object-fit: cover;
        }
    }
    .whattoexpect-welcome{
        margin-top: 24px;
        .whattoexpect-welcome-header{
            color: $brand-primary;
            font-size: 30px;
            @extend .lato-bold;
        }
        .whattoexpect-welcome-list{
            padding-left: 40px;
        }
    }
}