@import '../../../../assets/styles/_color.scss';

.service-card-action-panel {
  .control {
    border-radius: 3px;
    padding: 15px;
    margin-bottom: 60px;
    box-shadow: 0px 4px 92px rgba(208, 208, 208, 0.35);
  }

  .divider {
    position: relative;
    z-index: 0;
    left: 50%;
    margin-top: -60px;
    height: 60px;
    border-left: 1px dashed $grey3;
  }
}
.instructionsTitle {
  border: none;
  margin-bottom: 45px;
  background-color: transparent;
  color: #298a45;
  text-decoration-line: underline;
}
.instructionModal {
  li {
    padding-bottom: 10px;
  }
  max-width: 90% !important;
  max-height: 90% !important;
  overflow-y: auto;
  .pictureFormat{
    color: blue;
    font-weight: bold;
    font-style: italic;
    
  }
  .change-picture {
    padding-left: 5px;
    padding-right: 5px;
    border: 1px solid blue;
    background-color: blue;
    color: white;
    font-weight: bold;
    border-radius:4px;
  }
  .wordHeading {
    padding-bottom: 30px !important;
  }
  .osHeading {
    font-weight: bold;
    font-size: 18px;
    color: #298A45;
    padding-bottom: 25px;
  }
  .versionHeading {
    font-weight: bold;
    color: #298A45;
    font-size: 16px;
    padding-bottom: 10px;
  }
  .mainUl {
    padding-left: 20px;
  }
  .replaceHeading {
    font-weight: bold;
    font-size: 16px;
    color: #298A45;
    padding-bottom: 25px;
  }
  .instructionImageContainer {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  .instructionImage {
    object-fit: contain;
    width: auto;
  }
}
}
