.memory-container {
    // padding-left: 0;
    // margin-left: 0;
    // padding-right: 0;
    // margin-right: 0;
   // max-width: unset !important;
    @media (max-width:650px) {
        padding-left: 7px !important;
        padding-right: 7px !important;
    }
}

.memories-wrapper-family {
    display: flex;
    // margin: 30px 40px;
    margin-bottom: 20px;

    .MuiSwitch-colorSecondary.Mui-checked {
        color: #1f870e;
    }
    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
        background-color: #1f870e;
    }

    .MuiFormControlLabel-label {
        font-weight: 600 !important;
        font-size: 17px;
    }
    .event-time {
        justify-content: start;
        align-items: center;
        flex-wrap: wrap;
        .endTime {
            padding-right: 7px;
        }
        .startTime {
            padding-right: 5px;
        }
    }
    .hideEndTime {
        .MuiFormControlLabel-label {
            font-weight: 600 !important;
            font-size: 12px;
        }
        .MuiSwitch-roo {
            width: 52px;
            height: 35px;
        }
        .MuiSwitch-thumb {
            height: 18px;
            width: 18px;
        }
    }

    .memories-left-content-wrapper-family {
        width: 28%;
        // padding: 0px 20px;
        padding-left: 38px;
        padding-bottom: 20px;
        margin-right:10px;
        position: relative;

        .profile-pic-wrapper {
            height: 220px;
            width: 220px;
            background-color: rgb(237,235,235);

            &.loading {
                align-items: center;
                display: flex;
                justify-content: center;
            }

            .profile-pic-wrapper-loading {
                width: 180px;
                height: 180px;
            }
        }

        ul {
            list-style: none;
            padding-left: 0;
            margin-bottom: 0;
            li {
                padding-top: 20px;
                .options-wrapper {
                    padding-left: 50px;
                    position: relative;
                    color: $theme-text-color;
                    font-family: "Lato";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    cursor: pointer;
                    line-height: 18px;
                    .icon-wrapper {
                        position: absolute;
                        top: 0;
                        left: 20px;
                        height: 19px;
                    }
                    .MuiSwitch-switchBase
                        .MuiFormControlLabel-label.MuiTypography-root {
                        font-weight: 600;
                    }
                    &.long-text-wrapper {
                        display: inline-flex;
                    }
                }

                &.empty-li {
                    height: 200px;
                }
            }
        }
        .bck-family-btn {
            width: 100%;
            height: 43px;
            background: $theme-btn-bg;
            border-radius: 8px;
            font-family: Roboto;
            font-style: normal;
            font-weight: 500 !important;
            font-size: 14px;
            line-height: 16px;
            // padding: 13px 0;
            // bottom: 65px;
            // position: absolute;
        }
    }
    .memories-not-found-content-wrapper {
        padding: 24px 0;
        width: 100%;
        text-align: center;
        min-height: 255px;
        height: fit-content;
    }
    .memories-top-content-wrapper-family {
        width: 78%;
        .obituary-wrapper-family {
            background: #ffffff;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
            border-radius: 16px;
            .share-div {
                display: flex;
                align-items: center;
                justify-content: end;
                .cancel-button {
                    text-transform: uppercase;
                    color: #000000;
                    cursor: pointer;
                    border: 0;
                    padding: 15px 10px;
                    font-size: 10px;
                    line-height: 1.43;
                    letter-spacing: 0.2px;
                    font-family: "Lato";
                    font-style: normal;
                    font-size: 14px;
                    padding-left: 10px;
                    width: 90px;
                    background-color: #f0f0f0;
                    height: 45px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 10px;
                    border-radius: 6px;
                }
                .share-button {
                    text-transform: uppercase;
                    color: #fff;
                    cursor: pointer;
                    border: 0;
                    padding: 15px 10px;
                    font-size: 10px;
                    line-height: 1.43;
                    letter-spacing: 0.2px;
                    font-family: "Lato";
                    font-style: normal;
                    font-size: 14px;
                    padding-left: 10px;
                    width: 90px;
                    background-color: #337a2e;
                    height: 45px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 10px;
                    border-radius: 6px;
                }
            }
        }
    }
    .memories-main-content-wrapper-family {
        margin-top: 30px;
        width: 72%;
        min-height: 155vh;
        .memories-tab {
            margin-bottom: 30px;
            //height: 58px;
            background-color: #f7fbf8;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;

            &.justify-end {
                justify-content: end;
            }

            .family-website-nav-tabs {
                display: flex;
                //max-width: 70%;
                justify-content: space-between;

                .nav-tab-button {
                    color: #000000;
                    cursor: pointer;
                    padding: 15px 10px;
                    border: 0;
                    font-size: 18px;
                    min-width: 120px;
                    max-width: 180px;
                    background-color: #e0e0e0;
                    height: 45px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 10px;
                    padding-left: 10px;
                    border-radius: 6px;
                    margin-right: 20px;

                    &.active {
                        background-color: #f7fbf8;
                        color: #2c8c48;
                        border: 1px solid #91c39f;
                    }
                    .approve-memory-button {
                        width: 200px;
                    }
                }
            }
            .share-div {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .cancel-button {
                    text-transform: uppercase;
                    color: #000000;
                    cursor: pointer;
                    border: 0;
                    padding: 15px 10px;
                    font-size: 10px;
                    line-height: 1.43;
                    letter-spacing: 0.2px;
                    font-family: "Lato";
                    font-style: normal;
                    font-size: 14px;
                    padding-left: 10px;
                    width: 90px;
                    background-color: #f0f0f0;
                    height: 45px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 10px;
                    border-radius: 6px;
                }
                .share-button {
                    text-transform: uppercase;
                    color: #fff;
                    cursor: pointer;
                    border: 0;
                    padding: 15px 10px;
                    font-size: 10px;
                    line-height: 1.43;
                    letter-spacing: 0.2px;
                    font-family: "Lato";
                    font-style: normal;
                    font-size: 14px;
                    padding-left: 10px;
                    width: 90px;
                    background-color: #337a2e;
                    height: 45px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 10px;
                    border-radius: 6px;
                }
            }
            .share-memory-button {
                text-transform: uppercase;
                color: #fff;
                cursor: pointer;
                border: 0;
                padding: 15px 10px;
                font-size: 10px;
                line-height: 1.43;
                letter-spacing: 0.2px;
                border-radius: 5px;
                font-family: "Lato";
                font-style: normal;
                font-size: 14px;
                padding-left: 10px;
                width: 190px;
                background-color: #337a2e;
                //height: 45px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 10px;
                padding-left: 10px;
                border-radius: 6px;
            }
            .uploadMediaWrapper {
                .share-memory-button {
                    width: 140px;
                    margin-right: 20px;
                }
            }
        }
        .memories-tab-responsive{
            @media (max-width:650px){
                display:flex;
                flex-direction: column;
                align-items: start;
                margin-bottom:65px;
                .share-memory-button-wrapper{
                    display:flex;
                    align-items: center;
                    justify-content: end;
                }
            }
        }
        .memories-tab-responsive-login{
            @media (min-width: 576px) and (max-width:1399px){
                display:flex !important;
                flex-direction: row;
                align-items: start;
                margin-bottom:65px;
                .uploadMedia-shareMemoryWrapper {
                    width: 100%;
                    align-items: center;
                    justify-content: end;
                }
                .share-memory-button-wrapper{
                    display:flex;
                    align-items: center;
                    justify-content: end;
                }
            }
        }
        .obituary-wrapper-family {
            background: #ffffff;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
            border-radius: 16px;
        }
        .tribute-store-button-wrapper {
            padding-top: 14px;
            margin-top: auto;
            .btnWrapper{
                display: flex;
                justify-content: flex-end;
            }
        }
        .obituary-header-family, .uploadMedia-shareMemoryWrapper {
            .btnWrapper{
                display: flex;
                justify-content: flex-end;
            }
        }
        .mobileViewTribute {
            flex-direction: column;
        }
        .desktop-header {
            .plantTreeBtn {
               margin-right: 20px;
            }
        }
        .memories-tab {
            .tribute-store-redirection-button {
                margin-top: 10px;
                border-radius: 6px;
            }
            .plantTreeBtn {
                background-color: #337a2e;
            }
        }
        .navBarTributeStoreButtons {
            .tribute-store-redirection-button {
                min-width: 155px;
                margin-right: 0px;
            }
            @media (min-width:480px) {
                .btnWrapper {
                    display: flex;
                    .tribute-store-redirection-button {
                        margin-right: 10px;
                    }
                }
            }
        }
        .uploadMedia-shareMemoryWrapper {
            justify-content: flex-end;
        }

        .tribute-store-redirection-button {
            text-transform: uppercase;
            color: #fff;
            cursor: pointer;
            border: 0;
            padding: 10px 10px;
            font-size: 10px;
            letter-spacing: 0.2px;
            border-radius: 18px;
            font-family: "Lato";
            font-style: normal;
            font-size: 14px;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        @media (max-width: 300px) {
            .tribute-store-redirection-button {
                word-break: break-all;
                .tooltipButton{
                    .tooltipButton:hover::after{
                        word-break: break-all;
                    }
                }
            }
        }
        .sendFlowerBtn {
            background-color: #ff5a5f ;
        }
        .plantTreeBtn {
            background-color: #406c4f;
        }
        .tooltipButton {
            position: relative;
            display: inline-block;
            }
            .tooltipButton:hover::after {
            content: "The day of the event has already passed,please check for Upcoming Event.";
            position: absolute;
            background-color: black;
            color: white;
            padding: 5px;
            border-radius: 8px;
            font-size: 12px;
            bottom: 113%;
            left:0;
            width: 150px;
            text-transform: none;
            word-break: auto-phrase;
            }
            .tooltipButton:hover::before {
            content: "";
            position: absolute;
            }
        .disableButton {
            cursor: not-allowed;
            background: transparent;
            background-color: #c4c4c4;
        }
        .note {
            padding-top: 10px;
            font-size: 13px;
        }
        .obituary-header-family {
            padding: 30px;
            position: relative;
            background-color: #EAF2EB;
            display: flex;
            align-items: center;
            justify-content: space-between;

            h2 {
                padding-bottom: 0;
            }
        }
        @media (max-width:567px) {
            .desktop-header {
                display: none !important;
            }
            .mobile-events-header {
                padding: 30px;
                position: relative;
                background-color: #EAF2EB;
                justify-content: space-between;
                h2 {
                    padding-bottom: 0;
                }
                .mobileViewButton {
                    margin-top: 10px;
                }
            }
        } 
        @media (max-width:576px) and (min-width:346px) {
            .tooltipButton:hover::after  {
                width: 120px;
            }
        }
        @media (max-width:345px) {
            .tooltipButton:hover::after  {
                width: 80px;
            }
        }
        @media (max-width:800px) and (min-width:600px){
            .tooltipButton:hover::after  {
                width: 100px;
            }
        } 
        @media (max-width:644px) and (min-width:480px){
            .tooltipButton:hover::after  {
                width: 80px;
            }
        }
        .mobile-data-wrapper {
            display: none;
        }
        .cancel-button {
            margin-right: 20px;
            background-color: rgb(161, 163, 165);
            &:hover {
                background-color: #6c757d;
                color: #fff;
            }
        }

        .custom-btn {
            &:hover {
                color: #fff;
            }
        }
        h2 {
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 27px;
            color: #000000;
            margin-bottom: 0;
            padding-bottom: 24px;
        }
        .story-wrapper {
            width: 100%;
            min-height: 255px;
            height: fit-content;
            padding: 20px 30px;
            border-radius: 8px;
            padding-bottom: 50px;

            .profile-wrapper {
                display: flex;
                position: relative;
                .profile-picture-wrapper {
                    width: 160px;
                    position: relative;

                    &.disable-onhover {
                        pointer-events: none;
                    }
                    img {
                        width: 160px;
                        height: 160px;
                        object-fit: cover;
                    }
                    .link-customise {
                        @include center(both);
                        min-width: 70px;
                        min-height: 30px;
                        padding: 2px;
                        color: white;
                        visibility: hidden;
                        z-index: 2;
                    }
                    &::after {
                        position: absolute;
                        content: "";
                        width: 100%;
                        height: 100%;
                        background: rgba($black, 0.5);
                        left: 0;
                        top: 0;
                        display: none;
                    }
                    &:hover {
                        &::after {
                            display: block;
                        }
                        .link-customise {
                            visibility: visible;
                        }
                    }
                    a {
                        cursor: pointer;
                        .BoldPic-wrapper {
                            top: 98px;
                            position: absolute;
                            right: 90px;
                        }
                        .ItalicPic-wrapper {
                            top: 130px;
                            position: absolute;
                            right: 90px;
                        }
                        .UPic-wrapper {
                            top: 164px;
                            position: absolute;
                            right: 90px;
                        }
                    }
                }
                .profile-thought-wrapper {
                    width: calc(100% - 160px);
                    font-family: Playfair Display;
                    font-style: normal;
                    // font-weight: bold;
                    font-size: 20px;
                    line-height: 27px;
                    color: #000000;
                    max-width: 490px;
                    padding: 5px 5px 5px 49px;
                    display: flex;
                    width: 100%;
                    flex-direction: row-reverse;
                    span {
                        font-family: Playfair Display;
                        // font-style: italic;
                        // font-weight: normal;
                        font-size: 18px;
                        line-height: 1.5;
                        color: black;
                    }
                    .profile-quote {
                        width: 100%;
                    }
                    .container-root {
                        height: 150px;
                        background: white;
                        box-sizing: border-box;
                        // box-shadow: 2px 0px 7px rgba(0, 0, 0, 0.1);
                        border-radius: 8px;
                        &.profile-quote {
                            min-height: 127px;
                            height: fit-content;
                            padding: 0.5rem 1rem;
                        }
                        h1 {
                            line-height: 24px;
                        }
                    }
                }
                .memory-content {
                    position: absolute;
                    width: 753px;
                    height: 418px;
                    left: 344px;
                    top: 1985px;
                    background: rgba(0, 0, 0, 0.38);
                }
                .memory-title {
                    word-break: break-word !important;
                    position: absolute;
                    width: 288px;
                    height: 27px;
                    left: 372px;
                    top: 1932px;

                    font-family: Inter;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    /* identical to box height, or 150% */

                    color: #000000;
                }
            }
            a {
                font-family: Lato;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 27px;
                color: #27ae60;
                background-color: #f7fbf8;
                border-radius: 4px;
            }
            .copyRightText {
                font-weight: bold;
                font-size: 12pt;
            }
            .read-less-more {
                padding: 20px 25px;
                text-align: center;
            }
            .lifeof-wrapper {
                display: flex;
                width: 100%;
                flex-direction: row-reverse;
                justify-content: flex-end;
                .container-root {
                    // height: 350px;
                    min-height: 350px;
                    height: fit-content;
                    width: calc(100% - 25px);
                    background: white;
                    box-sizing: border-box;
                    border-radius: 8px;
                    padding: 1rem;
                }
                .options {
                    padding: 0;
                    width: 25px;
                    .selection {
                        border: 0.5px solid #2d9cdb;
                    }
                }
            }
            .mask {
                position: relative;
                float: left;
                clear: none;
                background-color: white;
                width: 170px;
                height: 170px;
                padding: 5px;
                margin-right: 30px;
                .profile-picture-wrapper {
                    width: 160px;
                    position: relative;

                    &.disable-onhover {
                        pointer-events: none;
                    }
                    img {
                        width: 160px;
                        height: 160px;
                        object-fit: cover;
                    }

                    .link-customise {
                        @include center(both);
                        min-width: 70px;
                        min-height: 30px;
                        padding: 2px;
                        color: white;
                        visibility: hidden;
                        z-index: 2;
                    }
                    &::after {
                        position: absolute;
                        content: "";
                        width: 100%;
                        height: 100%;
                        background: rgba($black, 0.5);
                        left: 0;
                        top: 0;
                        display: none;
                    }
                    &:hover {
                        &::after {
                            display: block;
                        }
                        .link-customise {
                            visibility: visible;
                        }
                    }
                    a {
                        cursor: pointer;
                        .BoldPic-wrapper {
                            top: 98px;
                            position: absolute;
                            right: 90px;
                        }
                        .ItalicPic-wrapper {
                            top: 130px;
                            position: absolute;
                            right: 90px;
                        }
                        .UPic-wrapper {
                            top: 164px;
                            position: absolute;
                            right: 90px;
                        }
                    }
                }
            }
            .profile-description-wrapper {
                min-height: 170px;
                width: 100% !important;
                overflow: auto;

                &.maxHeight275 {
                    max-height: 275px;
                    overflow: hidden !important;
                }
            }
            .event-description-wrapper {
                min-height: 170px;
                width: 100%;
                overflow: auto;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .upcoming-event {
                    padding: 30px;
                    width: 46%;
                    border: 2px solid #f0f0f0;
                    border-radius: 4px;
                    min-height: 240px;
                    margin-bottom: 30px;
                    display: flex;
                    flex-direction: column;
                    .event-location {
                        font-weight: 600;
                        font-size: 25px;
                        line-height: 40px;
                        color: #000000;
                        text-transform: capitalize;
                    }

                    .event-service,
                    .event-address,
                    .event-date,
                    .event-time,
                    .event-broadCastUrl,
                    .event-passCode,
                    .event-chapel {
                        font-weight: 400;
                        font-size: 22px;
                        line-height: 26px;
                        color: rgba(0, 0, 0, 0.7);
                        margin: 14px 0 0 0;
                    }
                    .copyIcon {
                        cursor: pointer;
                        padding-left: 5px;
                        height: 25px;
                        margin-top: 2px;
                        width: 24px;
                        object-fit: contain;
                    }
                    .tickMark {
                        width: 30px;
                    }
                    .event-broadCastUrl,
                    .event-passCode {
                        .labelName {
                            padding-right: 5px;
                        }
                        .liveStreamHeading {
                            font-weight: bolder;
                        }
                        flex-wrap: wrap;
                        div {
                            flex-wrap: wrap;
                        }
                        .textContainer {
                            flex-wrap: wrap;
                        }
                        a {
                            color: #0368B0;
                            text-decoration: underline;
                        }
                    }
                    .passCodeDivWithNote {
                        margin-top: 5px;
                        word-break: break-word;
                        .noteMsg {
                            color:  #FF5631;
                            font-size: 19px;
                        }
                    }
                    .event-chapel {
                        font-weight: 600;
                    }

                    .event-address {
                        cursor: pointer;
                        text-decoration: underline;
                    }

                    .no-events {
                        font-weight: 400;
                        font-size: 22px;
                        line-height: 26px;
                        color: rgb(106, 102, 102);
                    }
                }

                @media (max-width: 567px){
                    .upcoming-event {
                        width: 100%;
                    }
                }
            }
            .profile-description-input {
                width: calc(100% - 210px);
                border: none;
                height: 40px;
            }
            input.profile-description-input[disabled] {
                background: none !important;
            }
        }
        .audio-wrapper {
            .audio-file {
                width: 70%;
                padding: 30px;
                audio {
                    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
                    border-radius: 30px;
                    width: 95%;
                }
            }

            .btn-edit {
                border: 2px solid #27ae60;
                background-color: white;
                color: #27ae60;
                padding: 6px;
                width: 250px;
            }
        }
        .memory-listing-family {
            margin-top: 40px;

            .share-memory-wrapper {
                display: flex;
                justify-content: end;
                margin-top: 40px;
                .share-memory-button {
                    padding-left: 10px;
                    width: 190px;
                    background-color: #337a2e;
                    height: 45px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 10px;
                    padding-left: 10px;
                    border-radius: 6px;
                    .send-button {
                        text-transform: uppercase;
                        color: #fff;
                        cursor: pointer;
                        border: 0;
                        padding: 15px 10px;
                        font-size: 10px;
                        line-height: 1.43;
                        letter-spacing: 0.2px;
                        border-radius: 5px;
                        font-family: "Lato";
                        font-style: normal;
                        font-size: 14px;
                    }
                }
            }

            .memory-filter {
                display: flex;
                justify-content: end;

                .filters-wrapper {
                    position: relative;
                    .selected-filter {
                        padding: 15px 20px;
                        min-width: 250px;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        border-radius: 4px;
                        background-color: #ffffff;
                        display: flex;
                        justify-content: space-between;
                    }

                    .filter-options {
                        position: absolute;
                        z-index: 9999999;
                        background: white;
                        min-width: 250px;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        border-radius: 4px;
                        padding-bottom: 12px;
                        padding-top: 12px;
                        padding-left: 40px;
                    }

                    .checkbox-inline {
                        input {
                            margin-bottom: 3px;
                            margin-right: 10px;
                        }
                    }
                }
            }
            .gallery-link-container {
                width: 100%;
                text-align: center;
                margin-top: 8%;
                .download-gallery-link {
                    background-color: #f7fbf8;
                    color: #2c8c48;
                    border: 1px solid #91c39f;
                    cursor: pointer;
                    padding: 15px 10px;
                    font-size: 16px;
                    width: 300px;
                    height: 45px;
                    align-items: center;
                    justify-content: center;
                    margin-top: 10px;
                    border-radius: 6px;
                }
            }
        }
        .memory-list-wrapper {
            margin-top: 40px;
            .memory-card {
                word-break: break-word !important;
                width: 100%;
                background: $white;
                box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
                border-radius: 8px;
                margin-bottom: 24px;
                
                .memory-card-header {
                    padding: 15px 15px;
                    position: relative;
                    background-color: #EAF2EB;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .memory-title {
                        font-family: "Lato";
                        font-style: normal;
                        font-weight: 600;
                        font-size: 19px;
                        line-height: 27px;
                        color: $black;
                        padding-top: 0;
                        margin-bottom: 0;
                        padding-left: 8px;
                    }
                    .memory-icon {
                        position: absolute;
                        top: 15px;
                        right: 20px;
                        img {
                            width: 24px;
                            height: 20px;
                        }
                    }
                    .memory-icon:hover div.title {
                        display: inline;
                    }
                    .title {
                        display: none;
                        position: absolute;
                        color: white;
                        background: #393939;
                        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
                        border-radius: 4px;
                        padding: 2px 4px;
                        margin-top: 28px;
                    }
                    .memory-date {
                        font-family: "Lato";
                        font-style: italic;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 27px;
                        color: $black;
                        opacity: 0.5;
                    }
                }
                .memory-content {
                    padding: 20px;
                    padding-bottom: 0px !important;
                    .noMedia {
                        text-align: center;
                        padding: 10%;
                        @media (max-width:650px) {
                            font-size: 15px;
                         }
                      }
                    .imagesGallery {
                        .displayLoader {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                          }
                          .videoSectionTitle {
                            padding-bottom: 10px;
                            @media (max-width:650px) {
                               font-size: 15px;
                            }
                          }
                        .obituaryImages {
                            .maskLayerObituary{
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                background: rgba(0, 0, 0, 0.1);
                                color: white;
                                font-size: 1.5rem;
                                font-weight: bold;
                                pointer-events: none;
                                .single-digit,
                                    .double-digit {
                                        font-size: 30px;
                                        color: white;
                                        font-weight: 600;
                                        border-radius: 50%;
                                        background-color: #7895B2;
                                        opacity: 0.6;
                                    }
                                    .double-digit {
                                        padding: 10px;
                                        padding-top: 15px;
                                        height: 80px;
                                        width: 80px;
                                    }
                                    .single-digit {
                                        padding: 10px;
                                        height: 65px;
                                        width: 65px;
                                    }
                            }
                        }
                    }
                    .gallery-documents-wrapper {
                        .documentsHeading {
                            font-weight: 600;
                            font-size: 18px;
                            padding:0px 0px 15px 5px;
                            margin-bottom: 0px;
                        }
                    }
                    .galleryDocuments {
                        padding: 0px 20px 0px 20px;
                        .fileContainer {
                            .fileName {
                                padding-left: 5px;
                            }
                        }
                    }
                    .obituaryDocuments {
                        .fileContainer {
                            font-weight: 600;
                        }
                    }
                    .pdfViewer {
                        p {
                            margin-bottom: 0px !important;
                        }
                        .docxFile {
                            cursor: pointer;
                        }
                        .fileContainer {
                            padding: 15px 0px 5px 0px;
                            margin-bottom: 10px;
                            align-items: center;
                            word-wrap: break-word;
                            word-break: break-all;
                            .fileNameContainer {
                                background-color: #F4F4F4;
                            }
                            .dropdown {
                                .btn-secondary {
                                    color: unset !important;
                                    background-color: unset !important;
                                    border-color: unset !important;
                                    border: none !important;
                                }
                                .dropdown-item {
                                    border-bottom: 1px solid rgba(151, 151, 151, 0.3);
                                    padding: 0.5rem 1rem;
                                    font-weight: 500;
                                    background: transparent;
                                }
                                .toggleButton:hover {
                                    border: none !important;
                                    background-color: none !important;
                                    box-shadow: none !important;
                                  }
                                  .toggleButton:focus {
                                    border: none !important;
                                    box-shadow: none !important;
                                  }
                                  .downloadLink {
                                    color: black;
                                  }
                            }
                           .dropdown-menu {
                            & > button {
                                &:last-child {
                                  border-bottom: none;
                                }
                              }
                           }
                           .displayPdfAsImagesContainer {
                            position: relative;
                            padding-top: 10px;
                            .arrowButton {
                                position: absolute;
                                top: 50%;
                                transform: translate(-50%);
                                display: flex;
                                width: 40px;
                                height: 40px;
                                background: #fff;
                                cursor: pointer;
                            }
                            .next-button {
                                right: -37px;
                            }
                           }
                        }
                    }
                    .Item {
                        position: relative;
                        .rotateImageModal {
                            .mobile-showSaveButton {
                                background-color: grey;
                                opacity: 0.4;
                                position: absolute;
                                z-index: 1;
                                top: 0px;
                                right: 0px;
                                width: 500px;
                                min-height: 400px;
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }
                        .main-iconsContainer {
                            display: flex;
                            justify-content: center;
                        }
                        .single-iconsContainer {
                            display: flex;
                            justify-content: end;
                        }
                        .iconsContainer {
                            display: flex;
                        }
                        .rotateDeleteButtons{
                            display: flex;
                            position: absolute;
                            right: 0px;
                        }
                        .mobile-iocnsContainer  {
                            display: flex;
                            position: absolute;
                            z-index: 1;
                            right: 0px;
                        }
                        .iconsContainer-rotation {
                            width: 100%;
                            display: flex;
                            position: absolute;
                            z-index: 1;
                        }
                        .editorEnabled-Index {
                           position: absolute;
                           z-index: 1;
                           left:50%;
                           transform: translate(-50%, -50%);
                           text-align: center;
                           font-weight: bold;
                           font-size: 12px;
                           margin-top: 16px;
                           
                        }
                        .Index {
                            text-align: center;
                            font-weight: bold;
                            font-size: 12px;
                            margin-top: 6px;
                            margin-left: 50%;
                            margin-right: auto;
                        }
                        ul {
                            padding-top: 5px ;
                            margin: 0 ;
                            list-style: none ;
                            display: grid ;
                            grid-template-columns: 1fr 1fr ;
                            justify-items: center ;
                        }
                        li:nth-child(1) {
                            text-align:center ;
                             margin-left: 50% ;
                             margin-right: auto;
                        }
                        li:nth-child(2) {
                        margin-left: auto !important;
                        }
                        .delete-img-button{
                            border:none;
                            border-radius:4px;
                            color:#fff;
                            font-size:14px;
                            display:flex;
                            justify-content: center;
                            align-items: center;
                            background-color:orange;
                            cursor:pointer;
                            padding-right: 5px;
                        }
                   }
                    .memory-content-poc {
                        padding-bottom: 15px;
                        .media-container-poc {
                            background-color: white;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                        .grid-layout:nth-child(1){
                            padding-right: 2px !important;
                            padding-left: 0px !important;
                        }
                        .grid-layout:nth-child(2){
                            padding-left: 2px !important;
                            padding-right: 2px !important;
                        }
                        .grid-layout:nth-last-child(1) {
                            padding-left: 2px !important;
                            padding-right: 0px !important;
                        }
                        .single-grid-image{
                            display: grid;
                            grid-template-rows:minmax(200px,450px);
                        }
                        .single-grid {
                            width: 500px;
                            display: grid;
                            grid-template-rows:minmax(100px,350px);
                        }
                        .dual-grid {
                            display: grid;
                            grid-template-columns: 1fr 1fr;
                            width:900px;
                            grid-auto-rows: minmax(400px,max-content); /* OR min-content*/
                        }
                        .multi-grid {
                            display: grid;
                            grid-template-columns: 1fr 1fr 1fr;
                            grid-auto-rows: max-content; /* OR min-content*/
                        }
                        .grid-item {
                            cursor: pointer;
                        }
                        .grid-item-last {
                            position: relative;
                            padding-right:0px;
                           background-color: '#c1c1ca';
                            height: 100%;
                            width: 100%;
                            display: 'flex';
                            align-items: 'center';
                            justify-content: 'center';
                                .grid-show-more {
                                    position: absolute;
                                    height: 100%;
                                    width: 100%;
                                }
                                .mask-layer {
                                    position: absolute;
                                    z-index: 8;
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                   // background-color: rgba(0, 0, 0, 0.4);
                                    pointer-events: none;
                                        .single-digit,
                                        .double-digit {
                                            font-size: 30px;
                                            color: white;
                                            font-weight: 600;
                                            border-radius: 50%;
                                            background-color: #7895B2;
                                            opacity: 0.6;
                                        }
                                        .double-digit {
                                            padding: 10px;
                                            padding-top: 15px;
                                            height: 80px;
                                            width :80px;
                                        }
                                        .single-digit {
                                            padding: 10px;
                                            height: 65px;
                                            width :65px;
                                        }
                                    }
                        }
                        .image-file-div-poc {
                            height: 100%;
                            .image-file-poc {
                                width: 100%;
                                height: 100%;
                            }
                        }
                     
                        .video-file-div-poc {
                            width: 100%;
                            height: 100%;
                            align-items: center;
                            display: flex;
                            .video-file {
                                width: 100%;
                                height: 100%;
                                pointer-events: none;
                                iframe {
                                    height: 100%;
                                    width: 100%;
                                }
                            }
                        }
                        .audio-file-div-poc {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            height: 100%;
                            background: rgba(175, 175, 175, 0.5);
                            .audio-file {
                                pointer-events: none;
                            }
                        }
                    .gallery-image-div-color-poc {
                       background-color: #F1F3F4 !important;
                        cursor: pointer !important;
                    }
                    .obituaryImages-mobileView {

                        .normal-dot {
                            cursor: pointer;
                            height: 10px;
                            width: 10px;
                            margin: 0 2px;
                            background-color: #bbb;
                            border-radius: 50%;
                            display: inline-block;
                            transition: background-color 0.6s ease;
                        }
                        .active-dot
                         {
                            cursor: pointer;
                            height: 10px;
                            width: 10px;
                            margin: 0 2px;
                            background-color: rgb(5, 60, 79);
                            border-radius: 50%;
                            display: inline-block;
                            transition: background-color 0.6s ease;
                        }
                        .carousel.slide {
                            .carousel-indicators {
                                display: none;
                            }                   
                           
                            .carousel-inner {
                                display: flex;
                                align-items: center;
                                min-height: 500px;
                                max-height: 100%;
                                position: relative;
                                box-shadow: 0px 7.52257px 22.5677px rgba(0, 0, 0, 0.15);
                                border-radius: 3.00903px;
                            }
                            .carousel-item {
                                 background-color: #F1F3F4;
                            }
                           
                            .carousel-control-prev,
                            .carousel-control-next {
                                width: 30px;
                                height: 30px;
                                opacity: 1;
                                bottom: auto;
                                top: calc(50% - 20px);
                                background-color: $white;
                                border-radius: 50%;
                                span:not(.sr-only) {
                                    display: none;
                                }
                                &:before {
                                    content: "";
                                    width: 10px;
                                    height: 10px;
                                }
                            }
                            .carousel-control-prev {
                                &:before {
                                    background: url(/assets/images/arrow-left.svg) no-repeat
                                        center;
                                    background-size: contain;
                                }
                            }
                            .carousel-control-next{
                                &:before {
                                    background: url(/assets/images/arrow-right.svg)
                                        no-repeat center;
                                    background-size: contain;
                                }
                            }
                        }
                        .disableIconButton {
                            border: none;
                            cursor: not-allowed;
                        }
                        .iconsButton {
                            border: none;
                            cursor: pointer;
                        }
                        .downloadIcon {
                            margin: 4px 7px 0 5px;
                            height: 20px;
                            width: 20px;
                        }
                        .deleteIcon {
                            margin-top: 1px;
                            margin-bottom: 2px;
                            margin-right: 5px;
                            height: 20px;
                            width: 20px;
                        }
                        .rotateIconMobile {
                            height: 22px;
                            width: 22px;
                            margin: 0 2px 0 5px;
                        }
                    }
                    }
                 
                    .memory-content-text {
                        margin-bottom: 20px;
                        word-break: break-word !important;
                        color: rgb(106, 102, 102);
                    }

                    .media-container {
                        .contain-img img {
                            object-fit: contain !important;
                        }
                        /*  box-shadow: -2px 2px 2px 0 rgba(0, 0, 0, 0.07); */
                        .single-container {
                            width: 100%;
                            // .video-file-div,
                            .image-file-div {
                                height: 100%;
                                //   padding: 1rem;
                                // .video-file,
                                iframe,
                                canvas,
                                .image-file {
                                    height: 100%;
                                    pointer-events: none;
                                    width: 100%;
                                }
                            }
                            .large-view {
                                padding: 0;
                                height: 300px;
                                padding: 0.2rem;
                                .video-file-div {
                                    width: 100%;
                                    height: 100%;
                                    align-items: center;
                                    display: flex;
                                    .video-file {
                                        width: 100%;
                                        height: 100%;
                                        pointer-events: none;
                                        iframe {
                                            height: 100%;
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                        .dual-container {
                            min-height: 300px;
                            background: rgba(175, 175, 175, 0.5);
                            .view {
                                padding: 0 0.2rem 0 0;
                                min-height: 100%;
                                .video-file-div,
                                .image-file-div {
                                    width: 100%;
                                    height: 100%;
                                  /*  background: rgba(175, 175, 175, 0.5); */
                                    .video-file,
                                    .image-file,
                                    iframe {
                                        width: 100%;
                                        height: 100%;
                                        pointer-events: none;
                                    }
                                }
                            }
                            .view-mobile {
                                margin-top: 0;
                            }
                        }
                        .three-container {
                            height: 350px;
                            width: 100%;
                            background: rgba(175, 175, 175, 0.5);
                            .large-view {
                                padding: 0;
                                height: 100%;
                                padding: 0.2rem;
                                .image-file-div {
                                   /* background: rgba(175, 175, 175, 0.5);*/
                                    padding: 0.2rem;
                                    width: 100%;
                                    height: 100%;
                                    align-items: center;
                                    display: flex;
                                    justify-content: center;
                                    .image-file {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: contain;
                                    }
                                }
                                .video-file-div {
                                    width: 100%;
                                    height: 100%;
                                    align-items: center;
                                    display: flex;
                                    .video-file,
                                    iframe,
                                    canvas {
                                        width: 100%;
                                        height: 100%;
                                        pointer-events: none;
                                    }
                                }
                            }
                            .small-view {
                                padding: 0;
                                height: 100%;
                                .small-media-div {
                                    padding: 0.2rem;
                                    width: 100%;
                                    height: 50%;
                                    .video-file-div {
                                        width: 100%;
                                        height: 100%;
                                        align-items: center;
                                        display: flex;
                                        .video-file,
                                        iframe,
                                        canvas,
                                        div {
                                            width: 100%;
                                            height: 100%;
                                            pointer-events: none;
                                        }
                                    }
                                    .image-file-div {
                                        width: 100%;
                                        height: 100%;
                                        align-items: center;
                                        display: flex;
                                        /*background: rgba(175, 175, 175, 0.5);*/
                                        .image-file {
                                            width: 100%;
                                            height:100%;
                                            max-height: 100%;
                                        }
                                    }
                                }
                            }
                        }
                        .multi-container {
                            height: 400px;
                            width: 100%;
                            background: rgba(175, 175, 175, 0.5);
                            .large-view {
                                padding: 0;
                                height: 100%;
                                .image-file-div {
                                    padding: 0.2rem;
                                    width: 100%;
                                    height: 100%;
                                    align-items: center;
                                    display: flex;
                                   /* background: rgba(175, 175, 175, 0.5);*/
                                    .image-file {
                                        width: 100%;
                                        height: 100%;
                                        max-height: 100%;
                                    }
                                }
                                .video-file-div {
                                    padding: 0.2rem;
                                    width: 100%;
                                    height: 100%;
                                    align-items: center;
                                    display: flex;
                                    .video-file,
                                    iframe,
                                    canvas {
                                        width: 100%;
                                        height: 100%;
                                        pointer-events: none;
                                    }
                                }
                            }
                            .small-view {
                                padding: 0;
                                height: 100%;
                                .small-media-div {
                                    width: 100%;
                                    height: 33%;
                                    padding: 0.2rem;
                                    .video-file-div {
                                        width: 100%;
                                        height: 100%;
                                        align-items: center;
                                        display: flex;
                                        .video-file,
                                        iframe,
                                        canvas,
                                        div {
                                            height: inherit;
                                            width: inherit;
                                            pointer-events: none;
                                        }
                                    }
                                    .image-file-div {
                                        width: 100%;
                                        height: 100%;
                                        align-items: center;
                                        display: flex;
                                       /* background: rgba(175, 175, 175, 0.5);*/
                                        .image-file {
                                            width: 100%;
                                            height: 100%;
                                            max-height: 100%;
                                        }
                                    }
                                }
                                .show-more-div {
                                    height: 34%;
                                    width: 100%;
                                    position: relative;
                                    .media-div {
                                        position: absolute;
                                        height: 100%;
                                    }

                                    .mask-layer {
                                        position: absolute;
                                        z-index: 8;
                                        width: 100%;
                                        height: 100%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        background-color: rgba(0, 0, 0, 0.4);
                                        pointer-events: none;
                                        span {
                                            font-size: 28px;
                                            color: white;
                                            font-weight: 600;
                                        }
                                    }
                                }
                            }
                        }
                        .error-div {
                            background-color: lightgray;
                            border: 5px solid gray;
                            padding: 15px;
                        }
                        .audio-file-div {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            height: 100%;
                            background: rgba(175, 175, 175, 0.5);
                            .audio-file {
                                pointer-events: none;
                            }
                        }
                    }
                    .row {
                        margin-bottom: 15px;
                        margin-right: 0;
                        margin-left: 0;
                    }
                }
                .btn-edit-icon {
                    background: transparent;
                    border: none;
                    padding: 0;
                    order: none;
                    border-radius: 50%;
                    width: 30px;
                    height: 30px;
                    color: #2e7d32;
                    margin-left: 0.5rem;
            
                    &:hover {
                        color: white;
                        background-color: #2e7d32;
                    }
                }
                .add-img-button{
                    display:flex;
                    justify-content: center;
                    align-items: center;
                    color:#fff;
                    background-color: #298a45;
                    border-radius: 4px;
                    border:none;
                    cursor:pointer;
                    padding:5px 10px;
                    margin-left:20px;
                    &:disabled{
                        cursor:not-allowed;;
                    }
                }
                .memory-card-footer {
                    @extend .d-flex;
                    @extend .justify-content-start;
                    @extend .flex-column;
                    padding: 5px 20px 20px;

                    .memory-footer {
                        display: flex;
                        justify-content: space-between;
                        .memoryByPerson-contaier {
                            display:'flex';
                            flex-direction: column;
                            opacity: 0.5;
                            .details {
                                display: flex;
                                flex-direction: row;
                                flex-wrap: wrap;
                            }
                        }
                    }

                    .memory-postedBy {
                        font-family: "Lato";
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 27px;
                        color: $black;
                        opacity: 0.5;
                        padding-top: 5px;
                    }
                    span {
                        font-family: "Lato";
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 27px;
                        color: $black;
                    }
                    .hr-line {
                        border: 1px solid #000000;
                        opacity: 0.1;
                        margin: 20px 0px;
                    }
                    .button-container {
                        display: flex;
                        justify-content: end;
                        align-items: center;
                        margin: 20px 0px;
                        .footer-button {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 6px;
                            border: none;
                            padding: 5px;
                            font-size: 16px;
                            font-family: "Lato";
                            width: 90px;
                            margin-left: 15px;
                            cursor: pointer;
                        }
                        .reject-button {
                            color: #ff0000;
                            background-color: rgba(255, 0, 0, 0.1);
                            border: 1px solid #ff0000;
                        }
                        :disabled {
                            cursor: no-drop;
                            border: none;
                            background-color: #f0f0f0;
                            color: #000;
                        }
                        .approve-button {
                            color: #298a45;
                            border: 1px solid #298a45;
                            background-color: #f7fbf8;
                        }
                        .unApprove-button {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 6px;
                            border: none;
                            padding: 5px;
                            font-size: 16px;
                            font-family: "Lato";
                            width: 100px;
                            cursor: pointer;
                        }
                    }
                }
            }
            .gallery-card {
                box-shadow: none;
                .memory-content {
                    padding: 0;
                }
                .large-view {
                    padding-right: 0px !important;
                     padding-bottom: 0px !important;
                }
                .gallery-image-div-color {
                    background-color: #222224 !important;
                }
            }
        }
    }
    
    .memories-right-content-wrapper {
        width: 270px;
        min-height: 672px;
        padding: 24px 30px;
        background: $white;
        box-shadow: 2px 0px 8px $shadow-color;
        h4 {
            font-family: Lato;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            color: #000000;
            margin-bottom: 17px;
        }
        p {
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 27px;
            color: rgba(0, 0, 0, 0.6);
            margin-bottom: 0;
        }
        .events-details-wrapper {
            font-family: Lato;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 27px;
            color: #2d9cdb;
            display: block;
            padding-bottom: 24px;
        }
        .filters-wrapper {
            border-top: 0.5px solid rgba(0, 0, 0, 0.3);
            h6 {
                font-family: Lato;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 21px;
                color: #2e2e2e;
                opacity: 0.5;
                margin-bottom: 0;
                padding: 24px 0 16px;
            }
            ul {
                list-style: none;
                li {
                    font-family: Lato;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 27px;
                    color: #2e2e2e;
                }
            }
        }
    }
}
.scrollDiv {
    padding-bottom: 10px;
    .scroolButton {
        border: none;
        background-color: #337a2e;
        color: white;
        font-size: 18px;
    }
    .scroolUp {
        padding-right: 5px;
    }
}


.uploadMediaModal {
    width: 60% ;
    height: 90%;
    max-height: 100%;
    overflow-y: auto;
    max-width: 100% !important;
    .modal-title {
        font-weight: bold;
        font-size: 18px;
    }
    .shareMedia {
        display: flex;
        justify-content: flex-end;
        margin-right: 31px;
        margin-bottom: 15px;
        .share-button {
            letter-spacing: 0.2px;
            font-family: "Lato";
            font-style: normal;
            font-size: 16px;
            width: 100px;
            background-color: #337a2e;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            border-radius: 6px;
            border: 1px solid;
            &:disabled,
            &.disabled {
                background-color: #60cf80;
                cursor: not-allowed;
            }
        
        }
    }
    .memory-gallery {
        padding-left: 20px;
    }
}

.documentViewerModal {
    width: 60% !important;
    height: 90%;
    max-height: 100%;
    max-width: 100% !important;
    .displayLoader {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 575px) {
    .memories-wrapper-family {
        display: block;
        margin: 5px;

        .memories-left-content-wrapper-family {
            width: 100%;
            padding: 0;
            margin-bottom: 25px;
            display:flex;
            justify-content:center;
            .dashboards-card {
                position: static;
                margin-top: 20px;
            }
        }

        .memories-main-content-wrapper-family {
            margin-left: 0px !important;
            width: 100%;
            .memories-mobile-tab{
                flex-direction: column;
                background-color: #fff;
                padding: 20px;
                border-radius: 10px;
                box-shadow: 2px 4px 3px 3px $shadow-color;
            }
            .memories-menu-heading {
                font-size: 22px;
            }
            .memories-tab{
                .family-website-nav-tabs {
                    flex-wrap:wrap;
                    justify-content: center; 
                    .nav-tab-button{
                        font-size:16px;
                        min-width: 90px;
                        //margin-right:10px;
                    }
                    .share-memory-button {
                        width: 155px;
                    }
                    .uploadMediaWrapper {
                        .share-memory-button {
                            margin-right: 0px;
                        }
                    }
                    
                }
                &.justify-end {
                    justify-content: center;
                }
            }
            .memories-tab-responsive {
                align-items: center !important;
            }
            .memory-list-wrapper {
                .memory-card {
                    .memory-card-header {
                        flex-direction: column;
                        align-items:start;
                        padding: 15px 20px;
                        .memory-title {
                            font-size: 16px;
                            line-height: 18px;
                        }
                    }
                    .approve-memory-header {
                        flex-direction: row;
                        justify-content: space-between;
                    }
                }
            }
            .share-memory-button-wrapper{
                width:100%;
                display:flex;
                align-items: center;
                justify-content: start;
            }
            .memory-card-footer {
                .memory-footer {
                    flex-direction: column;
                }
            }
            .share-div-mobile {
                flex-direction: column;
            }
        }
    }
    .documentViewerModal {
        width: 90% !important;
    }
    .displayPdfAsImagesContainer {
        .arrowButton {
            width: 22px !important;
            height: 22px !important;
        }
        .next-button {
            right: -25px !important;
        }
    }
}
@media (min-width: 250px) and (max-width: 480px) {
    .memories-wrapper-family {
        .memories-main-content-wrapper-family {
            .memories-tab {
                .family-website-nav-tabs {
                    flex-direction: column;
                    justify-content: center;
                    align-items:center;
                    .nav-tab-button {
                        min-width: 155px;
                        margin-right:0;
                    }
                }
            }
        }
    }
}
@media (max-width: 1024px) and (min-width:576px){
    .memories-wrapper-family {
        display: block;
        margin: 5px;

        .memories-left-content-wrapper-family {
            width: 100%;
            padding: 0;
            margin-bottom: 25px;
            .dashboards-card {
                position: static;
                margin-top: 20px;
            }
        }

        .memories-main-content-wrapper-family {
            width: 100%;
        }
        .displayPdfAsImagesContainer {
            .arrowButton {
                width: 30px !important;
                height: 30px !important;
            }
        }
    }
}
@media (min-width:576px) {
    .memories-tab {
        margin-bottom: 18px;
        padding-bottom: 12px;
        position: sticky;
        top:0px;
        z-index: 100;
        margin-bottom: 30px;
    }
}
.slidecontainer {
    width: 270px;
    padding:15px 0px;
    .symbol-size {
        font-size: 20px;
        cursor: pointer;
    }
}
.slider {
    -webkit-appearance: auto;
    width: 240px;
    height: 1px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
}
.slider:hover {
    opacity: 1;
}
.avatar-buttons-wrapper {
    width: 270px;
    .btn-theme {
        padding: 5px 5px;
        font-size: 16px;
        min-width: 100px;
        min-height: 35px;
    }
}

.modal-open {
    .modal {
        .modal-dialog {
            &.images-preview-modal {
                max-width: 90%;
                .modal-content {
                    .modal-body {
                        position: relative;
                        padding: 15px 30px 15px; 
                        .col-next,
                        .col-prev {
                            height: 90%;
                            position: absolute;
                            width: 60px;
                            text-align: center;
                            display: flex;
                            vertical-align: middle;
                            top: 0px;
                        }
                        .col-next {
                            right: 0;
                        }
                        .col-prev {
                            left: 0;
                        }
                        .modal-next {
                            position: static;
                            border: none;
                            background: $white;
                            font-size: 60px;
                            font-weight: 700;
                        }
                        .modal-prev {
                            position: static;
                            border: none;
                            background: $white;
                            font-size: 60px;
                            font-weight: 700;
                        }
                        .video-file {
                            width: 720px;
                            height: 442.5px;
                        }
                        .image-file {
                            width: 1150px;
                            height: 600px;
                            object-fit: contain;
                        }
                        .audio-file-div {
                            width: 708px;
                            height: 442.5px;
                            display: flex;
                            align-items: center;
                            .audio-file {
                                width: 100%;
                            }
                        }
                        iframe {
                            width: 708px;
                            height: 442.5px;
                        }
                        .image-file-div  {
                            .previous-button,.next-button {
                                position: absolute;
                                z-index: 2;
                                cursor: pointer;
                                width: 40px;
                                height: 40px;
                                background: $white;
                                top:300px;
                            }
                            .previous-button {
                                left:10px;
                            }
                            .next-button {
                                right: 10px;
                            }
                        }
                        .gallery-videos  .video-file {
                            width:1160px;
                            height: 700px;
                            max-width: 100%;
                            max-height: 100%;
                            position: relative;
                            object-fit: contain;
                        }
                        .mp4Video {
                            width: auto !important;
                        }
                        .gallery-videos {
                            iframe {
                                width: 1150px;
                                height: 700px;
                                max-width: 100%;
                                max-height: 100%;
                            }
                            .close-button {
                                width: 50px;
                                height: 50px;
                                z-index: 1;
                                position: absolute;
                                //top:11px;
                                right: 10px;
                                cursor: pointer;
                                object-fit: contain;
                            }
                            .previous-button,.next-button {
                                position: absolute;
                                z-index: 1;
                                cursor: pointer;
                                background: $white;
                                width: 40px;
                                height: 40px;
                                top: 300px;
                            }
                            .previous-button {
                                left: 10px;
                            }
                            .next-button {
                                right: 10px;
                            }
                        }
                        .image-file-div .image-file {
                            width: 2000px;
                            height: 700px;
                            max-width: 100%;
                            max-height: 100%;
                            position: relative;                 
                        }
                        .image-file-div .view-full-screen {
                            height: 40px;
                            width: 40px;
                            z-index: 1;
                            position: absolute;
                            right: 10px;
                            bottom: 0;
                            margin-bottom: 10px;
                        }
                        .image-file-div .delete-image {
                            z-index: 1;
                            position: absolute;
                            right: 0px;
                            bottom: -30px;
                            margin: 0px !important;
                        }
                        .image-file-div .close-button {
                            width: 40px;
                            height: 40px;
                            z-index: 1;
                            position: absolute;
                            //top:5px;
                            top: 0px;
                            right: 10px;
                            object-fit: contain;
                            cursor: pointer;
                        }
                        .image-file-div .showSaveButton {
                            background-color: grey;
                            opacity: 0.4;
                            position: absolute;
                            z-index: 1;
                            top: 0px;
                            right: 0px;
                            width: 2000px;
                            height: 700px;
                            max-width: 100%;
                            max-height: 100%;
                        }
                        .gallery-audio-file-div {
                            width:1160px;
                            height: 700px;
                            max-width: 100%;
                            max-height: 100%;
                            position: relative;
                        }
                        .audio-file-div {
                            .gallery-audios  .audio-file {
                                //width: 100%;
                                position: relative;
                            }
                            .gallery-audios{
                                width: 100%;
                                padding-left: 80px;
                                padding-right: 80px;
                                .close-button {
                                    width: 40px;
                                    height: 40px;
                                    z-index: 1;
                                    position: absolute;
                                    top:5px;
                                    right: 0px;
                                    cursor: pointer;
                                    object-fit: contain;
                                  }
                                .previous-button,.next-button {
                                    position: absolute;
                                    z-index: 1;
                                    cursor: pointer;
                                    background: $white;
                                    width: 40px;
                                    height: 40px;
                                    //top  :300px;
                                  }
                                  .previous-button {
                                    left: 0px;
                                  }
                                  .next-button {
                                    right: 0px;
                                  }
                            }
                        }
                        .dot {
                            cursor: pointer;
                            height: 10px;
                            width: 10px;
                            margin: 0 2px;
                            background-color: #bbb;
                            border-radius: 50%;
                            display: inline-block;
                            transition: background-color 0.6s ease;
                        }
                        .active,
                        .dot:hover {
                            cursor: pointer;
                            height: 10px;
                            width: 10px;
                            margin: 0 2px;
                            background-color: lightblue;
                            border-radius: 50%;
                            display: inline-block;
                            transition: background-color 0.6s ease;
                        }
                        .fullscreen{
                            display:flex;
                            justify-content: end;
                            align-items: end;
                        }
                        .fullscreen-button{
                            color: #fff;
                            cursor: pointer;
                            border: 0;
                            padding: 15px 10px;
                            line-height: 1.43;
                            letter-spacing: 0.2px;
                            font-family: "Lato";
                            font-style: normal;
                            font-size: 14px;
                            padding-left: 10px;
                            width: 140px;
                            background-color: #337a2e;
                            height: 45px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-top: 10px;
                            border-radius: 6px;
                        }
                    }
                    .modal-footer {
                        border: 0;
                        padding: 20px 30px;
                    }
                }
                .delete-img-button,.edit-img-button{
                    border:none;
                    border-radius:4px;
                    color:#fff;
                    font-size:14px;
                    display:flex;
                    justify-content: center;
                    align-items: center;
                    background-color:orange;
                    cursor:pointer;
                    padding:5px 10px;
                }
                .rotateImage {
                    background-color:green !important;
                    margin-left: 5px;
                }
                .image-file-div {
                    
                    // .common-props {
                    //     width: 40px;
                    //     height: 40px;
                    //     background-color: white;
                    //     border-radius: 50%;
                    //     z-index: 1;
                    //     position: absolute;
                    //     object-fit: contain;
                    //     cursor: pointer;
                    // }
                    // .rotate-button {
                    //     top:50px;
                    //     right: 10px;
                    // }
                    // .download-button {
                    //     top:100px;
                    //     right: 10px;
                    // }
                    // .delete-button {
                    //     top:150px;
                    //     right: 10px;
                    // }
                }
                .IconsContainer {
                    display: flex;
                    margin-top: 16px;
                    .Index {
                        text-align: center;
                        font-weight: bold;
                        font-size: 18px;
                        margin-left: 49%;
                        margin-top: 9px;
                        margin-right: auto;
                    }
                    .ListOfIcons {
                        margin-top: 3px;
                    }
                    .download-button {
                        margin-top: -3px;
                    }
                    .delete-button {
                        margin-top: 3px;
                    }
                    .disable-button {
                        cursor: not-allowed;
                        border: none;;
                        background-color: white;
                        margin-left: 10px !important;
                    }
                    .delete-button,.rotate-button,.download-button{
                        height: 30px;
                        width: 30px;
                        object-fit: contain;

                    }
                    .disable-button {
                        cursor: not-allowed;
                        border: none;;
                        background-color: white;
                        margin-left: 10px !important;
                    }
                    .common-props {
                        border: none;;
                        background-color: white;
                        margin-left: 10px !important;
                        object-fit: contain;
                        cursor: pointer;
                    }
                }
                .gallery-videos {
                    .delete-button {
                        border-radius: 50%;
                        background-color: white;
                        width: 50px;
                        height: 50px;
                        z-index: 1;
                        position: absolute;
                        top:70px;
                        right: 10px;
                        object-fit: contain;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
.hasFocus {
    /* border: 0.5px solid #2d9cdb; */
}
.noFocus {
    border: #f1f1f1;
}
.errorFocus {
    border: 0.5px solid red;
}

.life-of-content-title {
    font-family: Inter;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
    .options {
        padding: 0;
        width: 25px;
        .selection {
            border: 0.5px solid #2d9cdb;
        }
    }
    .container-root {
        height: 211px;
        background: #fcfcfc;

        box-sizing: border-box;
        box-shadow: 2px 0px 7px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
    }
    a {
        cursor: pointer;
        // .BoldPic-wrapper {
        //     top: 30px;
        //     position: absolute;
        //     right: 6px;
        // }
        // .ItalicPic-wrapper {
        //     top: 60px;
        //     position: absolute;
        //     right: 6px;
        // }
        // .UPic-wrapper {
        //     top: 90px;
        //     position: absolute;
        //     right: 6px;
        // }
    }
}
.errorMessage {
    color: red;
    font-size: small;
}

.menu-modal {
    ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        li {
            padding-top: 20px;
            .options-wrapper {
                padding-left: 50px;
                position: relative;
                color: $theme-text-color;
                font-family: "Lato";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                cursor: pointer;
                line-height: 18px;
                .icon-wrapper {
                    position: absolute;
                    top: 0;
                    left: 20px;
                    height: 19px;
                }
                &.long-text-wrapper {
                    display: inline-flex;
                }
                .MuiSwitch-switchBase
                    .MuiFormControlLabel-label.MuiTypography-root {
                    font-weight: 600;
                }
            }

            &.empty-li {
                height: 200px;
            }
        }
    }
    .bck-family-btn {
        width: 100%;
        height: 43px;
        background: $theme-btn-bg;
        border-radius: 8px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500 !important;
        font-size: 14px;
        line-height: 16px;
    }
}

@media (max-width: 1290px) and (min-width: 997px) {
    .memories-wrapper {
        .memories-left-content-wrapper {
            width: 24%;
        }
        .memories-right-content-wrapper {
            width: 20%;
        }
        .memories-main-content-wrapper {
            max-width: 100%;
            width: 76%;
        }
    }
}

@media (max-width: 996px) and (min-width: 768px) {
    .memories-wrapper {
        .memories-left-content-wrapper {
            width: 24%;
        }
        .memories-right-content-wrapper {
            display: none;
        }
        .memories-main-content-wrapper {
            padding: 30px 45px;
            float: none;
            width: 76%;
            max-width: 100%;
            h2 {
                font-size: 22px;
                line-height: 26px;
            }
            .mobile-data-wrapper {
                background: $white;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
                padding: 20px;
                margin: 0 -45px;
                margin-bottom: 20px;
                display: block;
            }
            .memory-list-wrapper {
                .memory-card {
                    .memory-content {
                        .media-container {
                            .multi-container {
                                .small-view {
                                    .show-more-div {
                                        .mask-layer {
                                            span {
                                                font-size: 24px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) and (min-width: 320px) {
    .memories-wrapper {
        .memories-left-content-wrapper {
            width: 24%;
        }
        .memories-right-content-wrapper {
            display: none;
        }
        .memories-main-content-wrapper {
            padding: 0 15px 30px;
            float: none;
            width: 76%;
            max-width: 100%;
            h2 {
                font-size: 16px;
                line-height: 18px;
            }
            .memory-list-wrapper {
                .memory-card {
                    width: 100%;
                    .memory-card-header {
                        .memory-title {
                            font-size: 16px;
                            line-height: 18px;
                        }
                        .memory-icon {
                            img {
                                width: 20px;
                                height: 18px;
                            }
                        }
                        .memory-date {
                            font-size: 14px;
                            line-height: 16px;
                        }
                    }
                    .memory-content {
                        .media-container {
                            .dual-container {
                                .view-mobile {
                                    margin-top: 10px;
                                }
                            }
                            .multi-container {
                                .small-view {
                                    .show-more-div {
                                        .mask-layer {
                                            span {
                                                font-size: 16px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .memory-card-footer {
                        span {
                            font-size: 16px;
                            line-height: 18px;
                        }
                        .memory-postedBy {
                            font-size: 14px;
                            line-height: 16px;
                        }
                    }
                }
            }
            .story-wrapper {
                padding: 16px;
                min-height: auto;
                .profile-wrapper {
                    display: block;
                    .profile-picture-wrapper {
                        width: 100%;
                        img {
                            width: 75px;
                            height: 75px;
                            margin: 0 auto;
                            display: block;
                        }
                    }
                    .profile-thought-wrapper {
                        padding: 0;
                        margin: 0 auto;
                        span {
                            font-size: 12px;
                            line-height: 12px;
                        }
                    }
                }
                a {
                    font-size: 14px;
                    line-height: 16px;
                }
            }
            .audio-wrapper {
                .audio-file {
                    width: 100%;
                }
            }
            .mobile-data-wrapper {
                background: $white;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
                padding: 20px;
                margin: 0 -15px;
                margin-bottom: 20px;
                display: block;
                .filter-options {
                    display: block;
                    color: #000000 !important;
                    font-family: "Lato";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 12px;
                }
            }
        }
    }
    .uploadMediaModal {
        width: 90%;
        .memory-gallery {
            padding-left: 0px;
        }
    }
}

.memories-wrapper-family {
    .memories-top-content-wrapper-family {
        .approve-memories-container {
            background-color: transparent !important;
            box-shadow: none;
            border-radius: none;
        }
    }
}

.rejection-modal {
    width: 530px;
    .close-button {
        display: flex;
        justify-content: end;
        margin-top: 25px;
        cursor: pointer;
    }
    .rejection-body {
        padding: 0px 30px;
        font-family: "Lato";
        .rejection-header {
            font-size: 30px;
            font-weight: bold;
            color: #161616;
            margin-top: 15px;
        }
        .description-container {
            margin: 50px 0px;
            display: flex;
            flex-direction: column;
            .description-text {
                font-size: 16px;
                line-height: 1.5;
                color: #000;
            }
            .description-input {
                padding: 10px;
                color: #000;
                border: 1px solid #bdbdbd;
                box-shadow: 0px 1px 2px #ececec;
                border-radius: 6px;
                height: 100px;
                font-size: 14px;
            }
            .input-error-txt {
                color: #ff0000;
                font-size: 14px;
                text-align: start;
                margin-top: 10px;
            }
            .required{
                color:#ff0000;
                font-size:20px;
            }
        }
        .footer-buttons-tab {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 50px;
            .modal-footer-button {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                border: none;
                border-radius: 4px;
                font-size: 16px;
                color: #fff;
                padding: 10px 30px;
                width: 150px;
                margin:0px 5px;
            }
            .cancel-button {
                background-color: #b6b6b6;
            }
            .reject-button {
                background-color: #ff0000;
            }
        }
    }
}
.gallery-modal {
        ul {
            padding-top: 5px;
            margin: 0;
            list-style: none;
            display: grid;
            grid-template-columns: 1fr 1fr;
            justify-items: center;
        }
        li:nth-child(1) {
            text-align:center;
            margin-left: auto;
          }
          li:nth-child(2) {
          margin-left: auto;
          }
    .modal-body {
        .gallery-buttons {
            position: absolute;
            top: 0;
            bottom: 0;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            width: 15%;
            color: #fff;
            text-align: center;
            button {
                position: absolute !important;
                top: auto !important;
            }
        }
        .gallery-prev {
            left: 25px;
        }
        .gallery-next {
            right: 25px;
        }
    }
}
@media (min-width: 250px) and (max-width: 567px){
    .gallery-modal {
        .image-file {
            height: 500px !important;
         }
    }
    .modal-open {
        .modal {
            .modal-dialog {
                &.images-preview-modal {
                    .modal-content {
                        .modal-body {
                            padding: 5px;
                            display:flex;
                            align-items: center;
                            justify-content: center;
                            .col {
                                padding: 0px !important;
                            }
                            .image-file-div  {
                                .previous-button,.next-button {
                                    width:25px !important;
                                    height: 25px !important;
                                    top:220px !important;
                                }
                                .next-button {
                                    right: 0px !important;
                                }
                                .previous-button {
                                    left: 0px !important;
                                }
                                .close-button {
                                    right: 0px !important;
                                    width: 30px !important;
                                    height: 30px !important;
                                }
                            }
                            .gallery-videos {
                                iframe {
                                    max-width: 100% !important;
                                    max-height: 100%;
                                    height: 500px !important;
                                    object-fit: contain !important;
                                }
                                .close-button {
                                    right: 0px;
                                    //top:200px;
                                    width:30px !important;
                                    height: 30px !important;
                                }
                                .previous-button,.next-button {
                                    top: 220px !important;
                                    width:25px !important;
                                    height: 25px !important;
                                }
                                .previous-button {
                                    left: 0px !important;
                                }
                                .next-button {
                                    right: 0px !important;
                                }
                            }
                            .gallery-videos  .video-file {
                                max-width: 100% !important;
                                max-height: 100%;
                                height: 500px !important;
                                object-fit: contain !important;
                            }
                            .audio-file-div {
                                height: 500px !important;
                                .gallery-audios {
                                    padding: 0px !important;
                                    .close-button {
                                        right: 0px;
                                        top:0px;
                                        width:30px !important;
                                        height: 30px !important;
                                    }
                                    .previous-button,.next-button {
                                        top: 220px !important;
                                        width:25px !important;
                                        height: 25px !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .images-preview-modal {
        .image-file {
            height: 500px !important;
        }
    }
    .rejection-modal {
        width: 100% !important;
        .rejection-body {
            padding: 0;
            .rejection-header {
                font-size: 26px;
            }
            .footer-buttons-tab {
                .modal-footer-button {
                    margin: 0px 5px;
                }
            }
        }
    }
    .obituaryDocuments {
        padding: 14px 30px 0px 40px;
    }
}

.rotationModal {
    max-width: 90% !important;
}
.rotateImageModal,.addVideoUrl{
     .rotationEditor {
        width: auto;
        height: 500px;
        max-height: 700px;
     }
     .rotationEditor-Website {
        width: 2000px;
        height: 700px;
        max-width: 100%;
        max-height: 100%;
     }
     .mobile-rotateImageEditor{
        min-height: 400px;
        width: auto;
     }
    .croppedImageEditor {
        .portraitImage {
        .reactEasyCrop_Contain {
            width: 700px ;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }
        }
        .landscapeImage {
            .reactEasyCrop_Contain {
                width: 2000px ;
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
            }
        }
        .reactEasyCrop_Contain {
            width: 2000px ;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }
        .reactEasyCrop_CropArea {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            border: 1px solid rgba(255, 255, 255, 0.5);
            box-sizing: border-box;
             box-shadow: none !important; 
            color: rgba(0, 0, 0, 0.5);
            overflow: hidden;
        }
        
    }
    .modal-footer-buttons {
        position: absolute;
        z-index: 1;
        bottom: 6px;
        // left: 36%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .footer-container {
        width: 400px;
        .footer-btn {
            padding: 5px 5px;
            color: white;
            font-size: 16px;
            min-width: 100px;
            min-height: 35px;
            border: 6px;
            border-radius: 6px;
            background-color: #1f870e;
        }
        .disable-button {
        color: #fff;
        opacity: 0.5;
        cursor: not-allowed 
        }
        
    }
    .custom-close-button {
        width: 30px;
        height: 30px;
        z-index: 1;
        position: absolute;
        top: -15px;
        right: -10px;
        cursor: pointer;
        object-fit: contain;
        color: #212529;
    }
    @media (max-width: 767px) and (min-width: 320px) {
        .rotationEditor {
            width: auto;
            height: 350px;
        }
        .footer-container {
                .footer-btn {
                font-size: 10px !important;
                min-width: 50px !important;
                min-height: 20px !important;
                margin-left: 4px !important;
                margin-right: 4px !important;
                }
            
            width: 300px !important;
        }
    }
}
@media (min-width:576px) and (max-width:1280px) {
    .uploadMedia-shareMemoryWrapper {
        width: 100%;
    }
}