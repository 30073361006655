
// basic colors
$white: #fff;
$black: #000;

// brand colors
$brand-primary: #2e7d32;
$brand-secondary: #f57c00;
$brand-tertiary: #60cf80;
$brand-default: #e0e0e0;

$font-color: #67666e;

$base-color: #fafdcb;
$button-color: #248ea9;
$theme-color: #28c3d4;
$theme-hg-color: #aee7e8;
$theme-text-color: #2e2e2e;
$shadow-color: rgba(0, 0, 0, 0.1);
$theme-btn-bg: #1f870e;
$theme-note-color:  #219653;
$theme-cancel-btn-bg: #8E8E8E;
$theme-modal-border: #2d9cdb;
$theme-modal-bg: #fcfcfc;

@import url('https://fonts.googleapis.com/css?family=Dosis:300,400,500,600,700|Poppins:200,200i,300,300i,400,400i,500,500i,600,600i,700,700i&display=swap');

$Dosis: 'Dosis', sans-serif;
$Poppins: 'Poppins', sans-serif;

body {
    font-family: $Poppins;
}

.head-1 {

}

.head-2 {
    font-weight: 600;
    font-size: 3rem;
}

.head-3 {
    font-weight: 500;
    font-size: 2.5rem;
}

.head-4 {
    font-weight: 400;
    font-size: 2rem;
}

.head-5 {
    font-weight: 400;
    font-size: 1.8rem;
}

.head-6 {
    font-weight: 400;
    font-size: 1.5rem;
}
