@mixin custom-height($size, $line) {
    .ql-height-#{$size}-#{$line} {
        height: #{($size * $line) + (8 * ($line + 1))}px;
    }
}

.template-screen-edit {
    display: flex;
    flex-direction: column;
    //justify-content: space-between;
    background-color: white;
    height: 775px;
    width: 500px;
    padding: 2.5rem;
    line-height: 1.5;
    .prayercard-draggable {
        z-index: 10;
    }
    .prayer-content-spacing {
        line-height: 1.0;
    }

    .ql-container {
        font-size: 18px;
    }

    /* Set content for font-families */
    .ql-font-arial,
    .ql-font span[data-value="arial"]::before {
        font-family: Arial, sans-serif;
    }
    .ql-font-comicsans,
    .ql-font span[data-value="comicsans"]::before {
        font-family: "Comic Sans MS", cursive, sans-serif;
    }
    .ql-font-couriernew,
    .ql-font span[data-value="couriernew"]::before {
        font-family: "Courier New";
    }
    .ql-font-georgia,
    .ql-font span[data-value="georgia"]::before {
        font-family: Georgia, serif;
    }
    .ql-font-helvetica,
    .ql-font span[data-value="helvetica"]::before {
        font-family: Helvetica, sans-serif;
    }
    .ql-font-lucida,
    .ql-font span[data-value="lucida"]::before {
        font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
    }

    .ql-font-playball,
    .ql-font span[data-value="playball"]::before {
        font-family: $playball;
    }

    .ql-font-cinzel,
    .ql-font span[data-value="cinzel"]::before {
        font-family: $cinzel;
    }

    .ql-font-roboto,
    .ql-font span[data-value="roboto"]::before {
        font-family: $roboto;
    }

    .ql-font-robotoslab,
    .ql-font span[data-value="robotoslab"]::before {
        font-family: $robotoslab;
    }

    .ql-font-lato,
    .ql-font span[data-value="lato"]::before {
        font-family: $lato;
    }

    .ql-font-martel,
    .ql-font span[data-value="martel"]::before {
        font-family: $martel;
    }

    .ql-font-dancing-script,
    .ql-font span[data-value="dancing-script"]::before {
        font-family: $dancing;
    }

    /* Set content for sizes */
    .ql-size-fs12,
    .ql-size span[data-value="fs12"]::before {
        font-size: 12px !important;
    }
    .ql-size-fs13,
    .ql-size span[data-value="fs13"]::before {
        font-size: 13px !important;
    }

    .ql-size-small,
    .ql-size span[data-value="small"]::before {
        font-size: 14px !important;
    }
    .ql-size-fs15,
    .ql-size span[data-value="fs15"]::before {
        font-size: 15px !important;
    }
    .ql-size-smmedium,
    .ql-size span[data-value="smmedium"]::before {
        font-size: 16px !important;
    }
    .ql-size-medium,
    .ql-size span[data-value="medium"]::before {
        font-size: 18px !important;
    }
    .ql-size-lgmedium,
    .ql-size span[data-value="lgmedium"]::before {
        font-size: 21px !important;
    }
    .ql-size-large,
    .ql-size span[data-value="large"]::before {
        font-size: 24px !important;
    }
    .ql-size-huge,
    .ql-size span[data-value="huge"]::before {
        font-size: 32px !important;
    }

    .ql-size-fs17,
    .ql-size span[data-value="fs17"]::before {
        font-size: 17px !important;
    }

    .ql-size-fs19,
    .ql-size span[data-value="fs19"]::before {
        font-size: 19px !important;
    }

    .ql-size-fs20,
    .ql-size span[data-value="fs20"]::before {
        font-size: 20px !important;
    }

    .ql-size-fs22,
    .ql-size span[data-value="fs22"]::before {
        font-size: 22px !important;
    }

    .ql-size-fs23,
    .ql-size span[data-value="fs23"]::before {
        font-size: 23px !important;
    }

    .ql-size-fs25,
    .ql-size span[data-value="fs25"]::before {
        font-size: 25px !important;
    }

    .ql-size-fs26,
    .ql-size span[data-value="fs26"]::before {
        font-size: 26px !important;
    }

    .ql-size-fs27,
    .ql-size span[data-value="fs27"]::before {
        font-size: 27px !important;
    }

    .ql-size-fs28,
    .ql-size span[data-value="fs28"]::before {
        font-size: 28px !important;
    }




    /* Set content for alignment */
    .ql-align-left,
    .ql-align span[data-value=""]::before {
        text-align: left !important;
    }
    .ql-align-center,
    .ql-align span[data-value="center"]::before {
        text-align: center !important;
    }
    .ql-align-right,
    .ql-align span[data-value="right"]::before {
        text-align: right !important;
        padding:5px;
    }
    .ql-align-justify,
    .ql-align span[data-value="justify"]::before {
        text-align: justify !important;
        padding:5px;
    }

    /* Generate custom height for editor */
    // $heights: ("fs12", "fs13","small","fs15", "smmedium", "fs17" "medium", "fs19", "fs20", "lgmedium", "fs22", "fs23", "large","fs25", "fs26","fs27", "fs28", "huge");
    $heights: (12,13,14,15, 16, 17, 18, 19, 20, 21, 22, 23, 24,25, 26,27, 28, 32);
    $lineNumber: (1, 2, 14, 15);
    @mixin generate-height() {
        @each $height in $heights {
            @each $line in $lineNumber {
                @include custom-height($height, $line);
            }
        }
    }

    @include generate-height();
}

.print-mode{
    .template-screen-edit {
        div{
            margin-bottom:5px;
        }
    }
}

.nav-mode {
    .template-card {
        height: 340px;
        width: 200px;
        padding: 0;

        &.first-page{
            height: 320px;
        }
    }
    .template-content-card {
        height: 310px !important;
    }
    .template-custom-image {
        height: 322px !important;
        width: 187px !important;
    }
    .template-screen-edit {
        height: 310px;
        width: 200px;
        padding: 1rem;
        line-height: 0.6;

        div {
            //flex-grow: 1;
            flex-shrink: 1;
            flex-basis: auto;
            margin-bottom:1px;
        }

        p {
            margin-bottom: unset;
            word-break: break-word;
        }

        .ql-container {
            font-size: 7.2px;
        }

        /* Set content for sizes */
        .ql-size-fs12,
        .ql-size span[data-value="fs12"]::before {
            font-size: 4.8px !important;
        }

        .ql-size-fs13,
        .ql-size span[data-value="fs13"]::before {
            font-size: 5.2px !important;
        }

        .ql-size-small,
        .ql-size span[data-value="small"]::before {
            font-size: 5.6px !important;
        }

        .ql-size-fs15,
        .ql-size span[data-value="fs15"]::before {
            font-size: 6.0px !important;
        }

        .ql-size-smmedium,
        .ql-size span[data-value="smmedium"]::before {
            font-size: 6.4px !important;
        }

        .ql-size-medium,
        .ql-size span[data-value="medium"]::before {
            font-size: 7.2px !important;
        }

        .ql-size-lgmedium,
        .ql-size span[data-value="lgmedium"]::before {
            font-size: 8.4px !important;
        }

        .ql-size-large,
        .ql-size span[data-value="large"]::before {
            font-size: 9.6px !important;
        }

        .ql-size-huge,
        .ql-size span[data-value="huge"]::before {
            font-size: 12.8px !important;
        }

        .ql-size-fs17,
        .ql-size span[data-value="fs17"]::before {
            font-size: 6.8px !important;
        }

        .ql-size-fs19,
        .ql-size span[data-value="fs19"]::before {
            font-size: 7.6px !important;
        }

        .ql-size-fs20,
        .ql-size span[data-value="fs20"]::before {
            font-size: 8.0px !important;
        }

        .ql-size-fs22,
        .ql-size span[data-value="fs22"]::before {
            font-size: 8.8px !important;
        }

        .ql-size-fs23,
        .ql-size span[data-value="fs23"]::before {
            font-size: 9.2px !important;
        }

        .ql-size-fs25,
        .ql-size span[data-value="fs25"]::before {
            font-size: 10.0px !important;
        }

        .ql-size-fs26,
        .ql-size span[data-value="fs26"]::before {
            font-size: 10.6px !important;
        }

        .ql-size-fs27,
        .ql-size span[data-value="fs27"]::before {
            font-size: 11.2px !important;
        }

        .ql-size-fs28,
        .ql-size span[data-value="fs28"]::before {
            font-size: 11.7px !important;
        }
    }
}

.carousel {
    .template-card {
        padding: 0;
    }
    .template-screen-edit {
        height: 680px;
        width: 400px;
        padding: 2rem;
        line-height: normal;

        p {
            margin-bottom: unset;
        }

        .ql-container {
            font-size: 16.8px;
        }

        /* Set content for sizes */
        .ql-size-fs12,
        .ql-size span[data-value="fs12"]::before {
            font-size: 9.6px !important;
        }

        .ql-size-fs13,
        .ql-size span[data-value="fs13"]::before {
            font-size: 10.4px !important;
        }

        .ql-size-small,
        .ql-size span[data-value="small"]::before {
            font-size: 11.2px !important;
        }
        .ql-size-fs15,
        .ql-size span[data-value="fs15"]::before {
            font-size: 12.0px !important;
        }
        
        .ql-size-smmedium,
        .ql-size span[data-value="smmedium"]::before {
            font-size: 12.8px !important;
        }
        .ql-size-fs17,
        .ql-size span[data-value="fs17"]::before {
            font-size: 13.6px !important;
        }
        .ql-size-medium,
        .ql-size span[data-value="medium"]::before {
            font-size: 14.4px !important;
        }
        .ql-size-fs19,
        .ql-size span[data-value="fs19"]::before {
            font-size: 15.2px !important;
        }
        .ql-size-fs20,
        .ql-size span[data-value="fs20"]::before {
            font-size: 16.0px !important;
        }
        .ql-size-lgmedium,
        .ql-size span[data-value="lgmedium"]::before {
            font-size: 16.8px !important;
        }
        .ql-size-fs22,
        .ql-size span[data-value="fs22"]::before {
            font-size: 17.6px !important;
        }
        .ql-size-fs23,
        .ql-size span[data-value="fs23"]::before {
            font-size: 18.4px !important;
        }
        .ql-size-large,
        .ql-size span[data-value="large"]::before {
            font-size: 19.2px !important;
        }
        .ql-size-fs25,
        .ql-size span[data-value="fs25"]::before {
            font-size: 20.0px !important;
        }
        .ql-size-fs26,
        .ql-size span[data-value="fs26"]::before {
            font-size: 21.4px !important;
        }
        .ql-size-fs27,
        .ql-size span[data-value="fs27"]::before {
            font-size: 22.4px !important;
        }
        .ql-size-fs28,
        .ql-size span[data-value="fs28"]::before {
            font-size: 23.5px !important;
        }
        .ql-size-huge,
        .ql-size span[data-value="huge"]::before {
            font-size: 25.6px !important;
        }
    }
    .template-card-image {
        height: 680px;
        width: 400px;
    }
    .template-content-image {
        height: 620px !important;
        width: 400px;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
        @include box-shadow(0 3px 10px 0 rgba(144, 137, 137, 0.18));
    }
}
