.reset-form {
    font-family: "Lato";
    margin: 22px;
    margin-top: 40px;
    .forgot-heading {
        color: #161616;
        font-size: 22px;
        line-height: 1.5;
        margin-bottom: 7px;
        font-weight: 600;
    }
    .forgot-text {
        color: rgba(0, 0, 0, 0.6);
        font-size: 14px;
        line-height: 1.5;
        margin-bottom: 35px;
    }
    .form-label {
        color: #000;
        font-size: 16px;
        line-height: 1.5;
        font-weight: 500;
    }
    .form-input {
        padding: 20px;
        border: 1px solid #bdbdbd;
    }
    .error-msg {
        color: #ff0000;
        font-size: 14px;
    }
    .forgot-button {
        width: 100%;
        background-color: #298a45;
        height: 45px;
        margin-bottom: 35px;
        margin-top: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        cursor: pointer;
        border: 0;
        padding: 15px 10px;
        font-size: 12px;
        line-height: 1.43;
        letter-spacing: 0.2px;
        font-style: normal;
        font-size: 14px;
        border-radius: 3px;
    }
    .back-button-tab {
        display: flex;
        align-items: center;
        justify-content: start;
        margin-top: 15px;
        margin-bottom: 50px;
        .arrow-left {
            border: none;
            background-color: transparent;
            cursor: pointer;
            font-size: 14px;
            margin-right: 10px;
        }
        p {
            margin: 0;
            font-size: 14px;
            line-height: 1.5;
            color: #161616;
        }
    }
}
