@import '../../../assets/styles/_color.scss';

.dropzone-file-card {
  padding: 10px 10px 0 10px;
  background-color: $white;
  box-shadow: 0px 4px 92px rgba(208, 208, 208, 0.35);
  color: $grey1;
  margin-bottom: 30px;
  min-height: 64px;

  .progress-bar {
    background-color: $brand-primary;
  }

  .dropzone-file-card-details {
    color: $light-grey;
    font-size: 12px;
    margin-bottom: 10px;
  }

  .btn-icon {
    background: none;
    border: none;
    color: $grey1;
  }
}
