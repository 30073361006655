.arrangements-wrapper {
    .cardbox-wrapper {
        &:not(:last-of-type) {
            margin-bottom: 40px;
        }
        .cardbox-body {
            .btn-bottom {
                width: 100%;
                border-bottom: 1px solid #f2f2f2;
                padding-bottom: 24px;
            }
            .view-info-button {
                color: $brand-secondary;
                text-decoration: underline;
                &.btn-theme {
                    background-color:#298a45;
                    color:$white;
                    padding: 0 16px;
                    margin-top: 24px;
                    min-height: 38px;
                    text-decoration: none;
                }
            }
            .timestamp-wrapper {
                margin-top: 1rem;
                line-height: 0.5;
            }
        }
        .card-heading-size{
            font-size:21px;
        }
    }
    .steps-heading{
        color:#000;
        font-family:'Lato';
        font-size:20px;
        font-weight:700;
        margin-bottom:25px;
    }
    .cardbox-small{
        width:46%;
    }
    .cardbox-text{
        font-size:20px;
        color:#000000;
        opacity:0.7;
    }
}

@media (max-width: 767px) and (min-width: 320px) {
    .arrangements-wrapper {
        .cardbox-wrapper {
            &:not(:last-of-type) {
                margin-bottom: 24px;
            }
            .cardbox-body {
                p {
                    font-size: 14px;
                    padding-bottom: 8px;
                    &.btn-bottom {
                        width: 100%;
                        border-bottom: 0;
                        padding-bottom: 12px;
                    }
                }
                .view-info-button {
                    &.btn-theme {
                        margin-top: 0;
                        font-size: 14px;
                    }
                }
                .cardbox-text{
                    font-size:20px;
                }
            }
        }
        .cardbox-small{
            width:100%;
        }
    }
}

@media (max-width: 480px) and (min-width: 320px) {
    .arrangements-wrapper {
        .cardbox-wrapper {
            .cardbox-body {
                .view-info-button {
                    &.btn-theme {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media (max-width: 320px) {
    .arrangements-wrapper{
        .cardbox-small{
            width:100%;
        }
        .cardbox-text{
            font-size:14px;
        }
    }
}

.large-tooltip-section {
    .tooltip-inner{
        max-width: 300px;
    }
}

.space-tooltip-icon {
    margin-left: 10px;
}
.space-tooltip-icon-top {
    margin-top: 8px;
}
.space-tooltip-image-icon {
    margin-left: 10px;
    cursor: pointer;
}
.toopltip-space-top {
    margin-top: 5px;
}
.toopltip-space-top7px {
    margin-top: 7px;
}

.red-color {
    color: red;
}
.green-color{
    color:#298a45;
}