.memory-container {
    // padding-left: 0;
    // margin-left: 0;
    // padding-right: 0;
    // margin-right: 0;
   // max-width: unset !important;
}
.memories-wrapper {
    display: flex;
    background: #ffffff;
    padding: 42px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    margin: 40px 60px;
    margin-bottom: 20px;

    .MuiSwitch-colorSecondary.Mui-checked {
        color: #1f870e;
    }
    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
        background-color: #1f870e;
    }

    .MuiFormControlLabel-label {
        font-weight: 600 !important;
        font-size: 17px;
    }

    .memories-left-content-wrapper {
        width: 24%;
        padding: 0px 35px 0px 0px;
        position: relative;
        border-right: 1px solid #c1c2c3;

        .profile-pic-wrapper {
            height: auto;
            width: 220px;
            border-radius: 10px;
            background-color: rgb(237,235,235);
        }

        ul {
            list-style: none;
            padding-left: 0;
            margin-bottom: 0;
            li {
                padding-top: 20px;
                display: flex;

                .options-wrapper {
                    padding-left: 50px;
                    position: relative;
                    color: $theme-text-color;
                    font-family: "Lato";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    cursor: pointer;
                    line-height: 18px;
                    .icon-wrapper {
                        position: absolute;
                        top: 0;
                        left: 20px;
                        height: 19px;
                    }
                    .MuiSwitch-switchBase
                        .MuiFormControlLabel-label.MuiTypography-root {
                        font-weight: 600;
                    }
                    &.long-text-wrapper {
                        display: inline-flex;
                    }
                }

                &.empty-li {
                    height: 200px;
                }
            }
            .obituary-upload-image {
                display:flex;
                justify-content: center;
                margin-top:25px;
                margin-right:5px;
            }
            .switch-icon {
                display:flex;
                justify-content: space-between;
            }
        }
        .bck-family-btn {
            width: 100%;
            height: 43px;
            background: $theme-btn-bg;
            border-radius: 8px;
            font-family: Roboto;
            font-style: normal;
            font-weight: 500 !important;
            font-size: 14px;
            line-height: 16px;
            // padding: 13px 0;
            // bottom: 65px;
            // position: absolute;
        }
        .display-name-container {
            margin-top:30px;
            border-top: 1px solid #c1c2c3;
            .display-name-heading{
                display:flex;
                justify-content: space-between;
                align-items: center;
                margin-top:20px;
                .display-name{
                    font-size:20px;
                    font-family: "Lato";
                    font-weight: bold;
                    margin:0;
                }
            }  
        }
        .display-dropdown-container {
            margin-top:25px;
            .selected-name {
                font-size: 20px;
                font-weight: bold;
                margin-top: 20px;
                margin-bottom: 20px;
                font-family: "Lato";
                text-align: center;
            }
            .save-button, .delete-button{
                color: #fff;
                cursor: pointer;
                border: 0;
                padding: 15px 10px;
                font-size: 10px;
                line-height: 1.43;
                letter-spacing: 0.2px;
                border-radius: 5px;
                font-family: "Lato";
                font-style: normal;
                font-size: 16px;
                padding-left: 10px;
                width: 90px;
                background-color: #337a2e;
                height: 45px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 20px;
                padding-left: 10px;
                border-radius: 6px;

                &[disabled],
                &:disabled,
                &.disabled {
                    background-color: #c4c4c4;
                    cursor: not-allowed;
                }   
            }
            .delete-button {
                margin-right: 10px;
            }
        }
        .display-filter-options {
            padding-left:20px;
            .checkbox-heading {
                font-size:18px;
            }
            input[type="checkbox"] {
                margin-top: 0.5rem;
            }
            .color-checked-change {
                accent-color: #337a2e;
                opacity:0.7;
            }
            .color-change {
                accent-color: #337a2e;
            }
        }
    }
    .memories-not-found-content-wrapper {
        padding: 24px 0;
        width: 100%;
        text-align: center;
        min-height: 255px;
        height: fit-content;
    }

    .memories-main-content-wrapper {
        padding: 5px 40px 24px;
        width: 76%;
        .mobile-data-wrapper {
            display: none;
        }
        .cancel-button {
            margin-right: 20px;
            background-color: rgb(161, 163, 165);
            &:hover {
                background-color: #6c757d;
                color: #fff;
            }
        }

        .custom-btn {
            &:hover {
                color: #fff;
            }
        }
        h2 {
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 27px;
            color: #000000;
            margin-bottom: 0;
            padding-bottom: 24px;
        }
        .story-wrapper {
            width: 100%;
            min-height: 255px;
            height: fit-content;
            overflow: auto;
            padding: 20px 30px;
            margin-bottom: 20px;
            border: 1px solid #c1c2c3;
            border-radius: 8px;

            .profile-wrapper {
                display: flex;
                position: relative;
                .profile-picture-wrapper {
                    width: 160px;
                    position: relative;

                    &.disable-onhover {
                        pointer-events: none;
                    }
                    img {
                        width: 160px;
                        height: 160px;
                        object-fit: cover;
                    }

                    .link-customise {
                        @include center(both);
                        min-width: 70px;
                        min-height: 30px;
                        padding: 2px;
                        color: white;
                        visibility: hidden;
                        z-index: 2;
                    }
                    &::after {
                        position: absolute;
                        content: "";
                        width: 100%;
                        height: 100%;
                        background: rgba($black, 0.5);
                        left: 0;
                        top: 0;
                        display: none;
                    }
                    &:hover {
                        &::after {
                            display: block;
                        }
                        .link-customise {
                            visibility: visible;
                        }
                    }
                    a {
                        cursor: pointer;
                        .BoldPic-wrapper {
                            top: 98px;
                            position: absolute;
                            right: 90px;
                        }
                        .ItalicPic-wrapper {
                            top: 130px;
                            position: absolute;
                            right: 90px;
                        }
                        .UPic-wrapper {
                            top: 164px;
                            position: absolute;
                            right: 90px;
                        }
                    }
                }
                .profile-thought-wrapper {
                    width: calc(100% - 160px);
                    font-family: Playfair Display;
                    font-style: normal;
                    // font-weight: bold;
                    font-size: 20px;
                    line-height: 27px;
                    color: #000000;
                    max-width: 490px;
                    padding: 5px 5px 5px 49px;
                    display: flex;
                    width: 100%;
                    flex-direction: row-reverse;
                    span {
                        font-family: Playfair Display;
                        // font-style: italic;
                        // font-weight: normal;
                        font-size: 18px;
                        line-height: 1.5;
                        color: black;
                    }
                    .profile-quote {
                        width: 100%;
                    }
                    .container-root {
                        height: 150px;
                        background: white;
                        box-sizing: border-box;
                        // box-shadow: 2px 0px 7px rgba(0, 0, 0, 0.1);
                        border-radius: 8px;
                        &.profile-quote {
                            min-height: 127px;
                            height: fit-content;
                            padding: 0.5rem 1rem;
                        }
                        h1 {
                            line-height: 24px;
                        }
                    }
                }
                .memory-content {
                    position: absolute;
                    width: 753px;
                    height: 418px;
                    left: 344px;
                    top: 1985px;
                    background: rgba(0, 0, 0, 0.38);
                }
                .memory-title {
                    position: absolute;
                    width: 288px;
                    height: 27px;
                    left: 372px;
                    top: 1932px;

                    font-family: Inter;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 27px;
                    /* identical to box height, or 150% */

                    color: #000000;
                }
            }
            a {
                font-family: Lato;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 27px;
                color: #27ae60;
            }
            .copyRightText {
                font-weight: bold;
                font-size: 12pt;
            }
            .read-less-more {
                text-align: center;
                display: block;
            }
            .lifeof-wrapper {
                display: flex;
                width: 100%;
                flex-direction: row-reverse;
                justify-content: flex-end;
                .container-root {
                    // height: 350px;
                    min-height: 350px;
                    height: fit-content;
                    width: calc(100% - 25px);
                    background: white;
                    box-sizing: border-box;
                    border-radius: 8px;
                    padding: 30px;
                }
                .options {
                    padding: 0;
                    width: 25px;
                    .selection {
                        border: 0.5px solid #2d9cdb;
                    }
                }
            }
            .mask {
                position: relative;
                float: left;
                clear: none;
                background-color: white;
                width: 170px;
                height: 170px;
                padding: 5px;
                margin-right: 30px;
                .profile-picture-wrapper {
                    width: 160px;
                    position: relative;

                    &.disable-onhover {
                        pointer-events: none;
                    }
                    img {
                        width: 160px;
                        height: 160px;
                        object-fit: cover;
                    }

                    .link-customise {
                        @include center(both);
                        min-width: 70px;
                        min-height: 30px;
                        padding: 2px;
                        color: white;
                        visibility: hidden;
                        z-index: 2;
                    }
                    &::after {
                        position: absolute;
                        content: "";
                        width: 100%;
                        height: 100%;
                        background: rgba($black, 0.5);
                        left: 0;
                        top: 0;
                        display: none;
                    }
                    &:hover {
                        &::after {
                            display: block;
                        }
                        .link-customise {
                            visibility: visible;
                        }
                    }
                    a {
                        cursor: pointer;
                        .BoldPic-wrapper {
                            top: 98px;
                            position: absolute;
                            right: 90px;
                        }
                        .ItalicPic-wrapper {
                            top: 130px;
                            position: absolute;
                            right: 90px;
                        }
                        .UPic-wrapper {
                            top: 164px;
                            position: absolute;
                            right: 90px;
                        }
                    }
                }
            }
            .profile-description-wrapper {
                min-height: 170px;
                width: 100% !important;
                overflow: auto;
                &.maxHeight275 {
                    max-height: 275px;
                    overflow: hidden !important;
                }
            }
            .profile-description-input {
                width: calc(100% - 210px);
                border: none;
                height: 40px;
            }
            input.profile-description-input[disabled] {
                background: none !important;
            }
        }
        .audio-wrapper {
            .audio-file {
                width: 80%;
                audio {
                    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
                    border-radius: 30px;
                    width: 95%;
                }
            }

            .btn-edit {
                border: 2px solid #2e7d32;
                background-color: white;
                color: #2e7d32;
                padding: 6px;
                width: 250px;
            }
        }
        .memory-list-wrapper {
            margin-top: 20px;
            .memory-card {
                word-break: break-word !important;
                width: 100%;
                background: $white;
                box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
                border-radius: 8px;
                margin-bottom: 24px;
                .memory-card-header {
                    padding: 10px 20px;
                    position: relative;
                    .memory-title {
                        font-family: "Lato";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 27px;
                        color: $black;
                        padding-top: 0;
                        margin-bottom: 0;
                    }
                    .memory-icon {
                        position: absolute;
                        top: 15px;
                        right: 20px;
                        img {
                            width: 24px;
                            height: 20px;
                        }
                    }
                    .memory-icon:hover div.title {
                        display: inline;
                    }
                    .title {
                        display: none;
                        position: absolute;
                        color: white;
                        background: #393939;
                        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
                        border-radius: 4px;
                        padding: 2px 4px;
                        margin-top: 28px;
                    }
                    .memory-date {
                        font-family: "Lato";
                        font-style: italic;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 27px;
                        color: $black;
                        opacity: 0.5;
                    }
                }
                .memory-content {
                    padding: 0 20px;
                    .media-container {
                        box-shadow: -2px 2px 2px 0 rgba(0, 0, 0, 0.07);
                        .single-container {
                            height: 300px;
                            width: 100%;
                            // .video-file-div,
                            .image-file-div {
                                height: 100%;
                                padding: 1rem;
                                // .video-file,
                                iframe,
                                canvas,
                                .image-file {
                                    height: 100%;
                                    pointer-events: none;
                                }
                            }
                            .large-view {
                                padding: 0;
                                height: 100%;
                                padding: 0.2rem;
                                .video-file-div {
                                    width: 100%;
                                    height: 100%;
                                    align-items: center;
                                    display: flex;
                                    .video-file {
                                        width: 100%;
                                        height: 100%;
                                        pointer-events: none;
                                        iframe {
                                            height: 100%;
                                        }
                                    }
                                }
                            }
                        }
                        .dual-container {
                            // height: 300px;
                            .view {
                                padding: 0 0.2rem 0 0;
                                min-height: 100%;
                                .video-file-div,
                                .image-file-div {
                                    width: 100%;
                                    height: 100%;
                                    .video-file,
                                    .image-file,
                                    iframe {
                                        width: 100%;
                                        height: 100%;
                                        pointer-events: none;
                                    }
                                }
                            }
                            .view-mobile {
                                margin-top: 0;
                            }
                        }
                        .three-container {
                            height: 350px;
                            width: 100%;
                            .large-view {
                                padding: 0;
                                height: 100%;
                                padding: 0.2rem;
                                .image-file-div {
                                    background: rgba(175, 175, 175, 0.5);
                                    padding: 0.2rem;
                                    width: 100%;
                                    height: 100%;
                                    align-items: center;
                                    display: flex;
                                    justify-content: center;
                                    .image-file {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: contain;
                                    }
                                }
                                .video-file-div {
                                    width: 100%;
                                    height: 100%;
                                    align-items: center;
                                    display: flex;
                                    .video-file,
                                    iframe,
                                    canvas {
                                        width: 100%;
                                        height: 100%;
                                        pointer-events: none;
                                    }
                                }
                            }
                            .small-view {
                                padding: 0;
                                height: 100%;
                                .small-media-div {
                                    padding: 0.2rem;
                                    width: 100%;
                                    height: 50%;
                                    .video-file-div {
                                        width: 100%;
                                        height: 100%;
                                        align-items: center;
                                        display: flex;
                                        .video-file,
                                        iframe,
                                        canvas,
                                        div {
                                            width: 100%;
                                            height: 100%;
                                            pointer-events: none;
                                        }
                                    }
                                    .image-file-div {
                                        width: 100%;
                                        height: 100%;
                                        align-items: center;
                                        display: flex;
                                        background: rgba(175, 175, 175, 0.5);
                                        .image-file {
                                            width: 100%;
                                            height:100%;
                                            max-height: 100%;
                                        }
                                    }
                                }
                            }
                        }
                        .multi-container {
                            height: 400px;
                            width: 100%;
                            .large-view {
                                padding: 0;
                                height: 100%;
                                .image-file-div {
                                    padding: 0.2rem;
                                    width: 100%;
                                    height: 100%;
                                    align-items: center;
                                    display: flex;
                                    background: rgba(175, 175, 175, 0.5);
                                    .image-file {
                                        width: 100%;
                                        height: 100%;
                                        max-height: 100%;
                                    }
                                }
                                .video-file-div {
                                    padding: 0.2rem;
                                    width: 100%;
                                    height: 100%;
                                    align-items: center;
                                    display: flex;
                                    .video-file,
                                    iframe,
                                    canvas {
                                        width: 100%;
                                        height: 100%;
                                        pointer-events: none;
                                    }
                                }
                            }
                            .small-view {
                                padding: 0;
                                height: 100%;
                                .small-media-div {
                                    width: 100%;
                                    height: 33%;
                                    padding: 0.2rem;
                                    .video-file-div {
                                        width: 100%;
                                        height: 100%;
                                        align-items: center;
                                        display: flex;
                                        .video-file,
                                        iframe,
                                        canvas,
                                        div {
                                            height: inherit;
                                            width: inherit;
                                            pointer-events: none;
                                        }
                                    }
                                    .image-file-div {
                                        width: 100%;
                                        height: 100%;
                                        align-items: center;
                                        display: flex;
                                        background: rgba(175, 175, 175, 0.5);
                                        .image-file {
                                            width: 100%;
                                            max-height: 100%;
                                            height: 100%;
                                        }
                                    }
                                }
                                .show-more-div {
                                    height: 34%;
                                    width: 100%;
                                    position: relative;
                                    .media-div {
                                        position: absolute;
                                        height: 100%;
                                    }

                                    .mask-layer {
                                        position: absolute;
                                        z-index: 8;
                                        width: 100%;
                                        height: 100%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        background-color: rgba(0, 0, 0, 0.4);
                                        pointer-events: none;
                                        span {
                                            font-size: 28px;
                                            color: white;
                                            font-weight: 600;
                                        }
                                    }
                                }
                            }
                        }
                        .error-div {
                            background-color: lightgray;
                            border: 5px solid gray;
                            padding: 15px;
                        }
                        .audio-file-div {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            height: 100%;
                            background: rgba(175, 175, 175, 0.5);
                            .audio-file {
                                pointer-events: none;
                            }
                        }
                    }
                    .row {
                        margin-bottom: 15px;
                        margin-right: 0;
                        margin-left: 0;
                    }
                }
                .memory-card-footer {
                    @extend .d-flex;
                    @extend .justify-content-start;
                    @extend .flex-column;
                    padding: 5px 20px 20px;
                    .memory-postedBy {
                        font-family: "Lato";
                        font-style: italic;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 27px;
                        color: $black;
                        opacity: 0.5;
                        padding-top: 5px;
                    }
                    span {
                        font-family: "Lato";
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 27px;
                        color: $black;
                    }
                }
            }
        }
    }
    .memories-right-content-wrapper {
        width: 270px;
        min-height: 672px;
        padding: 24px 30px;
        background: $white;
        box-shadow: 2px 0px 8px $shadow-color;
        h4 {
            font-family: Lato;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            color: #000000;
            margin-bottom: 17px;
        }
        p {
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 27px;
            color: rgba(0, 0, 0, 0.6);
            margin-bottom: 0;
        }
        .events-details-wrapper {
            font-family: Lato;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 27px;
            color: #2d9cdb;
            display: block;
            padding-bottom: 24px;
        }
        .filters-wrapper {
            border-top: 0.5px solid rgba(0, 0, 0, 0.3);
            h6 {
                font-family: Lato;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 21px;
                color: #2e2e2e;
                opacity: 0.5;
                margin-bottom: 0;
                padding: 24px 0 16px;
            }
            ul {
                list-style: none;
                li {
                    font-family: Lato;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 27px;
                    color: #2e2e2e;
                }
            }
        }
    }
}
.slidecontainer {
    width: 270px;
    padding:15px 0px;
    .symbol-size {
        font-size: 20px;
        cursor: pointer;
    }
}
.slider {
    -webkit-appearance: auto;
    width: 240px;
    height: 1px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
}
.slider:hover {
    opacity: 1;
}
.avatar-buttons-wrapper {
    width: 240px;
    .btn-theme {
        padding: 5px 5px;
        font-size: 16px;
        min-width: 100px;
        min-height: 35px;
    }
}

.modal-open {
    .modal {
        .modal-dialog {
            &.images-preview-modal {
                .modal-content {
                    .modal-header {
                        background-color: $white;
                        padding: 20px;
                        > h5 .modal-close {
                            top: 0;
                            right: 5px;
                        }
                    }
                    .modal-body {
                        position: relative;
                        padding: 15px 30px 30px;
                        .modal-next {
                            position: absolute;
                            top: -265px;
                            right: -15px;
                            border: none;
                            background: $white;
                            font-size: 40px;
                            font-weight: 700;
                        }
                        .modal-prev {
                            position: absolute;
                            top: 175px;
                            left: -15px;
                            border: none;
                            background: $white;
                            font-size: 40px;
                            font-weight: 700;
                        }
                        .video-file {
                            width: 708px;
                            height: 442.5px;
                        }
                        .image-file {
                            width: 708px;
                            height: 442.5px;
                            object-fit: contain;
                        }
                        .audio-file-div {
                            width: 708px;
                            height: 442.5px;
                            display: flex;
                            align-items: center;
                            .audio-file {
                                width: 100%;
                            }
                        }
                        iframe {
                            width: 708px;
                            height: 442.5px;
                        }
                        .dot {
                            cursor: pointer;
                            height: 10px;
                            width: 10px;
                            margin: 0 2px;
                            background-color: #bbb;
                            border-radius: 50%;
                            display: inline-block;
                            transition: background-color 0.6s ease;
                        }
                        .active,
                        .dot:hover {
                            cursor: pointer;
                            height: 10px;
                            width: 10px;
                            margin: 0 2px;
                            background-color: lightblue;
                            border-radius: 50%;
                            display: inline-block;
                            transition: background-color 0.6s ease;
                        }
                    }
                    .modal-footer {
                        border: 0;
                        padding: 20px 30px;
                    }
                }
            }
        }
    }
}
.hasFocus {
    /* border: 0.5px solid #2d9cdb; */
}
.noFocus {
    border: #f1f1f1;
}
.errorFocus {
    border: 0.5px solid red;
}

.life-of-content-title {
    font-family: Inter;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
    .options {
        padding: 0;
        width: 25px;
        .selection {
            border: 0.5px solid #2d9cdb;
        }
    }
    .container-root {
        height: 211px;
        background: #fcfcfc;

        box-sizing: border-box;
        box-shadow: 2px 0px 7px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
    }
    a {
        cursor: pointer;
        // .BoldPic-wrapper {
        //     top: 30px;
        //     position: absolute;
        //     right: 6px;
        // }
        // .ItalicPic-wrapper {
        //     top: 60px;
        //     position: absolute;
        //     right: 6px;
        // }
        // .UPic-wrapper {
        //     top: 90px;
        //     position: absolute;
        //     right: 6px;
        // }
    }
}
.errorMessage {
    color: red;
    font-size: small;
}

.menu-modal {
    ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        li {
            padding-top: 20px;
            .options-wrapper {
                padding-left: 50px;
                position: relative;
                color: $theme-text-color;
                font-family: "Lato";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                cursor: pointer;
                line-height: 18px;
                .icon-wrapper {
                    position: absolute;
                    top: 0;
                    left: 20px;
                    height: 19px;
                }
                &.long-text-wrapper {
                    display: inline-flex;
                }
                .MuiSwitch-switchBase
                    .MuiFormControlLabel-label.MuiTypography-root {
                    font-weight: 600;
                }
            }

            &.empty-li {
                height: 200px;
            }
        }
    }
    .bck-family-btn {
        width: 100%;
        height: 43px;
        background: $theme-btn-bg;
        border-radius: 8px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500 !important;
        font-size: 14px;
        line-height: 16px;
    }
}
.customizeFamilyWebsiteWrapper {
    .lineTag {
        margin-top: 30px;
    }
    .display-name-heading{
        display:flex;
        justify-content: space-between;
        align-items: center;
        margin-top:20px;
        .display-name{
            display: flex;
            font-size:20px;
            font-family: "Lato";
            font-weight: bold;
            margin:0;
        }
        .customIndex {
            margin-right: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            font-size: 20px;
            height: 30px;
            border: 1px solid black;
            border-radius: 50%;

        }
    } 
    .display-name-container {
        .chooseName{
            font-size: 18px;
            margin-top: 30px;
        }
        .display-dropdown-container {
            margin-top: 22px;
            .select-wrapper {
                max-width: 310px;
                margin-bottom: 15px;
            }
           
            .form-check-input {
                margin-left: 0px !important;
                height: 30px;
                width: 21px;
            }
            .filterLabel {
                padding-bottom: 10px;
            }
            .filterValue {
                padding-left: 30px;
            }
            .previewText {
                font-weight: normal;
            }
            .selected-name {
                display: flex;
                font-size: 18px;
                font-weight: bold;
                margin-top: 20px;
                margin-bottom: 20px;
                font-family: "Lato";
            }
            
        }
        .display-filter-options {
            padding-left:20px;
            .checkbox-heading {
                font-size:18px;
            }
            input[type="checkbox"] {
                margin-top: 0.5rem;
            }
            .color-checked-change {
                accent-color: #337a2e;
                opacity:0.7;
            }
            .color-change {
                accent-color: #337a2e;
            }
        }
    }
    .save-button, .delete-button {
        color: #fff;
        cursor: pointer;
        border: 0;
        padding: 15px 10px;
        font-size: 10px;
        line-height: 1.43;
        letter-spacing: 0.2px;
        border-radius: 5px;
        font-family: "Lato";
        font-style: normal;
        font-size: 16px;
        padding-left: 10px;
        width: 90px;
        background-color: #337a2e;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        // margin-top: 20px;
        margin-bottom: 15px;
        padding-left: 10px;
        border-radius: 6px;

        &[disabled],
        &:disabled,
        &.disabled {
            background-color: #c4c4c4;
            cursor: not-allowed;
        }   
    }
    .cutomizeLinkWrapper {
        .col {
            margin-bottom: 30px;
        }
        .custominputfield {
            min-width: 400px
        }
        .inputcard-group {
            padding-top: 15px !important;
        }
        .descriptionGroup {
            padding-top: 0px !important;
        }
        .descriptionField {
            height: 97px !important;
        }
        .previewUrlWrapper {
            height: 396px;
            overflow-y: auto;
            .previewHeading {
                margin-top: 17px;
            }
            .description {
                padding: 0px 15px 0px 15px;
                max-width: 100%;
                word-wrap: break-word;
            }
            .previewDescription {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                background-color: #FFF3EC;
                padding: 20px 0px 20px 0px;
                min-height: 339px;
                p {
                    margin-bottom: 0;
                }
            }
            .urlPreview {
                text-align: center;
                word-break: break-word;
                margin-top: 30px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                max-width: 300px;
                width: 100%;
                padding: 10px;
                border-radius: 5px;
                font-size: 20px;
                color: white;
                background-color:  #F57C00;
                ;
            }
            .disabledPreview {
                background-color: #c4c4c4;
                cursor: not-allowed;
            }
        }
        .nav-tabs {
            margin-top: 10px;
            margin-bottom: 20px;
            border-bottom: none;
            .nav-item {
                display: flex !important;
                justify-content: space-between !important;
                cursor: pointer;
                padding-right: 25px;
                .nav-link {
                    border: none !important;
                }
                .nav-link:hover {
                    border: none !important;
                }
                .modal-close {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
        .addNewUrl {
            background-color: #f7fbf8;
            color: #2c8c48;
            border: 1px solid #91c39f;
            width: 128px;
        }
        .disableAddUrl {
            color: grey;
            cursor: not-allowed;
        }
    }
    .delete-button {
        margin-right: 10px;
        background-color: #fd7e14;
    }
    .addSocialMediaWrapper {
        .selectedFieldsContainer {
            height: 350px;
            overflow-y: auto;
        }
        .mediaLinkField {
            min-width: 400px !important;
            display: flex;
            
        }
        .inputImageWrapper {
            min-width: 350px;
            position: relative;
            .socialMediaIcon {
                position: absolute;
                left: 10px;
                transform: translate(0px,17px);
                height: 25px;
                width: 25px;
                object-fit: fill;
            }
            .custominputfield {
                padding-left: 40px !important;
            }
            .Blog {
                height: 33px;
                width: 33px;
                left: 5px;
                transform: translate(0px,13px);
            }
            .Linkedin {
                height: 24px;
                width: 22px;
            }
            .Youtube {
                height: 32px;
                width: 30px;
                transform: translate(0px,14px);
                left: 5px;
            }
            .Instagram, .CustomUrl {
                height: 32px;
                width: 32px;
                transform: translate(0px,14px);
                left: 5px;
            }
           .Tiktok {
            height: 29px;
            width: 29px;
            left: 5px;
            transform: translate(0px,15px);
           }
           .Twitter {
            height: 32px;
            width: 31px;
            left: 4px;
            top: -4px;
           }
        }
        .deleteContainer {
            display: flex;
            justify-content: end;
            margin-left: 10px;
            min-width: 40px;
        }
        .selectSocialMedia {
            padding-top: 0px !important;
        }
        .inputcard-withlabel  {
            min-width: 320px !important;
        }
        .labelCotainer {
            display: flex;
            justify-content: center;
            align-items: center;
            .labelName {
                margin-bottom: 0px !important;
                padding-left: 15px !important;
                font-size: 17px;
            }
            .socialMediaIcon {
                height: 30px;
                width: 30px;
                object-fit: cover;
            }
        }
        
    }
    .displaySubscribersCountWrapper {
        .textWrapper {
            display: flex;
        }
        .countText {
            margin-top: 15px;
            margin-left: 44px;
            color: #fd7e14;
        }
        .countValue {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #8C6B00;
            border-radius: 50%;
            border: 1px solid #FFF0BF;
            background-color: #FFF0BF;
            margin-left: 11px;
        }
        .singleDigit {
            height: 35px;
            width: 35px;
            margin-top: 10px;
        }
        .threeDigit {
            height: 45px;
            width: 45px;
            margin-top: 6px;
        }
    }

    .listOfMessagesWrapper {
        .messageEmailDetails {
            background-color: #F4F4F4;
            display: flex;
            flex-direction: column;
            margin-bottom: 25px;
            padding: 10px 25px 10px 10px;
            .fromEmailText {
                font-weight: 550;
            }
        }
        .messagesWrapper {
            .messageText {
                word-wrap: break-word;
                word-break: break-all;
            }
            .applyMaxHeight {
                word-wrap: break-word;
                word-break: break-all;
                max-height: 49px;
                overflow-y: hidden;
            }
            .messageContent {
                min-height: 24px;
                overflow-y: auto;
            }
            margin: 25px 0px 25px 0px;
            padding: 0px 150px 0px 150px;
            display: flex;
            max-height: 370px;
            overflow-y: auto;
            flex-direction: column;
            .timeContainer {
                margin-top: -2px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 15px;
                .showDate {
                    margin-right: 5px;
                    color: #7A7A7A;
                }
                .deleteIcon {
                    height: 30px;
                    cursor: pointer;
                }
            }
            .senderDetails {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
            .showReadMoreWrapper {
                display: flex;
                justify-content: end;
                margin-right: 15px;
            }
            .showReadMoreButton {
                background: transparent;
                border: none;
                color:#0095E9;
                ;
            }
        }
    
    }
}
@media (max-width: 1290px) and (min-width: 997px) {
    .memories-wrapper {
        .memories-left-content-wrapper {
            width: 24%;
        }
        .memories-right-content-wrapper {
            width: 20%;
        }
        .memories-main-content-wrapper {
            max-width: 100%;
            width: 76%;
        }
    }
}

@media (max-width: 996px) and (min-width: 768px) {
    .memories-wrapper {
        .memories-left-content-wrapper {
            width: 24%;
        }
        .memories-right-content-wrapper {
            display: none;
        }
        .memories-main-content-wrapper {
            padding: 30px 45px;
            float: none;
            width: 76%;
            max-width: 100%;
            h2 {
                font-size: 22px;
                line-height: 26px;
            }
            .mobile-data-wrapper {
                background: $white;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
                padding: 20px;
                margin: 0 -45px;
                margin-bottom: 20px;
                display: block;
            }
            .memory-list-wrapper {
                .memory-card {
                    .memory-content {
                        .media-container {
                            .multi-container {
                                .small-view {
                                    .show-more-div {
                                        .mask-layer {
                                            span {
                                                font-size: 24px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) and (min-width: 320px) {
    .memories-wrapper {
        .memories-left-content-wrapper {
           width: 24%;
        }
        .memories-right-content-wrapper {
            display: none;
        }
        .memories-main-content-wrapper {
            padding: 0 15px 30px;
            float: none;
            width: 76%;
            max-width: 100%;
            h2 {
                font-size: 16px;
                line-height: 18px;
            }
            .memory-list-wrapper {
                .memory-card {
                    width: 100%;
                    .memory-card-header {
                        .memory-title {
                            font-size: 16px;
                            line-height: 18px;
                        }
                        .memory-icon {
                            img {
                                width: 20px;
                                height: 18px;
                            }
                        }
                        .memory-date {
                            font-size: 14px;
                            line-height: 16px;
                        }
                    }
                    .memory-content {
                        .media-container {
                            .dual-container {
                                .view-mobile {
                                    margin-top: 10px;
                                }
                            }
                            .multi-container {
                                .small-view {
                                    .show-more-div {
                                        .mask-layer {
                                            span {
                                                font-size: 16px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .memory-card-footer {
                        span {
                            font-size: 16px;
                            line-height: 18px;
                        }
                        .memory-postedBy {
                            font-size: 14px;
                            line-height: 16px;
                        }
                    }
                }
            }
            .story-wrapper {
                padding: 16px;
                min-height: auto;
                .profile-wrapper {
                    display: block;
                    .profile-picture-wrapper {
                        width: 100%;
                        img {
                            width: 75px;
                            height: 75px;
                            margin: 0 auto;
                            display: block;
                        }
                    }
                    .profile-thought-wrapper {
                        padding: 0;
                        margin: 0 auto;
                        span {
                            font-size: 12px;
                            line-height: 12px;
                        }
                    }
                }
                a {
                    font-size: 14px;
                    line-height: 16px;
                }
            }
            .audio-wrapper {
                .audio-file {
                    width: 100%;
                }
            }
            .mobile-data-wrapper {
                background: $white;
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
                padding: 20px;
                margin: 0 -15px;
                margin-bottom: 20px;
                display: block;
                .filter-options {
                    display: block;
                    color: #000000 !important;
                    font-family: "Lato";
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 12px;
                }
            }
        }
    }
}

.obituary-writer-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    background-color: #FFEBD7;
    width: 100%;
    min-height: 50px;
    margin: auto;
    margin-bottom: 3%;
    color: #212121;
    font-size: 16px;
    font-family: "Lato";
    padding: 0 20px;
}

.iframe-container{
    position: fixed;
    top: 4%;
    left: 2.5%;
    height: 93%;
    width: 95%;
    z-index: 199;
    border: none;
    border-radius: 2%;
    overflow: auto;
}

.overlay{
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(70, 69, 69, 0.5); /* Adjust the opacity as needed */
    backdrop-filter: blur(1px);
    z-index: 150;
}

.draft-popup{
    position: fixed;
    top: 25%;
    left: 20%;
    width: 60%;
    height: 50%;
    background-color: white;
    z-index: 299;
}

.draft-container{
    border: 1px solid rgba(0, 0, 0, 0.612);
    height: 95%;
    margin: 1%;
}

.draft-content{
    margin: 1%;
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 3%;
    height: 72%;
    overflow: auto;
    line-height: 1.7;
}

.draft-popup-buttons{
    display: flex;
    align-items: center;
    justify-content: space-around;

}

.btn-publish, .btn-publish:active, .btn-publish:hover{
    background-color: rgb(219, 212, 212);
    width: 20%;
}

.btn-copy, .btn-copy:active, .btn-copy:hover{
    background-color: rgb(104, 170, 104);
    color: white;
    width: 20%;
}

.cross-mark{
    cursor: pointer;
}

.popup-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 25px;
    margin-left: 5%;
    margin-right: 5%;
}


.writer-btn, 
.writer-btn:hover, 
.writer-btn:active{
        border-radius: "6px" !important;
        background-color: #F57C00 !important;
        color: #ffffff !important;
        font-size: 13px !important;
        text-transform: capitalize !important;
        font-weight: 600 !important;
        white-space: nowrap !important;
}

.draft-btn, .draft-btn:active, .draft-btn:hover{
    background-color: #0095E9 !important;
    color: #fff !important;
    font-size: 13px !important;
}

.warn-popup{
    position: fixed;
    top: 35%;
    left: 35%;
    width: 30%;
    height: 30%;
    z-index: 400;
    background-color: white;
}

