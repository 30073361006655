@import '../../../assets/styles/_color.scss';

.service-card {
  background-color: $white;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
  margin-bottom: 30px;
  border-radius: 4px;

  .service-card-body {
    position: relative;
    cursor: pointer;
    padding: 20px;

    .service-card-img {
      width: 100%;
    }

    .btn-customise {
      display: none !important;
      position: absolute;
      left: calc(50% - 75px);
      top: calc(50% - 28px);
    }

    &:hover {
      .btn-customise {
        display: flex !important;
      }
    }
  }

  .service-card-header {
    @include flex(flex-start, center);
    @include font($roboto, 700, 21px, $black);

    height: 58px;
    padding: 0 40px;
    position: relative;
    border-radius: 4px;
    background-color: #f7fbf8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 3px 10px 0 rgba(144, 137, 137, 0.18);
    -moz-box-shadow: 0 3px 10px 0 rgba(144, 137, 137, 0.18);
    -webkit-box-shadow: 0 3px 10px 0 rgba(144, 137, 137, 0.18);
  }
}
