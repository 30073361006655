.dashboard-wrapper{
    flex-wrap: wrap;
    padding:0px 10px;
    @include flex(flex-start, center);
    .dashboard-card{
        width: 33.33%;
        .dashboard-card-content{
            padding-left: 16px;
            background-color: $white;
            .dashboard-card-decedentinfo{
                padding: 16px 0;
                border-bottom: 1px solid rgba($theme-placeholder-text, 0.1);
                .dashboard-card-name{
                    color: $brand-primary;
                    margin: 0;
                    font-size: 24px;
                    line-height: 28px;
                    @extend .lato-bold;
                }
                .dashboard-card-keyvalue{
                    font-size: 16px;
                    margin: 0;
                    margin-top: 20px;
                    .dashboard-card-key {
                        color: #000;
                        opacity:0.7;
                        white-space: nowrap;
                    }
                    .dashboard-card-value {
                        color: $black;
                        font-weight:600;
                        padding-left: 5px;
                    }
                }
            }
            .dashboard-card-viewinfo{
                > a{
                    padding: 16px 0;
                    text-decoration: none;
                    span{
                        color: $brand-primary;
                        margin-right: 10px;
                        text-decoration: underline;
                    }
                    i{
                        color: $brand-primary;
                    }
                }
                .arrangements-button{
                    text-decoration: none;
                    display:flex;
                    justify-content: center;
                    align-items: center;
                    border:none;
                    border-radius:4px;
                    background-color: #298a45;
                    color:#fff;
                    font-size:14px;
                    width:200px;
                    height:35px;
                    padding: 6px 10px;
                }
            }
            .card-bottom{
                border-bottom:none;
                .card-heading{
                    font-size:20px;
                    cursor:pointer;
                    color:#000;
                    &:hover{
                        color:#298a45;
                    }
                }
                .card-keyvalue{
                    display:flex;
                    justify-content: space-between;
                }
            }
        }
        .card-content{
            margin:0px 15px;
            margin-top:20px;
            margin-bottom:10px;
            padding-bottom:20px;
            padding-right:25px;
            border-radius:4px;
            box-shadow:0px 0px 5px rgba(0,0,0,0.15);
            &:hover{
                cursor:pointer;
            }
        }
    }  
}
.home-wrapper{
    border-radius:8px;
    background-color:#fff;
    box-shadow:0px 0px 5px rgba(0,0,0,0.15);
    padding-bottom:20px;
    margin-top:30px;
    .dashboard-header-family{
        background-color:#f7fbf8;
        border-radius:8px 8px 0px 0px;
        display:flex;
        justify-content: space-between;
        align-items:center;
        padding: 10px 20px;
        margin-bottom:10px;
        .dashboard-header{
            display:flex;
            align-items:center;
            .home-heading{
                font-family:"Lato";
                font-size:20px;
                font-weight:600;
            }
        }
    }
}

@media (max-width: 991px) and (min-width: 768px){
    .dashboard-wrapper{
        .dashboard-card{
            width: 50%;
            
        }
    }
}

@media (max-width: 767px) and (min-width: 320px){
    .dashboard-wrapper{
        .dashboard-card{
            width: 100%;
            .dashboard-card-content{
                padding-left: 12px;
                .dashboard-card-decedentinfo{
                    padding: 12px 0;
                    .dashboard-card-name{
                        font-size: 16px;
                        line-height: 19px;
                    }
                    .dashboard-card-keyvalue{
                        font-size: 14px;
                        margin-top: 14px;
                        line-height: 17px;
                    }
                }
                .dashboard-card-viewinfo{
                    > a{
                        padding: 12px 0;
                        span{
                            font-size: 14px;
                            line-height: 17px;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
}