.login-modal {
    font-family: "Lato";
    .modal-body {
        margin-top: 20px;
        .close-modal {
            display: flex;
            justify-content: end;
            height: 30px;
            cursor:pointer;
        }
        .login-heading {
            color: black;
            font-weight: bold;
            font-size: 26px;
            line-height: 1.5;
            margin-bottom: 10px;
        }
        .login-text {
            color: rgba(0, 0, 0, 0.6);
            font-size: 18px;
            font-weight: bold;
            line-height: 1.5;
            margin-bottom: 40px;
        }
    }
    .login-form {
        margin: 0px 25px;
        margin-bottom: 50px;
        .form-label {
            color: #000;
            font-size: 16px;
            line-height: 1.5;
            font-weight: 500;
        }
        .form-input {
            padding: 20px;
        }
        .icon-text {
            background-color: transparent;
        }
        .error-msg {
            color: #ff0000;
            font-size: 14px;
        }
        .forgot-password {
            font-size: 16px;
            line-height: 2.5;
            color: #46a6ff;
            border: none;
            background-color: transparent;
        }
        .checkbox-label {
            margin: 0;
            margin-left: 22px;
            margin-bottom: 10px;
        }
        .signin-button {
            width: 100%;
            background-color: #298a45;
            height: 45px;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            cursor: pointer;
            border: 0;
            padding: 15px 10px;
            font-size: 10px;
            line-height: 1.43;
            letter-spacing: 0.2px;

            font-style: normal;
            font-size: 14px;
            border-radius: 5px;
        }
    }
}
