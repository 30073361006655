.react-datepicker-wrapper {
    width: 100%;
    .react-datepicker__input-container {
        width: 100%;
        .datepicker-field {
            cursor: pointer;
            &[disabled] {
                color: $theme-placeholder-text;
                cursor: not-allowed;
                background-color: #f2f2f2;
            }
        }
        &:after {
            display: none;
        }
    }
    + .react-datepicker-popper {
        .react-datepicker {
            border: none;
            max-height: 350px;
            @extend .d-flex;
            @include box-shadow(0 3px 13px rgba(0, 0, 0, 0.08));
            .react-datepicker__triangle {
                &::before {
                    border-top-color: transparent;
                    border-bottom-color: transparent;
                }
            }
            .react-datepicker__navigation {
                &.react-datepicker__navigation--previous {
                }
                &.react-datepicker__navigation--next {
                }
            }
            .react-datepicker__month-container {
                .react-datepicker__header {
                    border-bottom: 0;
                    background-color: transparent;
                    .react-datepicker__current-month {
                        display: none;
                    }
                    .react-datepicker__header__dropdown {
                        .react-datepicker__month-dropdown-container {
                            .react-datepicker__month-select {
                                color: $date-header-color;
                                cursor: pointer;
                                font-size: 15px;
                                @extend .lato-regular;
                            }
                        }
                        .react-datepicker__year-dropdown-container {
                            .react-datepicker__year-select {
                                color: $date-header-color;
                                cursor: pointer;
                                font-size: 15px;
                                @extend .lato-regular;
                            }
                                               
                        }
                    }
                    .react-datepicker__day-names {
                        .react-datepicker__day-name {
                            color: $date-header-color;
                            font-size: 15px;
                            width: 39px;
                            height: auto;
                            padding: 15px 0 0;
                            line-height: 14px;
                            @extend .lato-regular;
                        }
                    }
                }
                .react-datepicker__month {
                    .react-datepicker__week {
                        .react-datepicker__day {
                            width: 39px;
                            color: $dates-color;
                            height: 39px;
                            font-size: 14.5px;
                            line-height: 39px;
                            @extend .lato-bold;
                            @include border-radius(50%);
                            &:hover {
                                color: $white;
                                background-color: $brand-primary;
                            }
                            &.react-datepicker__day--keyboard-selected {
                                color: $white;
                                background-color: $brand-primary;
                            }
                            &.react-datepicker__day--selected {
                                color: $white;
                                background-color: $brand-primary;
                            }
                            &.react-datepicker__day--outside-month {
                                color: $pervious-dates;
                            }
                            &.react-datepicker__day--disabled {
                                cursor: not-allowed;
                                opacity: 0.4;
                            }
                        }
                    }
                }
            }
        }
    }
}

.daterange-container{
    position: relative;
    input{
        width: 100%;
        border: 0;
        font-size: 13px;
        padding-right: 15px;
        @include placeholder-color($primary-text-color);
    }
    &::after{
        top: 50%;
        right: 16px;
        color: $theme-placeholder-text;
        cursor: pointer;
        content: "\f133";
        position: absolute;
        font-size: 16px;
        font-weight: 900;
        pointer-events: none;
        @extend .custom-fontawesome;
        @include translate(0, -50%);
    }
}
.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  visibility: visible !important;
}
.react-datepicker__year-dropdown {
    top: 60px !important;
}
.react-datepicker__month-dropdown {
    top: 60px !important;
}

