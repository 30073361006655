@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

.dashboards-card {
    width: auto;
    max-width: 300px;
    border-radius: 10px;
    margin-top: -100px;
    .dashboard-card-content {
        background-color: #fff;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 2px 4px 3px 3px $shadow-color;
        .profile-picture-wrapper {
            width: 100%;
            padding: 10px;
            border-radius: 10px;
            img {
                width: 220px;
                height: auto;
                // object-fit: contain;
                // min-width: 100%;
                border-radius: 10px;
            }
            .story-wrapper {
                .mask {
                    .profile-picture-wrapper {
                        .upload-button {
                            height: 35px;
                            width: 220px;
                            margin-top: 10px;
                            background-color: transparent;
                            color: #337a2e;
                            border: 1px solid #337a2e;
                            border-radius: 6px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-transform: uppercase;
                            cursor: pointer;
                            padding: 15px 10px;
                            line-height: 1.43;
                            letter-spacing: 0.2px;
                            font-family: "Lato";
                            font-style: normal;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        .dashboard-card-decedentinfo {
            background-color: #F4FAF7;
            padding: 16px 0px 0px 0px;
            .dashboard-card-name {
                color: $brand-primary;
                margin: 0;
                font-size: 24px;
                line-height: 28px;
                @extend .lato-bold;
            }
            .dashboard-card-keyvalue {
                font-size: 16px;
                margin: 0;
                margin-bottom: 20px;
                position: relative;
                height: auto;
                display: flex;
                .dashboard-card-key {
                    color: $theme-text;
                    white-space: nowrap;
                    font-family: "Lato";
                    font-style: normal;
                    font-weight: bold;
                    font-size: 16px;
                    padding-left: 10px;
                }
                .dashboard-card-value {
                    color: $theme-text;
                    display: flex;
                    white-space: pre-wrap;
                    font-size: 15px;
                    font-weight: 500;
                    font-family: "Lato";
                    font-style: normal;
                    align-items: flex-start;
                    padding-left: 16px;
                }
            }
            .nameValue,.birthDeathDetails {
                margin-bottom: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                .displayNameSpan {
                    font-weight: bold;
                    font-size: 20px;
                    font-family: 'Montserrat', 'Lato';
                    text-align: center;
                }
                .dobSpan {
                    text-align: center;
                    font-size: 14px;
                    font-family: 'Montserrat', 'Lato';
                }
            }
        }
        .dashboard-card-viewinfo {
            padding:0px 0px 16px 0px;
            border-bottom: 1px solid rgba($theme-placeholder-text, 0.1);
            margin-bottom: 20px;
            background: #F3FAFF;
            .dashboard-card-key-value {
                > a{
                    color: #0368B0;
                }
                .heading {
                    font-size: 20px;
                    font-weight: 600;
                    font-family: "Lato";
                    font-style: normal;
                    line-height: 24px;
                }
                padding-top: 20px;
                padding-left: 10px;
                .locateGrave {
                    color: #F57C00 !important;
                    text-decoration: underline;
                    .rightArrow {
                        margin-left: 10px;
                    }
                }
                .options-wrapper {
                    padding-left: 30px;
                    position: relative;
                    color: #2e2e2e;
                    font-family: "Lato";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    cursor: pointer;
                    line-height: 18px;
                    .icon-wrapper {
                        position: absolute;
                        top: 0;
                        left: 0px;
                        height: 19px;
                    }
                    &.long-text-wrapper {
                        display: inline-flex;
                    }
                    .MuiSwitch-colorSecondary.Mui-checked {
                        color: black;
                    }
                    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
                        background-color: black;
                    }
                }
            }
        }
        .dashboard-card-shareInfo {
            .dashboard-card-key-value {
                margin-bottom: 10px;
                display: flex;
                padding: 10px 10px;
                background-color: white;
                .heading {
                    font-size: 14px;
                    // font-weight: 600;
                    font-family: "Lato";
                    font-style: normal;
                    line-height: 24px;
                }
                .shareText {
                    font-weight: 400 !important;
                }
            }
            .dashboard-card-share-value {
                padding-left: 10px;
                .text {
                    padding-left: 0px;
                }
                .share-icon-wrapper {
                    padding-right: 4px;
                    width: 25px;
                    height: 25px;
                }
            }
            .dashboard-formcontrol-label {
                margin: 0px;
                padding: 10px 0px 10px 10px;
                margin-bottom: 20px;
                background-color: #fffdee;
            }
            .dashboard-login-button {
                height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: uppercase;
                cursor: pointer;
                border: 0;
                border-radius: 4px;
                padding: 0px 0px 0px 10px;
                font-size: 10px;
                line-height: 1.43;
                letter-spacing: 0.2px;
                font-family: "Lato";
                font-style: normal;
                font-size: 14px;
                text-decoration: underline;
            }
            .blueArrow {
                margin-left: 10px;
            }
            .login-button {
                color:  #0368B0;
                background-color: transparent;
                // border: 1px solid #298a45;
                // &:hover {
                //     color: #fff;
                //     background-color: #298a45;
                // }
            }
            .logout-button {
                background-color: transparent;
                // border: 1px solid #ff0000;
                color: #0368B0;
            }
        }
        .dashboard-card-button-value {
            padding-left: 10px;
            width: 100%;
            background-color: #298a45;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
            padding-left: 10px;
            border-radius: 4px;
            cursor: pointer;
            .send-button {
                text-transform: uppercase;
                color: #fff;
                cursor: pointer;
                border: 0;
                padding: 15px 10px;
                font-size: 10px;
                line-height: 1.43;
                letter-spacing: 0.2px;
                border-radius: 5px;
                font-family: "Lato";
                font-style: normal;
                font-size: 14px;
            }
        }
        .dashboard-card-customUrlInfo {
            background-color: #FFF3EC;
            border-bottom: 1px solid rgba($theme-placeholder-text, 0.1);
            display: flex;
            margin-bottom: 20px;
            flex-direction: column;
            .previewHeading {
                padding: 10px 10px 0px 10px;
                font-weight: bold;
                font-size: 18px;
                margin-bottom: 3px !important;
                cursor: pointer;
            }
            .description {
                max-width: 100%;
                font-size: 14px;
                word-wrap: break-word;
                margin-bottom: 15px !important;
             
            }
            .previewDescription {
               padding: 5px 10px 10px 10px;
            }
            .urlPreview {
                text-align: center;
                word-break: break-word;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                max-width: 300px;
                width: 100%;
                padding: 5px;
                border-radius: 5px;
                font-size: 18px;
                color: #F57C00;
                border: 1px solid #F57C00;
                margin-bottom: 15px;
            }
        }
        .dashboard-card-socialMediaProfileInfo {
            padding: 16px 0px 0px 10px;
            margin-bottom: 20px;
            border-bottom: 1px solid rgba($theme-placeholder-text, 0.1);
            background-color: #F4FAF7;
            .iconsWrapper {
                display: flex;
                flex-wrap: wrap;
                padding-bottom: 10px;
            }
            .relateProfileHeading {
                font-weight: bold;
            }
            .socialMediaProfileImage {
                height: 29px;
                width: 29px;
                margin-left: 7px;
                object-fit: fill;
            }
            .Instagram {
                height: 41px;
                width: 41px;
                margin-top: -4px;
                object-fit: fill;
            }
            .Youtube {
                height: 40px;
                width: 40px;
                margin-top: -1px;

            }
            .Blog {
                height: 36px;
                width: 36px;
                margin-top: -1px;
            }
            .Tiktok {
              height: 35px;
              width: 35px;
              object-fit: fill;
              margin-top: -1px;
            }
            .CustomUrl {
                height: 35px;
                width: 35px;
            }
            .Twitter {
                height: 40px;
                width: 40px;
                margin-top: -4px;
                margin-left: 0px;

            }
        }
        .dashboard-card-sendFlowers {
            padding: 16px 10px 10px 10px;
            margin-bottom: 20px;
            border-bottom: 1px solid rgba($theme-placeholder-text, 0.1);
            background-color: #F5F4FF;
            .row {
                margin-left: 0px;
                margin-right: 0px;
                flex-wrap: nowrap;
            }
            .sendFlowersWrapper {
                align-items: center;
            }
            .flowerWrapper {
                padding-left: 0px !important;
                .flowerImage {
                    height: 65px;
                    width: 65px;
                    object-fit: contain;
                }
            }
            .flowerHeadingCol {
                padding-right: 0px;
            }
            .sendFlowersHeading {
                justify-content: space-between;
                align-items: center;
                .rightArrow {
                    height: 20px;
                    width: 20px;
                    object-fit: contain;
                    margin-left: 20px;
                }
                .sendFlowersText {
                    color: #7D7BEC;
                    border: none;
                    background: transparent;
                }
            }
            p {
                margin-bottom: 0px !important;
            }
            .message {
                font-size: 14px;
            }
            .flowerHeadingCol,.flowerWrapper {
                width: auto;
            }
        }
        .dashboard-card-subscribeDecedent {
            margin-bottom: 20px;
            .g-recaptcha{
                margin-top: 20px;
                transform:scale(0.77);
                -webkit-transform:scale(0.79);
                transform-origin:0 0;
                -webkit-transform-origin:0 0;
                 height: 64px !important;
                }
            background-color: #EBF2EB;
            margin-top: 16px;
            padding: 16px 10px 10px 10px;
            border-bottom: 1px solid rgba($theme-placeholder-text, 0.1);
            .headingSection {
                display: flex;
                justify-content: space-between;
                .stayUpdated {
                    font-weight: bold;
                    font-size: 18px;
                    margin-bottom: 5px !important;
                }
            }
            .reminderHeading {
                font-size: 15px !important;
                margin-bottom: 5px !important;
            }
            .subscribeDecedentFormGroup {
                padding-top: 0px !important;
                .custominputfield {
                    // width: 240px;
                    height: 40px !important;
                }
                .choose-reason {
                    padding: 0px;
                    width: auto;
                }
            }
            .subscribeButton {
                padding-left: 0px !important;
            }
            .save-button {
                width: 100%;
                background: transparent;
            }
            .disbledSubscribeButton {
                background: transparent;
                background-color: #c4c4c4;
                .disableSave {
                    cursor: not-allowed;
                }
            }
        }
        .dashboard-card-contactFamilyMember {
            margin-bottom: 20px;
            .g-recaptcha{
                margin-top: 18px;
                transform:scale(0.79);
                -webkit-transform:scale(0.79);
                transform-origin:0 0;
                -webkit-transform-origin:0 0;
                height: 64px !important;
                }
            padding: 0px 10px 0px 10px;
            background-color: #F3FAFF;
            border-bottom: 1px solid rgba(151, 151, 151, 0.1);
            .inputcard-group {
                padding-top: 10px !important;
                .choose-reason {
                    padding: 0px !important;
                    width: auto;
                }
            }
            .heading {
                margin-bottom: 10px !important;
                cursor: pointer;
            }
            .custominputfield {
                // width: 240px !important;
                height: 40px !important;
            }
            .message {
                height: 100px !important;
            }
            .send-message {
                width: 240px;
                height: 40px;
                border-radius: 5px;
                color: green;
                border: 1px solid green;
                text-transform: uppercase;
                background: transparent;
            }
            .disabledSendMessage {
                cursor: not-allowed;
                background-color: #c4c4c4;
                background: transparent;
                color: grey;
            }
            .hideConatctFamily {
                margin-top: -3px;
                margin-left: 12px;
            }
            .MuiSwitch-root {
                width: 56px;
                height: 37px;
                .MuiSwitch-thumb {
                    height: 18px;
                    width: 18px;
                }
            }
            .MuiFormControlLabel-label {
                font-size: 15px;
                font-weight: 400 !important;
            }
        }
        .goBackToFaaButton {
            display: flex;
            justify-content: space-between;
            padding: 10px 0px 10px 9px;
            margin-bottom: 20px;
            font-family: 'Lato';
            font-size: 16px;
            background-color: #F1F9FF;
            > a {
                text-decoration: underline !important;
                color: #0368B0;
            }
            #faaRedirectToolTip {
                padding-right: 7px;
            }
        }
        .error-captcha-div {
            padding-bottom: 5px;
            .error-captcha-msg {
                color: red;
                padding-bottom: 3px;
            }
        }
        .collapseIcon {
            display: flex;
            justify-content: flex-end;
            padding-top: 8px;
            cursor: pointer;
        }
        .donationCollapse {
            align-items: center;
            padding-right: 10px;
        }
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    .dashboards-wrapper {
        .dashboard-card {
            width: 50%;
        }
    }
}

@media (max-width: 767px) and (min-width: 320px) {
    .dashboards-wrapper {
        .dashboard-card {
            width: 20%;
            .dashboard-card-content {
                padding-left: 12px;
                .dashboard-card-decedentinfo {
                    padding: 12px 0;
                    .dashboard-card-name {
                        font-size: 16px;
                        line-height: 19px;
                    }
                    .dashboard-card-keyvalue {
                        font-size: 14px;
                        margin-top: 14px;
                        line-height: 17px;
                    }
                }
                .dashboard-card-viewinfo {
                    > a {
                        padding: 12px 0;
                        span {
                            font-size: 14px;
                            line-height: 17px;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
    .dashboard-card-customUrlInfo {
        .urlPreview {
            font-size: 16px !important;
        }
    }
    .dashboard-card-subscribeDecedent, .dashboard-card-contactFamilyMember {
        .custominputfield {
            font-size: 14px !important;
        }
    }
}
.space-tooltip-share-icon {
    margin-right: 10px;
    cursor: pointer;
}

.tooltip-image-template {
    .tooltip {
        .arrow {
            left: 10px !important;
        }
    }
}
