/* ==============================================================================
******************   Font Styles For the Application   *************************
============================================================================== */

// @font-face {
//     font-family: 'Lato-Black';
//     src: url('../fonts/Lato-Black/Lato-Black.eot');
//     src: url('../fonts/Lato-Black/Lato-Black.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/Lato-Black/Lato-Black.woff') format('woff'),
//         url('../fonts/Lato-Black/Lato-Black.ttf')  format('truetype'),
//         url('../fonts/Lato-Black/Lato-Black.svg#svgFontName') format('svg');
// }
// @font-face {
//     font-family: 'Lato-Bold';
//     src: url('../fonts/Lato-Bold/Lato-Bold.eot');
//     src: url('../fonts/Lato-Bold/Lato-Bold.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/Lato-Bold/Lato-Bold.woff') format('woff'),
//         url('../fonts/Lato-Bold/Lato-Bold.ttf')  format('truetype'),
//         url('../fonts/Lato-Bold/Lato-Bold.svg#svgFontName') format('svg');
// }
// @font-face {
//     font-family: 'Lato-Light';
//     src: url('../fonts/Lato-Light/Lato-Light.eot');
//     src: url('../fonts/Lato-Light/Lato-Light.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/Lato-Light/Lato-Light.woff') format('woff'),
//         url('../fonts/Lato-Light/Lato-Light.ttf')  format('truetype'),
//         url('../fonts/Lato-Light/Lato-Light.svg#svgFontName') format('svg');
// }
// @font-face {
//     font-family: 'Lato-Regular';
//     src: url('assets/fonts/Lato-Regular.eot');
//     src: url('assets/fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
//         url('assets/fonts/Lato-Regular.woff') format('woff'),
//         url('assets/fonts/Lato-Regular.ttf')  format('truetype'),
//         url('assets/fonts/Lato-Regular.svg#svgFontName') format('svg');
// }
// @font-face {
//     font-family: 'RobotoSlab-Bold';
//     src: url('../fonts/RobotoSlab-Bold/RobotoSlab-Bold.eot');
//     src: url('../fonts/RobotoSlab-Bold/RobotoSlab-Bold.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/RobotoSlab-Bold/RobotoSlab-Bold.woff') format('woff'),
//         url('../fonts/RobotoSlab-Bold/RobotoSlab-Bold.ttf')  format('truetype'),
//         url('../fonts/RobotoSlab-Bold/RobotoSlab-Bold.svg#svgFontName') format('svg');
// }
// @font-face {
//     font-family: 'RobotoSlab-Light';
//     src: url('../fonts/RobotoSlab-Light/RobotoSlab-Light.eot');
//     src: url('../fonts/RobotoSlab-Light/RobotoSlab-Light.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/RobotoSlab-Light/RobotoSlab-Light.woff') format('woff'),
//         url('../fonts/RobotoSlab-Light/RobotoSlab-Light.ttf')  format('truetype'),
//         url('../fonts/RobotoSlab-Light/RobotoSlab-Light.svg#svgFontName') format('svg');
// }
// @font-face {
//     font-family: 'RobotoSlab-Regular';
//     src: url('../fonts/RobotoSlab-Regular/RobotoSlab-Regular.eot');
//     src: url('../fonts/RobotoSlab-Regular/RobotoSlab-Regular.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/RobotoSlab-Regular/RobotoSlab-Regular.woff') format('woff'),
//         url('../fonts/RobotoSlab-Regular/RobotoSlab-Regular.ttf')  format('truetype'),
//         url('../fonts/RobotoSlab-Regular/RobotoSlab-Regular.svg#svgFontName') format('svg');
// }
// @font-face {
//     font-family: 'RobotoSlab-Thin';
//     src: url('../fonts/RobotoSlab-Thin/RobotoSlab-Thin.eot');
//     src: url('../fonts/RobotoSlab-Thin/RobotoSlab-Thin.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/RobotoSlab-Thin/RobotoSlab-Thin.woff') format('woff'),
//         url('../fonts/RobotoSlab-Thin/RobotoSlab-Thin.ttf')  format('truetype'),
//         url('../fonts/RobotoSlab-Thin/RobotoSlab-Thin.svg#svgFontName') format('svg');

// }
// $rsthin         : 'RobotoSlab-Thin';
// $rsregular      : 'RobotoSlab-Regular';
// $rslight        : 'RobotoSlab-Light';
// $rsbold         : 'RobotoSlab-Bold';
// $lbold          : 'Lato-Bold';
// $lblack         : 'Lato-Black';
// $llight         : 'Lato-Light';

$lregular: 'Lato-Regular';

@import url('https://fonts.googleapis.com/css?family=Lato:300,400,500,700,900|Roboto+Slab:100,300,400,700|Playball');

// Roboto
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');

// Raleway
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// @import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;700;900&display=swap');

// Martel
@import url('https://fonts.googleapis.com/css2?family=Martel:wght@300;400;600;700;800;900&display=swap');

// Playball
@import url('https://fonts.googleapis.com/css2?family=Playball&display=swap');

// cinzel
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&display=swap');

// Dancing
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');

$robotoslab: 'Roboto Slab', serif;
$raleway: 'Raleway', sans-serif;

$lato: 'Lato', sans-serif;
$roboto: 'Roboto', sans-serif;

// $playball: 'Raleway', sans-serif;
// $cinzel: 'Roboto Slab', serif;

$playball: 'Playball', cursive;
$cinzel: 'Cinzel', serif;
$martel: 'Martel', serif;
$dancing: 'Dancing Script', cursive;
