.modal-dialog {
    &.AlertMessage-wrapper {
        width: 100%;
        max-width: 550px;
        .modal-content {
            .modal-header {
                .modal-title {
                    padding: 0;
                    background-color: transparent;
                    .modal-close {
                    }
                    .modal-headername {
                        font-size: 18px;
                        margin-bottom: 15px;
                    }
                    .modal-memory-headername {
                        font-size: 20px;
                        font-weight:600;
                    }
                }
            }

            .modal-body {
                color: $grey;
                padding: 10px 30px 0 !important;
                font-size: 18px;
                @extend .lato-regular;
                .notes-textarea {
                    width: 100%;
                    height: 150px;
                    padding: 24px;
                    border: 1px solid $theme-placeholder-text;
                    border-radius: 5px;
                }
                .heading {
                    color: #212529;
                    margin-top: 20px;
                    margin-bottom: 0px;
                    font-size: 18px;
                }
                .error-input-msg {
                    color: red;
                    display: block;
                    overflow: hidden;
                    // position: absolute;
                    font-size: 14px;
                    line-height: 16px;
                    white-space: nowrap;
                }
                .modal-label {
                    font-size: 14px;
                    line-height: 19px;
                    margin-top: 10px;
                }
            }
            .modal-footer {
                display: flex;
                align-items: flex-end;
                .btn-cancel {
                    color: $brand-primary;
                    background-color: transparent !important;
                }
                .btn-decline {
                    background-color: #eb5757;
                    margin-right: 1rem;
                }
                .btn-cancel-admin {
                    background-color: transparent !important;
                    color: black;
                }
                button {
                    &:not(:first-child) {
                        margin-left: 28px;
                    }
                }
            }
        }
    }
}
