@import './print';
@import './editor';

.prayer-card-body-wrapper {
  .prayer-card-creation-steps {
    padding: 18px 14px 38px;

    .buttons-container {
      margin-top: 20px;
    }

    .action-btn-link {
      font-size: 17px;
      line-height: 20px;
      align-items: center;
      text-align: center;
      text-decoration-line: underline;
      text-transform: capitalize;
      cursor: pointer;
      color: #298a45;
      background-color: transparent;
      border: none;
    }
    li {
      @include font($roboto, 400, 20px, #000000);
      list-style: none;
      margin-bottom: 25px;
      img {
        padding-left: 5px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .prayer-cards-list {
    display: flex;
    flex-wrap: wrap;
    .prayer-card {
      margin-bottom: 50px;
      padding: 0 18px;
      @media (max-width: 767px) {
        margin-bottom: 30px;
      }
      .prayer-card-template-img-container {
        cursor: pointer;
        position: relative;
        width: 240px;
        height: 210px;
        img {
          border-radius: 2px;
          box-shadow: 0px 10px 30px rgba($black, 0.05);
          max-width: 100%;
          height: 100%;
        }
        .link-customise {
          @include center(both);
          min-width: 135px;
          min-height: 45px;
          padding: 5px;
          visibility: hidden;
          z-index: 2;
        }
        &::after {
          position: absolute;
          content: '';
          width: 100%;
          height: 100%;
          background: rgba($black, 0.5);
          left: 0;
          top: 0;
          display: none;
        }
        &:hover {
          &::after {
            display: block;
          }
          .link-customise {
            visibility: visible;
          }
        }
      }
      .prayer-card-template-type {
        @include flex(flex-start, center);
        flex-grow: 1;
        @include font($roboto, 400, 16px, $black);
        box-shadow: 0px 10px 30px rgba($black, 0.05);
        border-radius: 2px;
        min-height: 50px;
        background-color: $white;
        padding: 5px 15px;
        margin-bottom: 0;
        @media (max-width: 767px) {
          min-height: 40px;
        }
      }
      .custom-template-text {
        font-size: 16px;
        margin: 0;
      }
      &.prayer-card-upload-img {
        .prayer-card-template-img-container {
          background-color: $grey4;
          .link-customise {
            visibility: visible;
            min-height: 0;
            min-width: 0;
            color: $white !important;
            padding: 0;
            border: none;
            font-size: 20px;
          }
          &:hover {
            &:after {
              display: none;
            }
          }
        }
      }
    }
    .DN {
      display: none;
    }
  }
}

.prayer-custom-modal {
  .prayer-custom-header {
    padding: 0px !important;
    background-color: white !important;
  }
  .custom-info-button {
    position: absolute;
    top: 15px;
    right: 14px;
    cursor: pointer;
    font-size: 62px;
    color: #212529;
  }
}

// .prayer-custom-wrapper {
//     max-height: 80vh !important;
//     overflow-y: auto !important;
//     padding: 0 !important;
//     &::-webkit-scrollbar {
//         width:7px;
//         border-radius: 5px;
//     }
//     &::-webkit-scrollbar-track {
//         @include box-shadow(inset 0 0 4px rgba(0, 0, 0, 0.3));
//         border-radius: 5px;
//     }
//     &::-webkit-scrollbar-thumb {
//         background-color: darkgrey;
//         outline: 1px solid slategrey;
//         border-radius: 5px;
//     }
// }

.revamp-prayer-card {
  .prayer-card-creation-steps {
    padding: 18px 18px 38px 18px;

    .buttons-container {
      margin-top: 20px;
    }

    .action-btn-link {
      font-size: 17px;
      line-height: 20px;
      margin-left: 14px;
      align-items: center;
      text-align: center;
      text-decoration-line: underline;
      text-transform: capitalize;
      cursor: pointer;
      color: #298a45;
      background-color: transparent;
      border: none;
      &[disabled],
      &:disabled,
      &.disabled {
        cursor: not-allowed;
      }
    }
    li {
      @include font($roboto, 400, 20px, #000000);
      list-style: none;
      margin-bottom: 25px;
      img {
        padding-left: 5px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .locked-text {
    text-align: center;
    color: red;
    font-size: 16px;
  }
  .prayer-card-template-wrapper {
    border: 1px solid white;
    background: white;
    padding: 40px 40px 40px 20px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
    border-radius: 20px;

    margin-right: 0px;
    margin-left: 0px;
    div[class^='col'],
    div[class*=' col'] {
      padding-right: 0px;
      padding-left: 0px;
    }

    .cardbox-heading {
      min-height: 50px;
      text-align: center;
      padding: 0 30px 30px 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .cardbox-heading-button {
        max-width: 125px;
        margin: 0px 25px;
        cursor: pointer;
      }

      .cardbox-heading-name {
        padding-top: 0.8rem;
        @include font($roboto, 500, 20px, $black);
      }
      @media (max-width: 1023px) {
        min-height: 45px;
        .cardbox-heading-name {
          font-size: 18px;
        }
      }
    }
    .edit-disable-btn {
      cursor: not-allowed;
      background-color: white !important;
      color: #2e7d32 !important;
    }
  }
  .prayer-card-menu-wrapper {
    display: flex;
    justify-content: center;

    .menu-navigation {
      justify-content: center;
      align-content: baseline;
      // background-color: white;

      &.nav-tabs {
        border-bottom: none;
      }
      .nav-item {
        height: fit-content;
        margin-bottom: 10px;
        .nav-link {
          color: unset;
          height: 100%;
          padding: 0;

          &.active {
            border: 3px solid #298a45;
          }

          .template-card {
            .card-nav {
              max-height: 100%;
              max-width: 100%;
              overflow: hidden;
              padding: 0;
              .prayer-card-image {
                min-height: 200%;
                min-width: 400%;
              }
              .prayer-card-content {
                height: auto;
                overflow: visible;
              }
            }
            .custom-card-nav {
              padding: 0;
              display: flex;
              justify-content: center;
              //height: 100%;
              width: 100%;
              align-items: center;
              img {
                max-height: 100%;
              }
              .prayer-card-content {
                height: auto;
                overflow: visible;
              }
            }
            .custom-content-nav {
              max-height: 100%;
              max-width: 100%;
              padding: 0;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
  .prayer-card-editor-wrapper {
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 0;
    .tab-content {
      background: #ffffff;
      box-shadow: 0px 5.2px 15.6px rgba(0, 0, 0, 0.2);
      border-radius: 5px;
    }
    .template-screen-edit {
      .text-editor {
        position: relative;
        max-height: 414px;
        .ql-toolbar {
          position: absolute;
          width: max-content;
          top: -41px;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
          z-index: 5;
          left: -2.5%;
          background: white;
          .ql-font,
          .ql-size {
            border-right: 1px solid rgba(0, 0, 0, 0.3);
            padding-left: 1rem;
            padding-right: 1rem;
            width: 155px;
          }
          .ql-bold,
          .ql-italic,
          .ql-underline {
            width: 32px;
          }
          .ql-underline {
            border-right: 1px solid rgba(0, 0, 0, 0.3);
          }
          .ql-bold,
          .ql-align {
            margin-left: 0.5rem;
          }
          div {
            max-height: 420px;
          }
        }
        .quill {
          position: relative;
          .ql-container {
            border: none;
            .ql-editor {
              // max-height: 420px;
              overflow-y: visible;
              height: 734px;
              padding: 0 0.5rem;
              background-color: #fff;
              border: 1px solid #80bdff;
              @include box-shadow(1px 0px 5px 0 #80bdff);
              border-radius: 5px;
              .content {
                line-height: 1;
                >span {
                  line-height: 1;
                }
              }
              .footerLocation, .cityName {
                line-height:1 ;
                >span {
                  line-height: 1;
                }
              }
              .ql-custom-newLine {
                line-height: 1.42;
              }
            }
          }
        }
      }
      .template-string-wrapper {
        .hover-figure {
          display: inline-block;
          overflow: hidden;
          position: relative;
          //text-align: center;
          width: 100%;
          margin-bottom: 5px;
          &:before,
          &:after {
            background-color: rgba(255, 255, 255, 0.5);
            border-top: 2px solid rgba(0, 0, 0, 0.3);
            border-bottom: 2px solid rgba(0, 0, 0, 0.3);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            content: '';
            transition: all 0.3s ease;
            z-index: 1;
            opacity: 0;
            transform: scaleY(2);
          }
          .template-string {
            backface-visibility: hidden;
            word-break: break-word;
            p {
              margin-bottom: 0;
            }
            .content {
              line-height: 1;
              >span {
                line-height: 1;
              }
            }
            .footerLocation, .cityName {
              line-height:1 ;
              >span {
                line-height: 1;
              }
            }
           .ql-custom-newLine {
              line-height: 1.42;
            }
          }
          figcaption {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            align-items: center;
            z-index: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            line-height: 1.1em;
            opacity: 0;
            z-index: 2;
            transition-delay: 0.1s;
            font-size: 24px;
            font-weight: 400;
            letter-spacing: 1px;
            text-transform: uppercase;

            svg {
              stroke: rgba(0, 0, 0, 0.8);
            }
            .prayer-edit-icon {
              cursor: pointer;
            }
          }
        }
        .hover-figure * {
          box-sizing: border-box;
          transition: all 0.45s ease;
        }

        &.editable-border {
          //    border: 0.5px solid rgba(0, 0, 0, 0.3);
          box-sizing: border-box;
          cursor: move;

          .hover-figure {
            display: inline-block;
            overflow: hidden;
            position: relative;
            //text-align: center;
            width: 100%;
            &:before,
            &:after {
              transform: scale(1);
              opacity: 1;
            }

            figcaption {
              opacity: 1;

              svg {
                stroke: rgba(0, 0, 0, 0.8);
              }
              .prayer-edit-icon {
                cursor: pointer;
              }
            }
          }
          .template-string {
            width: 100%;
            opacity: 0.7;
          }
        }
      }
    }
    .custom-card-img {
      height: 775px;
      width: 500px;
      display: flex;
      align-items: center;
      .custom-card-nav {
        padding: 0;
        width: 100%;
        //height: 100%;
        display: flex;
        justify-content: center;
        img {
          height: 775px !important;
          border: 5px solid black;
        }
      }
    }
    .template-card {
      .card-nav {
        padding: 0 !important;
      }
    }
    .custom-image-grid {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
    }
    .custom-item-container {
      width: 25% !important;
      padding: 0px;
      height: 50% !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
    }
    .custom-item-container img {
      border: 5px solid white;
      box-sizing: content-box !important;
    }
  }
  .prayer-card-footer {
    padding: 1rem 0;
    border-top: 1px solid rgba(0, 0, 0, 0.4);
    margin-top: 5rem;
    .row {
      margin-right: 0px;
      margin-left: 0px;
      padding: 1rem;
    }
    .prayer-card-info {
      .title {
        font-family: $lato;
        font-style: italic;
        color: rgba(0, 0, 0, 0.4);
        font-size: 14px;
        margin-right: 1rem;
      }
      .value {
        font-family: $lato;
        font-style: italic;
        font-weight: 700;
        color: rgba(0, 0, 0, 1);
        font-size: 14px;
      }
    }
  }
}

.slidecontainer {
  width: 270px !important;
  padding: 15px 0px;
}
.symbol-size {
  font-size: 20px;
  cursor: pointer;
}

.select-modal {
  min-width: 40%;
  .modal-content {
    background-color: transparent !important;
    .modal-header {
      background-color: transparent !important;
      padding: 30px 10px 30px 30px !important;
      .modal-close {
        color: white !important;
      }
    }
    .modal-body {
      padding: 0 !important;
    }
    .select-input {
      div[class*='-control'] {
        display: none;
      }
      div[class*='-menu'] {
        margin-bottom: 0;
        margin-top: 0;
      }
      div[class*='-MenuList'] {
        div {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}

.modal-template-slide {
  &.modal-preview-card {
    height: 90vh;
    max-width: 800px !important;

    .modal-content {
      width: 760px !important;

      .modal-body {
        width: 100%;
      }
    }
  }
  .modal-content {
    height: 100%;
    background-color: transparent !important;
    .modal-header {
      padding: 0 !important;
      .close {
        margin: 0;
        padding: 0;
        position: absolute;
        z-index: 9;
        right: 30px;
        top: 10px;
        opacity: 1;
        span {
          color: $black;
          font-size: 25px;
        }
        @media (max-width: 1023px) {
          top: 12px;
          right: 50px;
          span {
            font-size: 26px;
          }
        }
      }
    }
    .modal-body {
      padding: 0 !important;
      width: 400px;
      display: flex;
      justify-content: center;
      .carousel {
        height: 100%;
        width: fit-content;
        .carousel-inner {
          height: 100%;
          width: unset;
          background: #ffffff;
          box-shadow: 0px 7.52257px 22.5677px rgba(0, 0, 0, 0.15);
          border-radius: 3.00903px;
          .carousel-item {
            height: 100%;
            .cardbox-heading {
              min-height: 50px;
              background-color: #f7fbf8;
              text-align: center;
              color: rgba(0, 0, 0, 1);

              h1 {
                color: rgba(0, 0, 0, 1);
              }

              .cardbox-heading-name {
                color: rgba(0, 0, 0, 1) !important;
                @include font($roboto, 500, 20px, $black);
              }
              @media (max-width: 1023px) {
                min-height: 45px;
                .cardbox-heading-name {
                  font-size: 18px;
                  color: rgba(0, 0, 0, 1) !important;
                }
              }
            }
            .cardbox-wrapper {
              // width: 400px;
            }
            .template-card-image {
              .card-nav {
                max-height: 100%;
                max-width: 100%;
                overflow: hidden;
                padding: 0;
                .prayer-card-image {
                  min-height: 200%;
                  min-width: 400%;
                }
                .prayer-card-content {
                  height: auto;
                  overflow: visible;
                }
              }
              .custom-card-nav {
                padding: 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;
                width: 100%;
                img {
                  max-height: 100%;
                  max-width: 100%;
                  object-fit: contain;
                }
              }
              .custom-content-nav {
                max-height: 100%;
                max-width: 100%;
                padding: 0;
                overflow: hidden;
              }
            }
          }
        }
      }
      .carousel.slide {
        background-color: $white;
        .carousel-indicators {
          display: none;
        }
        .carousel-inner {
          background: #ffffff;
          box-shadow: 0px 7.52257px 22.5677px rgba(0, 0, 0, 0.15);
          border-radius: 3.00903px;
        }
        .carousel-control-prev,
        .carousel-control-next {
          width: 40px;
          height: 40px;
          opacity: 1;
          bottom: auto;
          top: calc(50% - 20px);
          background-color: $white;
          border-radius: 50%;
          span:not(.sr-only) {
            display: none;
          }
          &:before {
            content: '';
            width: 15px;
            height: 15px;
          }
          @media (max-width: 1023px) {
            width: 20px;
            height: 20px;
            &::before {
              width: 10px;
              height: 10px;
            }
          }
        }
        .carousel-control-prev {
          left: -60px;
          &:before {
            background: url(/assets/images/arrow-left.svg) no-repeat center;
            background-size: contain;
          }
          @media (max-width: 1023px) {
            left: -30px;
          }
        }
        .carousel-control-next {
          right: -60px;
          &:before {
            background: url(/assets/images/arrow-right.svg) no-repeat center;
            background-size: contain;
          }
          @media (max-width: 1023px) {
            right: -30px;
          }
        }
      }
    }
  }
}

#print-div {
  display: none;
}

.program-card-body-wrapper {
  .cardbox-heading-buttons {
    min-height: 50px;
    text-align: center;
    display: flex;
    justify-content: center;

    .cardbox-heading-button {
      max-width: 125px;
      margin: 0px 25px;
      cursor: pointer;
    }
    .BackButton {
      margin-left: 626px;
      .CancelButton {
        border: none;
        background-color: transparent;
        color: green;
        font-size: 20px;
      }
      .ArrowLeftIcon {
        height: 30px;
        margin-bottom: 3px;
      }
    }
  }

  .cardbox-heading {
    margin-top: 30px;
  }

  .template-card-image {
    .prayer-body {
      padding: 0 !important;
      img {
        width: 100%;
      }
    }
    .custom-prayer-body {
      height: fit-content !important;
      padding: 0 !important;
    }
  }

  .tooltip-template {
    .tooltip {
      .arrow {
        left: 10px !important;
      }
    }
  }
  .template-card {
    width: fit-content;
    // width: 900px;
    margin: 0 auto;
    @media (max-width: 767px) {
      width: 100%;
    }
    &.cardbox-wrapper {
      .cardbox-heading {
        background-color: #f7fbf8;
        min-height: 70px;

        .cardbox-heading-name {
          @include font($roboto, 500, 26px, $black);
        }
        @media (max-width: 1023px) {
          min-height: 60px;
          .cardbox-heading-name {
            font-size: 24px;
          }
        }
      }
      .cardbox-body {
        background-color: $white;
      }

      .prayer-body {
        @include flex-d(center, center, column);
        padding: 60px;
        text-align: center;
        height: 1450px;
        // height: 1275;
        width: 875px;
        @media (max-width: 1023px) {
          padding: 40px;
          min-height: 1000px;
          width: 625px;
        }
        @media (max-width: 767px) {
          padding: 40px;
          min-height: 850;
          width: 500px;
        }

        .template-card-body {
          // .poem-textarea {
          //   height: 20rem;
          // }
          white-space: break-spaces;
          &.template-with-selector {
            align-items: center;
            justify-content: center;
            .form-label-theme {
              font-weight: 700;
              margin-bottom: 0;
            }
          }

          .upper-case {
            text-transform: uppercase;
          }

          textarea {
            display: block;
            width: 100%;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            transition: border-color 0.15s ease-in-out,
              box-shadow 0.15s ease-in-out;

            color: #212121 !important;

            -webkit-writing-mode: horizontal-tb !important;
            text-rendering: auto;
            letter-spacing: normal;
            word-spacing: normal;
            text-transform: none;
            text-indent: 0px;
            text-shadow: none;
            appearance: textfield;
            -webkit-rtl-ordering: logical;
            cursor: text;
          }
        }

        textarea:focus {
          background-color: #fff;
          border-color: #80bdff;
        }

        .grouped-divs {
          display: flex;
          width: 100%;
          .group-div-1,
          .group-div-2 {
            text-align: center;
            width: 100%;
          }
        }

        .image-container {
          padding: 2rem;
          margin: 2rem;
          position: relative;
          svg {
            height: 300px;
            max-width: 100%;
          }
          .image-md {
            border-radius: 50%;
            min-height: 600px;
            max-width: 450px;
            object-fit: cover;
            // min-height: 500px;
            // min-width: 200px;
            // max-width: 300px;
          }
          .image-lg {
            height: 600px;
            object-fit: contain;
          }
          .link-customise {
            @include center(both);
            min-width: 135px;
            min-height: 45px;
            padding: 5px;
            visibility: hidden;
            z-index: 2;
          }
          &:hover {
            background-color: lightgray;
            .link-customise {
              visibility: visible;
            }
          }
        }

        .prayer-template-card {
          width: 100%;
          margin-bottom: 20px;
          @media (max-width: 1023px) {
            margin-bottom: 10px;
          }
          &:last-child {
            margin-bottom: 0;
          }

          .image-mdx {
            padding: 30px 0;
            object-fit: cover;
          }

          input,
          textarea {
            text-align: center;
          }
          input {
            padding: 0;
            line-height: normal;
          }

          .name-textarea {
            height: 80px;

            @media (max-width: 767px) {
              height: 55px;
            }
          }

          .three-textarea {
            height: 120px;
          }

          .four-textarea {
            height: 160px;
          }

          .six-textarea {
            height: 240px;
          }

          .md-textarea {
            height: 320px;
          }

          .lg-textarea {
            height: 450px;
          }

          .xl-textarea {
            height: 700px;
          }

          .xxl-textarea {
            height: 1000px;
          }

          .program-textarea {
            &.xxl-textarea {
              height: 740px;
            }
          }

          // .allow-empty {
          //     min-height: 400px;
          //     height: fit-content;
          //     width: 100%;
          // }

          textarea {
            padding: 0;
            @media (max-width: 1023px) {
              height: 54px;
            }
          }
          .max-textarea {
            textarea {
              text-align: left;
            }
          }

          .group-div-1 {
            float: left;
          }
          .group-div-2 {
            float: right;
          }

          .playball {
            div,
            input,
            textarea,
            span {
              @include font($playball, 400, 32px, $black);
              font-style: italic;
              @media (max-width: 1023px) {
                font-size: 28px;
              }
              @media (max-width: 767px) {
                // font-size: 28px;
              }
            }
            input {
              height: 35px;
            }
            &.template-description,
            &.poem-textarea {
              div,
              input,
              textarea,
              span {
                font-size: 32px;
                line-height: 40px;
                @media (max-width: 1023px) {
                  font-size: 20px;
                }
                @media (max-width: 767px) {
                  font-size: 18px;
                }
              }
            }
          }
          .playball-thin {
            div,
            input,
            textarea,
            span {
              @include font($playball, 300, 24px, $black);
              @media (max-width: 1023px) {
                font-size: 20px;
              }
            }

            textarea {
              line-height: 1.8;
            }
          }
          .playball-lg {
            div,
            input,
            textarea,
            span {
              @include font($playball, 600, 36px, $black);
              @media (max-width: 1023px) {
                font-size: 30px;
              }
            }

            textarea {
              line-height: 1.8;
            }
          }
          .cinzel-extra-bold {
            div,
            input,
            textarea,
            span {
              @include font($cinzel, 600, 32px, $black);
              @media (max-width: 1023px) {
                font-size: 22px;
              }
              @media (max-width: 767px) {
                font-size: 20px;
              }
            }
            input {
              height: 36px;
            }
          }
          .cinzel-bold {
            div,
            input,
            textarea,
            span {
              @include font($cinzel, 400, 30px, $black);
              white-space: break-spaces;
              @media (max-width: 1023px) {
                font-size: 20px;
              }
              @media (max-width: 767px) {
                font-size: 18px;
              }
            }
            input {
              height: 36px;
            }
          }
          .cinzel-bold-lg {
            div,
            input,
            textarea,
            span {
              @include font($cinzel, 400, 32px, $black);
              white-space: break-spaces;
              @media (max-width: 1023px) {
                font-size: 24px;
              }
            }
            input {
              height: 36px;
            }
          }
          .cinzel-bold-xlg {
            div,
            input,
            textarea,
            span {
              @include font($cinzel, 500, 34px, $black);
              white-space: break-spaces;
              @media (max-width: 1023px) {
                font-size: 28px;
              }
            }
            input {
              height: 36px;
            }
          }
          .cinzel {
            div,
            input,
            textarea,
            span {
              @include font($cinzel, 400, 26px, $black);
              @media (max-width: 1023px) {
                font-size: 20px;
              }
            }
          }

          .margin-xs {
            margin-bottom: 0;
          }
          .margin-sm {
            margin-bottom: 10px;
          }
          .margin-md {
            margin-bottom: 20px;
          }
          .margin-lg {
            margin-bottom: 30px;
          }
          .margin-xlg {
            margin-bottom: 40px;
          }
          .margin-top-sm {
            margin-top: 10px;
          }
          .margin-top-md {
            margin-top: 20px;
          }
          .margin-top-lg {
            margin-top: 30px;
          }
          .margin-top-xlg {
            margin-top: 40px;
          }

          .program-template {
            &.margin-top-xlg {
              margin-top: 100px;
            }
          }
        }
      }

      .program-body {
        height: 1200px;
        width: 825px;
        background: #ffffff;
        box-shadow: 0px 7.52257px 22.5677px rgba(0, 0, 0, 0.05);
        border-radius: 3.00903px;

        .prayer-template-card {
          .xxl-textarea {
            height: 1040px;
          }
        }
      }
    }
  }

  .prayer-card-tabs {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
    @media (max-width: 767px) {
      padding-bottom: 20px;
    }
    .tabs-container {
      width: 100%;
      border: 1px solid #fff;
      background: #fff;
      padding: 40px 40px 40px 20px;
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
      border-radius: 20px;
      margin-right: 0;
      margin-left: 0;

      @media (max-width: 1023px) {
        max-width: 100%;
        width: 100%;
      }
      .nav-tabs {
        border-bottom: none !important;
        display: block;
        border-bottom: none !important;
        margin-bottom: 35px;
        border-bottom: 0;
        margin-left: 20px;
        .nav-item {
          color: #212529;
          margin-bottom: 25px;
          min-height: 150px;
          display: flex;
          align-self: normal;
          &:not(:last-child) {
            margin-right: 20px;
          }
          .nav-link {
            border: 2px solid #ebebeb;
            padding: 0;
            color: #212529;
            position: relative;
            width: 340px;
            display: block;
            cursor: pointer;
            &.active {
              border: 3px solid green;
            }
            span.direction {
              @include font($roboto, 400, 14px, $black);
              position: absolute;
              width: 100%;
              left: 0;
              bottom: 0;
              height: 30px;
              padding: 5px 10px;
              background: #ebebeb;
              box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
              display: block;
              text-align: center;
            }
            .img-container {
              @include flex(center, center);
              height: 100%;
              img {
                width: 100%;
              }
            }
            &:first-child {
              .nav-link {
                width: 80px;
              }
            }
          }
        }
      }
    }
    .buttons-container {
      margin-bottom: 30px;
      padding: 0 10px;

      button {
        min-width: 135px;
        min-height: 45px;
        padding: 10px;
        text-transform: uppercase;
      }
      @media (max-width: 767px) {
        margin-top: 40px;
      }
      @media (max-width: 520px) {
        flex-direction: column;
        align-items: center;
        button {
          font-size: 14px;
          min-height: 36px;
          &:not(:first-child) {
            margin: 20px 0 0 !important;
          }
        }
      }
    }
    &.program-card-tabs {
      .template-card-mini {
        @include flex(center, center);
      }
      .tabs-container .nav-tabs {
        .nav-item {
          @media (max-width: 600px) {
            width: 50%;
            margin-right: 0;
            justify-content: center;
            &:nth-of-type(1),
            &:nth-of-type(2) {
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }

  .template-card-mini {
    padding: 10px 10px 40px;
    // box-shadow: 0px 4.23077px 15.8654px $brand-primary;
    border: 3px solid #ebebeb;
    border-radius: 2px;
    height: 100%;
    .template-card-mini-body {
      @include flex-d(flex-start, center, column);
      text-align: center;
      .template-card-mini-text {
        word-break: break-word;
        white-space: break-spaces;
        width: 100%;
        padding-bottom: 5px;
        .link-customise {
          display: none !important;
        }
        &:not(:last-child) {
          margin-bottom: 12px;
        }
        .container-group {
          display: flex;
          justify-content: space-around;
          margin-bottom: 12px;
          > div {
            padding: 0 10px;
          }
        }
        span {
          display: block;
          &:first-child {
            margin-bottom: 2px;
          }
        }
        svg {
          height: 50px;
        }
        img {
          max-width: 70%;
        }
        .image-mdx {
          min-width: 60px;
          max-width: 70px;
          padding: 15px 0 0 0;
          object-fit: cover;
          &.margin-location {
            max-height: 98px;
            border-radius: unset;
            object-fit: contain;
          }
        }
        .xxl-textarea {
          min-height: 120px;
          height: fit-content;
        }
        p {
          margin: 0;
        }
        .allow-empty {
          min-height: 30px;
          height: fit-content;
          width: 100%;
        }
        .playball {
          @include font($playball, 400, 2.5px, $black);
        }
        .playball-lg {
          @include font($playball, 400, 2.7px, $black);
        }
        .cinzel-extra-bold,
        .cinzel-bold {
          @include font($cinzel, 500, 2.5px, $black);
          // text-transform: uppercase;
        }
        .cinzel-bold-lg {
          @include font($cinzel, 400, 2.7px, $black);
        }
        .cinzel-bold-xlg {
          @include font($cinzel, 500, 2.9px, $black);
        }
        .cinzel {
          @include font($cinzel, 400, 2.5px, $black);
        }
        .cinzel-bold {
          font-weight: 400;
        }
      }
      .nav-mini-text {
        .image-md {
          border-radius: 50%;
          height: 50px;
          min-width: 20px;
          max-width: 30px;
          object-fit: cover;
        }
        .image-lg {
          height: 80px;
          object-fit: contain;
        }
        .xxl-textarea {
          min-height: 50px;
          height: fit-content;
        }
        &:not(:last-child) {
          margin-bottom: 4px;
        }
      }
    }
  }
}

.program-card-body-wrapper .program-template-body {
  .template-card-mini .template-card-mini-body .template-card-mini-text {
    .playball {
      font-size: 8px;
      line-height: 10px;
    }
    .playball-lg {
      font-size: 9px;
      line-height: 12px;
    }
    .cinzel-extra-bold,
    .cinzel-bold {
      font-size: 8px;
      line-height: 10px;
    }
    .cinzel-bold-lg {
      font-size: 9px;
      line-height: 12px;
    }
    .cinzel-bold-xlg {
      font-size: 10px;
      line-height: 13px;
    }
    .cinzel {
      font-size: 8px;
      line-height: 10px;
    }
  }
}

.modal-open .modal .modal-program-template-slide.modal-dialog {
  max-width: 100% !important;
  width: 45%;
  // height: 100% !important;
  // width: 100vh;
  // height: 100vh;

  .modal-content {
    background-color: transparent;
    width: 100%;
    .modal-header {
      padding: 0;
      .close {
        margin: 0;
        padding: 5px;
        position: absolute;
        z-index: 9;
        right: 70px;
        top: 18px;
        opacity: 1;
        background: none;
        span {
          color: $black;
          font-size: 25px;
        }
        @media (max-width: 1023px) {
          top: 12px;
          right: 50px;
          span {
            font-size: 26px;
          }
        }
      }
    }
    .modal-body {
      padding: 0 80px;
      max-height: 80%;
      @media (max-width: 1023px) {
        padding: 0 40px;
      }
      .carousel.slide {
        background-color: $white;
        .carousel-indicators {
          display: none;
        }
        .carousel-inner {
          background: #ffffff;
          box-shadow: 0px 7.52257px 22.5677px rgba(0, 0, 0, 0.15);
          border-radius: 3.00903px;
        }
        .carousel-item {
          .container-group {
            > div {
              max-width: 50%;
            }
            @media (max-width: 600px) {
              flex-direction: column;
              > div {
                max-width: 100%;
              }
            }
          }
          .template-card {
            width: 100%;
            // div {
            //     white-space: break-spaces;
            // }
            .cardbox-heading {
              min-height: 40px;

              .cardbox-heading-name {
                @include font($roboto, 500, 18px, $black);
              }
              @media (max-width: 1023px) {
                min-height: 45px;
                .cardbox-heading-name {
                  font-size: 18px;
                }
              }
            }
            .template-card-mini {
              white-space: break-spaces;
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
              padding: 0;
              .template-card-mini-body {
                overflow-y: auto;
                height: 80vh;
                padding: 30px 60px;
                @media (max-width: 1023px) {
                  padding: 40px;
                }
                @media (max-width: 767px) {
                  padding: 20px;
                }
              }
            }

            .img-container {
              display: flex;
              justify-content: center;
              white-space: break-spaces;
              // padding: 60px;
              height: 80vh;
              padding: 0;
              // height: fit-content;
              width: 100%;
              overflow-y: auto;
              object-fit: contain;
              @media (max-width: 1023px) {
                padding: 40px;
              }
              @media (max-width: 767px) {
                padding: 20px;
              }
            }

            .image-md {
              height: 50vh;
              border-radius: 50%;
              min-width: 180px;
              max-width: 220px;
              padding: 30px 0;
              object-fit: cover;
            }

            .image-mdx {
              min-width: 180px;
              max-width: 220px;
              padding: 30px 0;
              object-fit: cover;
            }

            .image-lg {
              height: 400px;
              object-fit: contain;
            }

            .group-div-1 {
              float: left;
            }
            .group-div-2 {
              float: right;
            }

            .name-textarea {
              min-height: 50px;
            }

            .three-textarea {
              height: 75px;
            }

            .four-textarea {
              height: 100px;
            }

            .six-textarea {
              height: 150px;
            }

            .xl-textarea {
              height: 375px;
            }

            .md-textarea {
              height: 250px;
            }

            .lg-textarea {
              height: 250px;
            }

            .xxl-textarea {
              min-height: 400px;
              height: fit-content;
            }

            @media (max-width: 1023px) {
              width: calc(100vw - 80px);
            }
          }
          .template-card-mini-text {
            &:not(:last-child) {
              margin-bottom: 5px;
              @media (max-width: 1023px) {
                margin-bottom: 5px;
              }
            }
            svg {
              height: 250px;
            }
            .playball {
              font-size: 14px;
              font-style: italic;
              @media (max-width: 1023px) {
                font-size: 14px;
              }
            }
            .playball-lg {
              font-size: 21px;
              font-style: italic;
              @media (max-width: 1023px) {
                font-size: 19px;
              }
            }

            .cinzel-extra-bold,
            .cinzel-bold {
              font-size: 16px;
              @media (max-width: 1023px) {
                font-size: 14px;
              }
            }
            .cinzel-bold-lg {
              font-size: 14px;
              @media (max-width: 1023px) {
                font-size: 12px;
              }
            }
            .cinzel-bold-xlg {
              font-size: 16px;
              @media (max-width: 1023px) {
                font-size: 16px;
              }
            }
            .cinzel {
              @include font($cinzel, 400, 13px, $black);
            }
            &:nth-last-of-type(1),
            &:nth-last-of-type(2) {
              .playball {
                font-size: 14px;
                font-style: italic;
                @media (max-width: 1023px) {
                  font-size: 14px;
                }
              }
            }
            .footer-div {
              margin-top: 18px;
            }
          }
        }
        .carousel-control-prev,
        .carousel-control-next {
          width: 40px;
          height: 40px;
          opacity: 1;
          bottom: auto;
          top: calc(50% - 20px);
          background-color: $white;
          border-radius: 50%;
          span:not(.sr-only) {
            display: none;
          }
          &:before {
            content: '';
            width: 15px;
            height: 15px;
          }
          @media (max-width: 1023px) {
            width: 20px;
            height: 20px;
            &::before {
              width: 10px;
              height: 10px;
            }
          }
        }
        .carousel-control-prev {
          left: -60px;
          &:before {
            background: url(/assets/images/arrow-left.svg) no-repeat center;
            background-size: contain;
          }
          @media (max-width: 1023px) {
            left: -30px;
          }
        }
        .carousel-control-next {
          right: -60px;
          &:before {
            background: url(/assets/images/arrow-right.svg) no-repeat center;
            background-size: contain;
          }
          @media (max-width: 1023px) {
            right: -30px;
          }
        }
      }
    }
    .margin-xs {
      margin-bottom: 0;
    }
    .margin-sm {
      margin-bottom: 5px;
    }
    .margin-md {
      margin-bottom: 10px;
    }
    .margin-xlg {
      margin-bottom: 20px;
    }
    .margin-top-sm {
      margin-top: 5px;
    }
    .margin-top-md {
      margin-top: 10px;
    }
    .margin-top-lg {
      margin-top: 15px;
    }
    .margin-top-xlg {
      margin-top: 20px;
    }
  }
}

.program-card-tabs {
  .program-text-msg {
    font-size: 18px;
    line-height: 16px;
    margin-bottom: 30px;
    margin-left: 230px;
  }
  .tabs-container .nav-tabs .nav-item {
    .nav-link {
      width: 100px;
      .template-card-mini .template-card-mini-body {
        .playball {
          font-size: 1.8px;
        }
        .playball-lg {
          font-size: 2.2px;
        }
        .cinzel-extra-bold,
        .cinzel-bold {
          font-size: 2px;
        }
        .cinzel-bold-lg {
          font-size: 2.1px;
        }
        .cinzel-bold-xlg {
          font-size: 2.3px;
        }
        .cinzel {
          font-size: 2px;
        }
      }
    }
  }
}
