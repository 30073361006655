@mixin custom-height($size, $line) {
  .ql-height-#{$size}-#{$line} {
    height: #{($size * $line) + (8 * ($line + 1))}px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    &.container-program-card {
      max-width: 1260px !important;
    }
  }
}

.template-screen-edit-program .ql-height-24-2 {
  height: 420px !important;
}

.template-screen-edit-program .ql-height-32-21 {
  height: 700px;
}

.disable-save {
  color: #2e7d32;
  background-color: #fff;
}

.program-card-body-wrapper {
  .container-program-template {
    background-color: $white;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
    margin-bottom: 30px;
    border-radius: 4px;
    .program-template-body {
      position: relative;
      cursor: pointer;
      padding: 20px;

      .program-template-img {
        width: 100%;
      }
      //   > div {
      //     display: flex;
      //     flex-wrap: wrap;
      //     justify-content: space-between;
      //     padding: 75px 40px 35px;
      //     @media (max-width: 1199px) {
      //       justify-content: space-around;
      //     }
      //     @media (max-width: 767px) {
      //       justify-content: center;
      //     }
      //   }
      .btn-customise {
        display: none !important;
        position: absolute;
        left: calc(50% - 75px);
        top: calc(50% - 28px);
      }
      &:hover {
        // background: rgba($black, 0.5);
        .btn-customise {
          display: flex !important;
        }
      }
      .program-template-card {
        /*margin-right: 30px;*/
        position: relative;
        //margin-bottom: 80px;
        width: calc((100% - (3 * 30px)) / 4);
        display: flex;
        &:last-child {
          margin-right: 0;
        }
        @media (max-width: 1199px) {
          width: 250px;
          margin: 0 0 80px;
          &:nth-of-type(4) {
            margin-bottom: 0;
          }
        }
        @media (max-width: 991px) {
          &:nth-of-type(3) {
            margin-bottom: 0;
          }
        }
        @media (max-width: 767px) {
          width: 100%;
          max-width: 250px;
          &:nth-of-type(3) {
            margin-bottom: 80px;
          }
        }
        .template-card-title {
          @include flex(center, center);
          @include font($roboto, 500, 16px, $black);
          position: absolute;
          top: -40px;
          width: 101%;
          left: -1px;
          min-height: 40px;
          background: #ebebeb;
          border-radius: 10px 10px 0 0;
        }
        .template-card-body {
          @include flex-d(center, center, column);
          text-align: center;
          height: 500px;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
          border-radius: 4px;
          padding: 20px;
          flex-grow: 1;
          overflow: hidden;
          span {
            display: block;
          }
          p {
            @include font($playball, 400, 8px, $black);
            line-height: 11px;
            margin: 0;
          }
        }
        &.program-template-card1 .template-card-body p {
          font-size: 9px;
        }
        &.program-template-card3 .template-card-body p {
          @include font($roboto, 400, 8px, $black);
          span {
            line-height: 16px;
          }
        }
      }
      .template-card-mini {
        height: 100%;
        width: 100%;
        border: none;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        word-break: break-word;
        .template-card-mini-body .template-card-mini-text {
          max-width: 100%;
          word-break: break-word;
          span {
            font-size: 8px;
            line-height: 10px;
          }
        }
      }
    }
    .program-template-header {
      @include flex(flex-start, center);
      @include font($roboto, 700, 21px, $black);
      height: 58px;
      padding: 0 40px;
      position: relative;
      border-radius: 4px;
      background-color: #f7fbf8;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0 3px 10px 0 rgba(144, 137, 137, 0.18);
      -moz-box-shadow: 0 3px 10px 0 rgba(144, 137, 137, 0.18);
      -webkit-box-shadow: 0 3px 10px 0 rgba(144, 137, 137, 0.18);
    }
    &.container-program2-template {
      .program-template-card1 .template-card-body {
        .name {
          @include font($playball, 400, 9px, $black);
          line-height: 11px;
          margin: 20px 0;
        }
        > span {
          @include font($roboto, 400, 8px, $black);
        }
      }
      .program-template-card3 .template-card-body {
        div,
        h4 {
          @include font($roboto, 400, 8px, $black);
          word-break: break-all;
          text-align: left;
          &.top-description {
            text-transform: uppercase;
            line-height: 9px;
            text-align: center;
            line-height: 16px;
          }
          span {
            line-height: 9px;
            margin-bottom: 6px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        h4 {
          font-weight: 700 !important;
          text-align: center;
          text-transform: uppercase;
          margin: 15px 0 5px;
        }
      }
      .program-template-card4 .template-card-body {
        .pallbearers {
          margin-bottom: 20px;
          h4 {
            @include font($roboto, 400, 8px, $black);
            margin-bottom: 5px;
            text-transform: uppercase;
          }
          div {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            li {
              @include font($roboto, 400, 8px, $black);
              list-style: none;
              width: 50%;
              padding: 0 10px;
              line-height: 13px;
            }
          }
          &.great-grand {
            div li {
              width: 100%;
            }
          }
        }
        p {
          span {
            text-align: right;
            margin-top: 5px;
          }
        }
      }
    }
    &.container-program3-template {
      .program-template-card2 .template-card-body {
        div span {
          @include font($roboto, 700, 8px, $black);
          line-height: 11px;
          margin-bottom: 10px;
        }
      }
      .program-template-card3 .template-card-body p {
        @include font($roboto, 400, 8px, $black);
        span {
          line-height: 13px !important;
        }
      }
    }
  }
}

.program-template-card[align='right'] {
  input {
    text-align: right !important;
  }
}

.program-template-card[align='left'] {
  textarea {
    text-align: left !important;
  }
}

.template-screen-edit-program {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 800px;
  padding: 2.5rem;
  line-height: 1.5;
  background: #ffffff;
  box-shadow: 0px 5.2px 15.6px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin-top: 30px;
  min-height: 900px;

  .image-container {
    padding: 2rem;
    margin: 2rem;
    position: relative;
    display: flex;
    justify-content: center;

    svg {
      height: 300px;
      max-width: 100%;
    }
    .image-md {
      border-radius: 50%;
      min-height: 600px;
      max-width: 450px;
      object-fit: cover;
    }
    .image-lg {
      height: 600px;
      object-fit: contain;
    }
    .profileImage {
        object-fit: cover;
       // border: 1px solid;
        border-radius: 50%;
        width: 500px;
        height: 600px
    }
    .downloadIcon {
        height: 30px;
        width: 30px;
    }
    .downLoadButton {
        background-color: transparent;
        border: none;
        position: absolute;
        z-index: 1;
        top: 10px;
        right: 10px
    }
    .link-customise {
      @include center(both);
      min-width: 135px;
      min-height: 45px;
      padding: 5px;
      visibility: hidden;
      z-index: 2;
    }
    .disableCamera {
        height: 30px;
        cursor: not-allowed;
    }
    .cameraIcon {
        height: 30px;
        cursor: pointer;
    }
    &:hover {
      background-color: lightgray;
      .link-customise {
        visibility: visible;
      }
    }
  }

  .template-string-wrapper {
    .hover-figure {
      display: inline-block;
      overflow: hidden;
      position: relative;
      text-align: center;
      width: 100%;
      &:before,
      &:after {
        background-color: rgba(255, 255, 255, 0.5);
        border-top: 2px solid rgba(0, 0, 0, 0.3);
        border-bottom: 2px solid rgba(0, 0, 0, 0.3);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        content: '';
        transition: all 0.3s ease;
        z-index: 1;
        opacity: 0;
        transform: scaleY(2);
      }
      .template-string {
        backface-visibility: hidden;
        word-break: break-word;
        p {
          margin-bottom: 0;
        }
      }
      figcaption {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        align-items: center;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 1.1em;
        opacity: 0;
        z-index: 2;
        transition-delay: 0.1s;
        font-size: 24px;
        font-weight: 400;
        letter-spacing: 1px;
        text-transform: uppercase;

        svg {
          stroke: rgba(0, 0, 0, 0.8);
        }
      }
    }
    .hover-figure * {
      box-sizing: border-box;
      transition: all 0.45s ease;
    }

    &.editable-border {
      //    border: 0.5px solid rgba(0, 0, 0, 0.3);
      box-sizing: border-box;

      .hover-figure {
        display: inline-block;
        overflow: hidden;
        position: relative;
        text-align: center;
        width: 100%;
        &:before,
        &:after {
          transform: scale(1);
          opacity: 1;
        }

        figcaption {
          opacity: 1;

          svg {
            stroke: rgba(0, 0, 0, 0.8);
          }
        }
      }
      .template-string {
        width: 100%;
        opacity: 0.7;
      }
    }
  }

  .ql-container {
    font-size: 18px;
  }

  /* Set content for font-families */
  .ql-font-arial,
  .ql-font span[data-value='arial']::before {
    font-family: Arial, sans-serif;
  }
  .ql-font-comicsans,
  .ql-font span[data-value='comicsans']::before {
    font-family: 'Comic Sans MS', cursive, sans-serif;
  }
  .ql-font-couriernew,
  .ql-font span[data-value='couriernew']::before {
    font-family: 'Courier New';
  }
  .ql-font-georgia,
  .ql-font span[data-value='georgia']::before {
    font-family: Georgia, serif;
  }
  .ql-font-helvetica,
  .ql-font span[data-value='helvetica']::before {
    font-family: Helvetica, sans-serif;
  }
  .ql-font-lucida,
  .ql-font span[data-value='lucida']::before {
    font-family: 'Lucida Sans Unicode', 'Lucida Grande', sans-serif;
  }

  .ql-font-playball,
  .ql-font span[data-value='playball']::before {
    font-family: $playball;
  }

  .ql-font-cinzel,
  .ql-font span[data-value='cinzel']::before {
    font-family: $cinzel;
  }

  .ql-font-roboto,
  .ql-font span[data-value='roboto']::before {
    font-family: $roboto;
  }

  .ql-font-robotoslab,
  .ql-font span[data-value='robotoslab']::before {
    font-family: $robotoslab;
  }

  .ql-font-lato,
  .ql-font span[data-value='lato']::before {
    font-family: $lato;
  }

  .ql-font-martel,
  .ql-font span[data-value='martel']::before {
    font-family: $martel;
  }

  .ql-font-dancing-script,
  .ql-font span[data-value='dancing-script']::before {
    font-family: $dancing;
  }

  /* Set content for sizes */
  .ql-size-fs12,
  .ql-size span[data-value='fs12']::before {
    font-size: 12px !important;
  }
  .ql-size-fs13,
  .ql-size span[data-value='fs13']::before {
    font-size: 13px !important;
  }

  .ql-size-small,
  .ql-size span[data-value='small']::before {
    font-size: 14px !important;
  }
  .ql-size-fs15,
  .ql-size span[data-value='fs15']::before {
    font-size: 15px !important;
  }
  .ql-size-smmedium,
  .ql-size span[data-value='smmedium']::before {
    font-size: 16px !important;
  }
  .ql-size-medium,
  .ql-size span[data-value='medium']::before {
    font-size: 18px !important;
  }
  .ql-size-lgmedium,
  .ql-size span[data-value='lgmedium']::before {
    font-size: 21px !important;
  }
  .ql-size-large,
  .ql-size span[data-value='large']::before {
    font-size: 24px !important;
  }
  .ql-size-huge,
  .ql-size span[data-value='huge']::before {
    font-size: 32px !important;
  }

  .ql-size-fs17,
  .ql-size span[data-value='fs17']::before {
    font-size: 17px !important;
  }

  .ql-size-fs19,
  .ql-size span[data-value='fs19']::before {
    font-size: 19px !important;
  }

  .ql-size-fs20,
  .ql-size span[data-value='fs20']::before {
    font-size: 20px !important;
  }

  .ql-size-fs22,
  .ql-size span[data-value='fs22']::before {
    font-size: 22px !important;
  }

  .ql-size-fs23,
  .ql-size span[data-value='fs23']::before {
    font-size: 23px !important;
  }

  .ql-size-fs25,
  .ql-size span[data-value='fs25']::before {
    font-size: 25px !important;
  }

  .ql-size-fs26,
  .ql-size span[data-value='fs26']::before {
    font-size: 26px !important;
  }

  .ql-size-fs27,
  .ql-size span[data-value='fs27']::before {
    font-size: 27px !important;
  }

  .ql-size-fs28,
  .ql-size span[data-value='fs28']::before {
    font-size: 28px !important;
  }

  /* Set content for alignment */
  .ql-align-left,
  .ql-align span[data-value='']::before {
    text-align: left !important;
  }
  .ql-align-center,
  .ql-align span[data-value='center']::before {
    text-align: center !important;
  }
  .ql-align-right,
  .ql-align span[data-value='right']::before {
    text-align: right !important;
  }
  .ql-align-justify,
  .ql-align span[data-value='justify']::before {
    text-align: justify !important;
  }

  /* Generate custom height for editor */
  // $heights: ("fs12", "fs13","small","fs15", "smmedium", "fs17" "medium", "fs19", "fs20", "lgmedium", "fs22", "fs23", "large","fs25", "fs26","fs27", "fs28", "huge");
  $heights: (
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    32
  );
  $lineNumber: (1, 2, 14, 15);
  @mixin generate-height() {
    @each $height in $heights {
      @each $line in $lineNumber {
        @include custom-height($height, $line);
      }
    }
  }

  @include generate-height();
}

.template-screen-edit-program-preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2.5rem;
  line-height: 1 !important;
  border-radius: 5px;
  // margin-top: 30px;

  .image-containers {
    display: flex;
    justify-content: center;
  }

  img {
    width: 50%;
    margin: 10px 0;
  }

  .image-container {
    padding: 2rem;
    margin: 2rem;
    position: relative;
    display: flex;
    justify-content: center;

    svg {
      height: 300px;
      max-width: 100%;
    }
    .image-md {
      border-radius: 50%;
      min-height: 600px;
      max-width: 450px;
      object-fit: cover;
    }
    .image-lg {
      height: 600px;
      object-fit: contain;
    }
    .link-customise {
      @include center(both);
      min-width: 135px;
      min-height: 45px;
      padding: 5px;
      visibility: hidden;
      z-index: 2;
    }
    &:hover {
      background-color: lightgray;
      .link-customise {
        visibility: visible;
      }
    }
  }

  .template-string-wrapper {
    .hover-figure {
      display: inline-block;
      overflow: hidden;
      position: relative;
      text-align: center;
      width: 100%;
      &:before,
      &:after {
        background-color: rgba(255, 255, 255, 0.5);
        border-top: 2px solid rgba(0, 0, 0, 0.3);
        border-bottom: 2px solid rgba(0, 0, 0, 0.3);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        content: '';
        transition: all 0.3s ease;
        z-index: 1;
        opacity: 0;
        transform: scaleY(2);
      }
      .template-string {
        backface-visibility: hidden;
        word-break: break-word;
        p {
          margin-bottom: 0;
        }
      }
      figcaption {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        align-items: center;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 1.1em;
        opacity: 0;
        z-index: 2;
        transition-delay: 0.1s;
        font-size: 24px;
        font-weight: 400;
        letter-spacing: 1px;
        text-transform: uppercase;

        svg {
          stroke: rgba(0, 0, 0, 0.8);
        }
      }
    }
    .hover-figure * {
      box-sizing: border-box;
      transition: all 0.45s ease;
    }

    &.editable-border {
      //    border: 0.5px solid rgba(0, 0, 0, 0.3);
      box-sizing: border-box;

      .hover-figure {
        display: inline-block;
        overflow: hidden;
        position: relative;
        text-align: center;
        width: 100%;
        &:before,
        &:after {
          transform: scale(1);
          opacity: 1;
        }

        figcaption {
          opacity: 1;

          svg {
            stroke: rgba(0, 0, 0, 0.8);
          }
        }
      }
      .template-string {
        width: 100%;
        opacity: 0.7;
      }
    }
  }

  .ql-container {
    font-size: 18px;
  }

  /* Set content for font-families */
  .ql-font-arial,
  .ql-font span[data-value='arial']::before {
    font-family: Arial, sans-serif;
  }
  .ql-font-comicsans,
  .ql-font span[data-value='comicsans']::before {
    font-family: 'Comic Sans MS', cursive, sans-serif;
  }
  .ql-font-couriernew,
  .ql-font span[data-value='couriernew']::before {
    font-family: 'Courier New';
  }
  .ql-font-georgia,
  .ql-font span[data-value='georgia']::before {
    font-family: Georgia, serif;
  }
  .ql-font-helvetica,
  .ql-font span[data-value='helvetica']::before {
    font-family: Helvetica, sans-serif;
  }
  .ql-font-lucida,
  .ql-font span[data-value='lucida']::before {
    font-family: 'Lucida Sans Unicode', 'Lucida Grande', sans-serif;
  }

  .ql-font-playball,
  .ql-font span[data-value='playball']::before {
    font-family: $playball;
  }

  .ql-font-cinzel,
  .ql-font span[data-value='cinzel']::before {
    font-family: $cinzel;
  }

  .ql-font-roboto,
  .ql-font span[data-value='roboto']::before {
    font-family: $roboto;
  }

  .ql-font-robotoslab,
  .ql-font span[data-value='robotoslab']::before {
    font-family: $robotoslab;
  }

  .ql-font-lato,
  .ql-font span[data-value='lato']::before {
    font-family: $lato;
  }

  .ql-font-martel,
  .ql-font span[data-value='martel']::before {
    font-family: $martel;
  }

  .ql-font-dancing-script,
  .ql-font span[data-value='dancing-script']::before {
    font-family: $dancing;
  }

  /* Set content for sizes */
  .ql-size-fs12,
  .ql-size span[data-value='fs12']::before {
    font-size: 12px !important;
  }
  .ql-size-fs13,
  .ql-size span[data-value='fs13']::before {
    font-size: 13px !important;
  }

  .ql-size-small,
  .ql-size span[data-value='small']::before {
    font-size: 14px !important;
  }
  .ql-size-fs15,
  .ql-size span[data-value='fs15']::before {
    font-size: 15px !important;
  }
  .ql-size-smmedium,
  .ql-size span[data-value='smmedium']::before {
    font-size: 16px !important;
  }
  .ql-size-medium,
  .ql-size span[data-value='medium']::before {
    font-size: 18px !important;
  }
  .ql-size-lgmedium,
  .ql-size span[data-value='lgmedium']::before {
    font-size: 21px !important;
  }
  .ql-size-large,
  .ql-size span[data-value='large']::before {
    font-size: 24px !important;
  }
  .ql-size-huge,
  .ql-size span[data-value='huge']::before {
    font-size: 32px !important;
  }

  .ql-size-fs17,
  .ql-size span[data-value='fs17']::before {
    font-size: 17px !important;
  }

  .ql-size-fs19,
  .ql-size span[data-value='fs19']::before {
    font-size: 19px !important;
  }

  .ql-size-fs20,
  .ql-size span[data-value='fs20']::before {
    font-size: 20px !important;
  }

  .ql-size-fs22,
  .ql-size span[data-value='fs22']::before {
    font-size: 22px !important;
  }

  .ql-size-fs23,
  .ql-size span[data-value='fs23']::before {
    font-size: 23px !important;
  }

  .ql-size-fs25,
  .ql-size span[data-value='fs25']::before {
    font-size: 25px !important;
  }

  .ql-size-fs26,
  .ql-size span[data-value='fs26']::before {
    font-size: 26px !important;
  }

  .ql-size-fs27,
  .ql-size span[data-value='fs27']::before {
    font-size: 27px !important;
  }

  .ql-size-fs28,
  .ql-size span[data-value='fs28']::before {
    font-size: 28px !important;
  }

  /* Set content for alignment */
  .ql-align-left,
  .ql-align span[data-value='']::before {
    text-align: left !important;
  }
  .ql-align-center,
  .ql-align span[data-value='center']::before {
    text-align: center !important;
  }
  .ql-align-right,
  .ql-align span[data-value='right']::before {
    text-align: right !important;
  }
  .ql-align-justify,
  .ql-align span[data-value='justify']::before {
    text-align: justify !important;
  }

  /* Generate custom height for editor */
  // $heights: ("fs12", "fs13","small","fs15", "smmedium", "fs17" "medium", "fs19", "fs20", "lgmedium", "fs22", "fs23", "large","fs25", "fs26","fs27", "fs28", "huge");
  $heights: (
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    32
  );
  $lineNumber: (1, 2, 14, 15);
  @mixin generate-height() {
    @each $height in $heights {
      @each $line in $lineNumber {
        @include custom-height($height, $line);
      }
    }
  }

  @include generate-height();
}
.limit-error {
  color: red;
  font-size: 16px;
  margin-top: 10px;
}

.nav-mode {
  .template-card {
    height: 340px;
    width: 200px;
    padding: 0;

    &.first-page {
      height: 320px;
    }
  }
  .template-screen-edit-program {
    height: 340px;
    width: 200px;
    padding: 1rem;
    line-height: 0.8;

    div {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: auto;
    }

    p {
      margin-bottom: unset;
      word-break: break-word;
    }

    .ql-container {
      font-size: 7.2px;
    }

    /* Set content for sizes */
    .ql-size-fs12,
    .ql-size span[data-value='fs12']::before {
      font-size: 4.8px !important;
    }

    .ql-size-fs13,
    .ql-size span[data-value='fs13']::before {
      font-size: 5.2px !important;
    }

    .ql-size-small,
    .ql-size span[data-value='small']::before {
      font-size: 5.6px !important;
    }

    .ql-size-fs15,
    .ql-size span[data-value='fs15']::before {
      font-size: 6px !important;
    }

    .ql-size-smmedium,
    .ql-size span[data-value='smmedium']::before {
      font-size: 6.4px !important;
    }

    .ql-size-medium,
    .ql-size span[data-value='medium']::before {
      font-size: 7.2px !important;
    }

    .ql-size-lgmedium,
    .ql-size span[data-value='lgmedium']::before {
      font-size: 8.4px !important;
    }

    .ql-size-large,
    .ql-size span[data-value='large']::before {
      font-size: 9.6px !important;
    }

    .ql-size-huge,
    .ql-size span[data-value='huge']::before {
      font-size: 16px !important;
    }

    .ql-size-fs17,
    .ql-size span[data-value='fs17']::before {
      font-size: 6.8px !important;
    }

    .ql-size-fs19,
    .ql-size span[data-value='fs19']::before {
      font-size: 7.6px !important;
    }

    .ql-size-fs20,
    .ql-size span[data-value='fs20']::before {
      font-size: 8px !important;
    }

    .ql-size-fs22,
    .ql-size span[data-value='fs22']::before {
      font-size: 8.8px !important;
    }

    .ql-size-fs23,
    .ql-size span[data-value='fs23']::before {
      font-size: 9.2px !important;
    }

    .ql-size-fs25,
    .ql-size span[data-value='fs25']::before {
      font-size: 10px !important;
    }

    .ql-size-fs26,
    .ql-size span[data-value='fs26']::before {
      font-size: 10.6px !important;
    }

    .ql-size-fs27,
    .ql-size span[data-value='fs27']::before {
      font-size: 11.2px !important;
    }

    .ql-size-fs28,
    .ql-size span[data-value='fs28']::before {
      font-size: 11.7px !important;
    }
  }

  .template-screen-edit-program-preview {
    height: 430px;
    overflow: hidden;
    padding: 1rem;
    line-height: 1 !important;

    div {
      flex-shrink: 1;
      flex-basis: auto;
    }

    p {
      margin-bottom: unset;
      word-break: break-word;
    }

    .ql-container {
      font-size: 7.2px;
    }

    /* Set content for sizes */
    .ql-size-fs12,
    .ql-size span[data-value='fs12']::before {
      font-size: 4.8px !important;
    }

    .ql-size-fs13,
    .ql-size span[data-value='fs13']::before {
      font-size: 5.2px !important;
    }

    .ql-size-small,
    .ql-size span[data-value='small']::before {
      font-size: 5.6px !important;
    }

    .ql-size-fs15,
    .ql-size span[data-value='fs15']::before {
      font-size: 6px !important;
    }

    .ql-size-smmedium,
    .ql-size span[data-value='smmedium']::before {
      font-size: 6.4px !important;
    }

    .ql-size-medium,
    .ql-size span[data-value='medium']::before {
      font-size: 7.2px !important;
    }

    .ql-size-lgmedium,
    .ql-size span[data-value='lgmedium']::before {
      font-size: 8.4px !important;
    }

    .ql-size-large,
    .ql-size span[data-value='large']::before {
      font-size: 9.6px !important;
    }

    .ql-size-huge,
    .ql-size span[data-value='huge']::before {
      font-size: 16px !important;
    }

    .ql-size-fs17,
    .ql-size span[data-value='fs17']::before {
      font-size: 6.8px !important;
    }

    .ql-size-fs19,
    .ql-size span[data-value='fs19']::before {
      font-size: 7.6px !important;
    }

    .ql-size-fs20,
    .ql-size span[data-value='fs20']::before {
      font-size: 8px !important;
    }

    .ql-size-fs22,
    .ql-size span[data-value='fs22']::before {
      font-size: 8.8px !important;
    }

    .ql-size-fs23,
    .ql-size span[data-value='fs23']::before {
      font-size: 9.2px !important;
    }

    .ql-size-fs25,
    .ql-size span[data-value='fs25']::before {
      font-size: 10px !important;
    }

    .ql-size-fs26,
    .ql-size span[data-value='fs26']::before {
      font-size: 10.6px !important;
    }

    .ql-size-fs27,
    .ql-size span[data-value='fs27']::before {
      font-size: 11.2px !important;
    }

    .ql-size-fs28,
    .ql-size span[data-value='fs28']::before {
      font-size: 11.7px !important;
    }
  }
}

.carousel {
  .template-card {
    padding: 0;
  }
  .template-screen-edit-program {
    height: 680px;
    width: 400px;
    padding: 2rem;
    line-height: 1.5;

    p {
      margin-bottom: unset;
    }

    .ql-container {
      font-size: 16.8px;
    }

    /* Set content for sizes */
    .ql-size-fs12,
    .ql-size span[data-value='fs12']::before {
      font-size: 9.6px !important;
    }

    .ql-size-fs13,
    .ql-size span[data-value='fs13']::before {
      font-size: 10.4px !important;
    }

    .ql-size-small,
    .ql-size span[data-value='small']::before {
      font-size: 11.2px !important;
    }
    .ql-size-fs15,
    .ql-size span[data-value='fs15']::before {
      font-size: 12px !important;
    }

    .ql-size-smmedium,
    .ql-size span[data-value='smmedium']::before {
      font-size: 16px !important;
    }
    .ql-size-fs17,
    .ql-size span[data-value='fs17']::before {
      font-size: 13.6px !important;
    }
    .ql-size-medium,
    .ql-size span[data-value='medium']::before {
      font-size: 14.4px !important;
    }
    .ql-size-fs19,
    .ql-size span[data-value='fs19']::before {
      font-size: 15.2px !important;
    }
    .ql-size-fs20,
    .ql-size span[data-value='fs20']::before {
      font-size: 16px !important;
    }
    .ql-size-lgmedium,
    .ql-size span[data-value='lgmedium']::before {
      font-size: 16.8px !important;
    }
    .ql-size-fs22,
    .ql-size span[data-value='fs22']::before {
      font-size: 17.6px !important;
    }
    .ql-size-fs23,
    .ql-size span[data-value='fs23']::before {
      font-size: 18.4px !important;
    }
    .ql-size-large,
    .ql-size span[data-value='large']::before {
      font-size: 19.2px !important;
    }
    .ql-size-fs25,
    .ql-size span[data-value='fs25']::before {
      font-size: 20px !important;
    }
    .ql-size-fs26,
    .ql-size span[data-value='fs26']::before {
      font-size: 21.4px !important;
    }
    .ql-size-fs27,
    .ql-size span[data-value='fs27']::before {
      font-size: 22.4px !important;
    }
    .ql-size-fs28,
    .ql-size span[data-value='fs28']::before {
      font-size: 23.5px !important;
    }
    .ql-size-huge,
    .ql-size span[data-value='huge']::before {
      font-size: 25.6px !important;
    }
  }

  .template-screen-edit-program-preview {
    height: auto;
    width: 200px;
    padding: 1rem;
    line-height: 1 !important;

    &.preview-modal {
      width: 100%;
      height: 600px;
      color: $black;
    }
    &.leftpage-spacing {
      line-height: 1;
    }

    p {
      margin-bottom: unset;
    }

    .ql-container {
      font-size: 16.8px;
    }

    /* Set content for sizes */
    .ql-size-fs12,
    .ql-size span[data-value='fs12']::before {
      font-size: 9.6px !important;
    }

    .ql-size-fs13,
    .ql-size span[data-value='fs13']::before {
      font-size: 10.4px !important;
    }

    .ql-size-small,
    .ql-size span[data-value='small']::before {
      font-size: 11.2px !important;
    }
    .ql-size-fs15,
    .ql-size span[data-value='fs15']::before {
      font-size: 12px !important;
    }

    .ql-size-smmedium,
    .ql-size span[data-value='smmedium']::before {
      font-size: 16px !important;
    }
    .ql-size-fs17,
    .ql-size span[data-value='fs17']::before {
      font-size: 13.6px !important;
    }
    .ql-size-medium,
    .ql-size span[data-value='medium']::before {
      font-size: 14.4px !important;
    }
    .ql-size-fs19,
    .ql-size span[data-value='fs19']::before {
      font-size: 15.2px !important;
    }
    .ql-size-fs20,
    .ql-size span[data-value='fs20']::before {
      font-size: 16px !important;
    }
    .ql-size-lgmedium,
    .ql-size span[data-value='lgmedium']::before {
      font-size: 16.8px !important;
    }
    .ql-size-fs22,
    .ql-size span[data-value='fs22']::before {
      font-size: 17.6px !important;
    }
    .ql-size-fs23,
    .ql-size span[data-value='fs23']::before {
      font-size: 18.4px !important;
    }
    .ql-size-large,
    .ql-size span[data-value='large']::before {
      font-size: 19.2px !important;
    }
    .ql-size-fs25,
    .ql-size span[data-value='fs25']::before {
      font-size: 20px !important;
    }
    .ql-size-fs26,
    .ql-size span[data-value='fs26']::before {
      font-size: 21.4px !important;
    }
    .ql-size-fs27,
    .ql-size span[data-value='fs27']::before {
      font-size: 22.4px !important;
    }
    .ql-size-fs28,
    .ql-size span[data-value='fs28']::before {
      font-size: 23.5px !important;
    }
    .ql-size-huge,
    .ql-size span[data-value='huge']::before {
      font-size: 25.6px !important;
    }
  }
  .template-card-image {
    height: 680px;
    width: 400px;
  }
}
.template-screen-edit-program {
  .text-editor {
    position: relative;
    max-height: 414px;
    .ql-toolbar {
      position: absolute;
      width: max-content;
      top: -41px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      z-index: 5;
      left: 20%;
      background: white;
      .ql-font,
      .ql-size {
        border-right: 1px solid rgba(0, 0, 0, 0.3);
        padding-left: 1rem;
        padding-right: 1rem;
        width: 155px;
      }
      .ql-bold,
      .ql-italic,
      .ql-underline {
        width: 32px;
      }
      .ql-underline {
        border-right: 1px solid rgba(0, 0, 0, 0.3);
      }
      .ql-bold,
      .ql-align {
        margin-left: 0.5rem;
      }
      div {
        max-height: 420px;
      }
    }
    .quill {
      position: relative;
      .ql-container {
        border: none;
        .ql-editor {
          max-height: 820px;
          padding: 0 0.5rem;
          background-color: #fff;
          border: 1px solid #80bdff;
          @include box-shadow(1px 0px 5px 0 #80bdff);
          border-radius: 5px;
        }
      }
    }
  }
  .text-editor-temp-5 {
    position: relative;
    //max-height: 414px;
    .ql-toolbar {
      position: absolute;
      width: max-content;
      top: -41px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      z-index: 5;
      left: 20%;
      background: white;
      .ql-font,
      .ql-size {
        border-right: 1px solid rgba(0, 0, 0, 0.3);
        padding-left: 1rem;
        padding-right: 1rem;
        width: 155px;
      }
      .ql-bold,
      .ql-italic,
      .ql-underline {
        width: 32px;
      }
      .ql-underline {
        border-right: 1px solid rgba(0, 0, 0, 0.3);
      }
      .ql-bold,
      .ql-align {
        margin-left: 0.5rem;
      }
      div {
        max-height: 420px;
      }
    }
    .quill {
      position: relative;
      .ql-container {
        border: none;
        .ql-editor {
          max-height: 700px;
          padding: 0 0.5rem;
          background-color: #fff;
          border: 1px solid #80bdff;
          @include box-shadow(1px 0px 5px 0 #80bdff);
          border-radius: 5px;
        }
      }
    }
  }
  .template-string-wrapper {
    .hover-figure {
      display: inline-block;
      overflow: hidden;
      position: relative;
      text-align: center;
      width: 100%;
      &:before,
      &:after {
        background-color: rgba(255, 255, 255, 0.5);
        border-top: 2px solid rgba(0, 0, 0, 0.3);
        border-bottom: 2px solid rgba(0, 0, 0, 0.3);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        content: '';
        transition: all 0.3s ease;
        z-index: 1;
        opacity: 0;
        transform: scaleY(2);
      }
      .template-string {
        backface-visibility: hidden;
        word-break: break-word;
        p {
          margin-bottom: 0;
        }
      }
      figcaption {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        align-items: center;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 1.1em;
        opacity: 0;
        z-index: 2;
        transition-delay: 0.1s;
        font-size: 24px;
        font-weight: 400;
        letter-spacing: 1px;
        text-transform: uppercase;

        svg {
          stroke: rgba(0, 0, 0, 0.8);
        }
      }
    }
    .hover-figure * {
      box-sizing: border-box;
      transition: all 0.45s ease;
    }

    &.editable-border {
      //    border: 0.5px solid rgba(0, 0, 0, 0.3);
      box-sizing: border-box;

      .hover-figure {
        display: inline-block;
        overflow: hidden;
        position: relative;
        text-align: center;
        width: 100%;
        &:before,
        &:after {
          transform: scale(1);
          opacity: 1;
        }

        figcaption {
          opacity: 1;

          svg {
            stroke: rgba(0, 0, 0, 0.8);
          }
        }
      }
      .template-string {
        width: 100%;
        opacity: 0.7;
      }
    }
  }
}

.template-screen-edit-program-preview {
  .text-editor {
    position: relative;
    max-height: 414px;
    .ql-toolbar {
      position: absolute;
      width: max-content;
      top: -41px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      z-index: 5;
      left: 20%;
      background: white;
      .ql-font,
      .ql-size {
        border-right: 1px solid rgba(0, 0, 0, 0.3);
        padding-left: 1rem;
        padding-right: 1rem;
        width: 155px;
      }
      .ql-bold,
      .ql-italic,
      .ql-underline {
        width: 32px;
      }
      .ql-underline {
        border-right: 1px solid rgba(0, 0, 0, 0.3);
      }
      .ql-bold,
      .ql-align {
        margin-left: 0.5rem;
      }
      div {
        max-height: 420px;
      }
    }
    .quill {
      position: relative;
      .ql-container {
        border: none;
        .ql-editor {
          max-height: 820px;
          padding: 0 0.5rem;
          background-color: #fff;
          border: 1px solid #80bdff;
          @include box-shadow(1px 0px 5px 0 #80bdff);
          border-radius: 5px;
        }
      }
    }
  }
  .text-editor-temp-5 {
    position: relative;
    //max-height: 414px;
    .ql-toolbar {
      position: absolute;
      width: max-content;
      top: -41px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      z-index: 5;
      left: 20%;
      background: white;
      .ql-font,
      .ql-size {
        border-right: 1px solid rgba(0, 0, 0, 0.3);
        padding-left: 1rem;
        padding-right: 1rem;
        width: 155px;
      }
      .ql-bold,
      .ql-italic,
      .ql-underline {
        width: 32px;
      }
      .ql-underline {
        border-right: 1px solid rgba(0, 0, 0, 0.3);
      }
      .ql-bold,
      .ql-align {
        margin-left: 0.5rem;
      }
      div {
        max-height: 420px;
      }
    }
    .quill {
      position: relative;
      .ql-container {
        border: none;
        .ql-editor {
          max-height: 820px;
          padding: 0 0.5rem;
          background-color: #fff;
          border: 1px solid #80bdff;
          @include box-shadow(1px 0px 5px 0 #80bdff);
          border-radius: 5px;
        }
      }
    }
  }
  .template-string-wrapper {
    .hover-figure {
      display: inline-block;
      overflow: hidden;
      position: relative;
      text-align: center;
      width: 100%;
      &:before,
      &:after {
        background-color: rgba(255, 255, 255, 0.5);
        border-top: 2px solid rgba(0, 0, 0, 0.3);
        border-bottom: 2px solid rgba(0, 0, 0, 0.3);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        content: '';
        transition: all 0.3s ease;
        z-index: 1;
        opacity: 0;
        transform: scaleY(2);
      }
      .template-string {
        backface-visibility: hidden;
        word-break: break-word;
        p {
          margin-bottom: 0;
        }
      }
      figcaption {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        align-items: center;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 1.1em;
        opacity: 0;
        z-index: 2;
        transition-delay: 0.1s;
        font-size: 24px;
        font-weight: 400;
        letter-spacing: 1px;
        text-transform: uppercase;

        svg {
          stroke: rgba(0, 0, 0, 0.8);
        }
      }
    }
    .hover-figure * {
      box-sizing: border-box;
      transition: all 0.45s ease;
    }

    &.editable-border {
      //    border: 0.5px solid rgba(0, 0, 0, 0.3);
      box-sizing: border-box;

      .hover-figure {
        display: inline-block;
        overflow: hidden;
        position: relative;
        text-align: center;
        width: 100%;
        &:before,
        &:after {
          transform: scale(1);
          opacity: 1;
        }

        figcaption {
          opacity: 1;

          svg {
            stroke: rgba(0, 0, 0, 0.8);
          }
        }
      }
      .template-string {
        width: 100%;
        opacity: 0.7;
      }
    }
  }
}

.template-screen-edit-program-preview {
  height: 280px;
  padding: 1rem;
  line-height: 1 !important;

  div {
    flex-shrink: 1;
    flex-basis: auto;
  }

  p {
    margin-bottom: unset;
    word-break: break-word;
  }

  .ql-container {
    font-size: 7.2px;
  }

  /* Set content for sizes */
  .ql-size-fs12,
  .ql-size span[data-value='fs12']::before {
    font-size: 4.8px !important;
  }

  .ql-size-fs13,
  .ql-size span[data-value='fs13']::before {
    font-size: 5.2px !important;
  }

  .ql-size-small,
  .ql-size span[data-value='small']::before {
    font-size: 5.6px !important;
  }

  .ql-size-fs15,
  .ql-size span[data-value='fs15']::before {
    font-size: 6px !important;
  }

  .ql-size-smmedium,
  .ql-size span[data-value='smmedium']::before {
    font-size: 6.4px !important;
  }

  .ql-size-medium,
  .ql-size span[data-value='medium']::before {
    font-size: 7.2px !important;
  }

  .ql-size-lgmedium,
  .ql-size span[data-value='lgmedium']::before {
    font-size: 8.4px !important;
  }

  .ql-size-large,
  .ql-size span[data-value='large']::before {
    font-size: 9.6px !important;
  }

  .ql-size-huge,
  .ql-size span[data-value='huge']::before {
    font-size: 16px !important;
  }

  .ql-size-fs17,
  .ql-size span[data-value='fs17']::before {
    font-size: 6.8px !important;
  }

  .ql-size-fs19,
  .ql-size span[data-value='fs19']::before {
    font-size: 7.6px !important;
  }

  .ql-size-fs20,
  .ql-size span[data-value='fs20']::before {
    font-size: 8px !important;
  }

  .ql-size-fs22,
  .ql-size span[data-value='fs22']::before {
    font-size: 8.8px !important;
  }

  .ql-size-fs23,
  .ql-size span[data-value='fs23']::before {
    font-size: 9.2px !important;
  }

  .ql-size-fs25,
  .ql-size span[data-value='fs25']::before {
    font-size: 10px !important;
  }

  .ql-size-fs26,
  .ql-size span[data-value='fs26']::before {
    font-size: 10.6px !important;
  }

  .ql-size-fs27,
  .ql-size span[data-value='fs27']::before {
    font-size: 11.2px !important;
  }

  .ql-size-fs28,
  .ql-size span[data-value='fs28']::before {
    font-size: 11.7px !important;
  }
}
