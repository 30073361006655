.password-section{
    @media (min-width: 992px) {
        margin: 68.5px 0;
    }
    .positioned-back{
        position: relative;
        margin-bottom: 2rem;
        @media (min-width: 768px) {
            padding-left: 39px;
        }
        @media (max-width: 575px) {
            margin-bottom: 16px;
        }
        > a{
            top: 50%;
            left: 0;
            margin: 0;
            padding: 0;
            position: absolute;
            @include transform(translate(0, -50%));
            > i{
                color: $brand-primary;
                font-size: 0.9375rem;
            }
        }
        h4{
            color: $theme-text;
            margin: 0;
            font-size: 2rem;
            @extend .lato-bold;
        }
    }
    .password-description{
        font-size: 1rem;
        // margin-bottom: 4.5rem;
    }
}
@media (max-width: 767px) and (min-width: 320px) {
    .password-section{
        .positioned-back{
            h4{
                font-size: 1.125rem;
                margin-bottom: 1rem;
            }
        }
        .password-description{
            font-size: 0.875rem;
            margin-bottom: 2.25rem;
        }
    }
}