.obituary-modal-wrapper {
    // padding: 76px 188px;
    width: 65% !important;
    max-width: 100% !important;
    height: 90%;
    .tox.tox-tinymce {
        min-height: 500px !important;
    }

    .story-wrapper {
        width: 80%;
        padding: 1rem 0;
        .profile-wrapper {
            display: flex;
            .profile-picture-wrapper {
                width: 160px;
                margin-right: 1rem;
                img {
                    width: 160px;
                    height: 160px;
                }
            }
            .profile-thought-wrapper {
                width: calc(100% - 200px);
                font-family: Playfair Display;
                // font-style: normal;
                // font-weight: bold;
                font-size: 18px;
                line-height: 27px;
                color: #000000;
                padding-top: 1rem;
                span {
                    font-family: Playfair Display;
                    // font-style: italic;
                    // font-weight: normal;
                    font-size: 14px;
                    line-height: 27px;
                    color: black;
                }
                .options {
                    padding: 0;
                    width: 25px;
                    .selection {
                        border: 0.5px solid #2d9cdb;
                    }
                }
                a {
                    cursor: pointer;
                    // .BoldPic-wrapper {
                    //     top: 98px;
                    //     position: absolute;
                    //     right: 90px;
                    // }
                    // .ItalicPic-wrapper {
                    //     top: 130px;
                    //     position: absolute;
                    //     right: 90px;
                    // }
                    // .UPic-wrapper {
                    //     top: 164px;
                    //     position: absolute;
                    //     right: 90px;
                    // }
                }
                .container-root {
                    height: 211px;
                    background: white;
                    box-sizing: border-box;
                    // box-shadow: 2px 0px 7px rgba(0, 0, 0, 0.1);
                    border-radius: 8px;
                    &.profile-quote {
                        min-height: 127px;
                        height: fit-content;
                        // width: calc(100% - 25px);
                        padding: 0.5rem 1rem;
                        span {
                            font-size: 18px;
                        }
                        h1 {
                            line-height: 7px;
                        }
                    }
                }
                .hasFocus {
                    border: 0.5px solid #2d9cdb;
                }
                .noFocus {
                    border: #f1f1f1;
                }
                .errorFocus {
                    border: 0.5px solid red;
                }
            }
        }
        a {
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 27px;
            color: #27ae60;
            text-align: center;
            display: block;
        }
    }
    .modal-content {
        background-color: unset !important;
        border-radius: 5px;
        .modal-header {
            //background-color: transparent !important;
            padding: 0 !important;
            .close-icon-hg {
                color: white !important;
                // font-size: 24px !important;
            }
            .custom-close-button {
                width: 30px;
                height: 30px;
                z-index: 1;
                position: absolute;
                top:5px;
                right: 10px;
                cursor: pointer;
                object-fit: contain;
                color: #212529;
            }
        }
        .modal-body {
            width: 100%;
            border-radius: 5px;
            background-color: white;
            padding: 2rem 1rem !important;
            .modal-content {
                font-family: Inter;
                font-style: normal;
                padding: 2rem 3rem;
                font-weight: normal;
                font-size: 18px;
                line-height: 27px;
                color: #000000;
                a {
                    cursor: pointer;
                    .BoldPic-wrapper {
                        top: 68px;
                        position: absolute;
                        right: 55px;
                    }
                    .ItalicPic-wrapper {
                        top: 100px;
                        position: absolute;
                        right: 55px;
                    }
                    .UPic-wrapper {
                        top: 134px;
                        position: absolute;
                        right: 55px;
                    }
                }
                .container-root {
                    min-height: 200px;
                    background: #fcfcfc;
                    box-sizing: border-box;
                    // box-shadow: 2px 0px 7px rgba(0, 0, 0, 0.1);
                    border-radius: 8px;
                    &.obituary-quote {
                        width: calc(100% - 30px);
                        min-height: 400px;
                        height: fit-content;
                        padding: 0.5rem 1rem;
                    }
                }
                .hasFocus {
                    border: 0.5px solid #2d9cdb;
                }
                .noFocus {
                    border: #f1f1f1;
                }
                .errorFocus {
                    border: 0.5px solid red;
                }
            }
        }
    }
}
.profile-section-wrapper {
    margin-top: 60px !important;
}
@media (max-width: 996px) and (min-width: 768px) {
    .obituary-modal-wrapper {
        width: 100%;
        padding: 15px;
        height: auto;
        .modal-content {
            .modal-body {
                padding: 16px !important;
                min-height: auto;
                height: auto;
                .modal-content {
                    .container-root {
                        &.obituary-quote {
                            width: 100%;
                            min-height: 250px;
                            height: 240px !important;
                        }
                    }
                }
            }
        }
        .story-wrapper {
            width: 100%;
            padding: 0;
            .profile-wrapper {
                display: block;
                .profile-picture-wrapper {
                    width: 100%;
                    margin-right: 0;
                    img {
                        margin: 0 auto;
                        display: block;
                    }
                }
                .profile-thought-wrapper {
                    padding: 10px 0;
                    margin: 0 auto;
                }
            }
        }
    }
}

@media (max-width: 767px) and (min-width: 320px) {
    .obituary-modal-wrapper {
        width: 100%;
        padding: 15px;
        height: auto;
        .modal-content {
            .modal-body {
                padding: 16px !important;
                min-height: auto;
                height: auto;
                .modal-content {
                    padding: 0;
                    font-size: 12px;
                    line-height: 18px;
                    .container-root {
                        &.obituary-quote {
                            width: 100%;
                            min-height: 250px;
                            height: 240px !important;
                        }
                    }
                }
            }
        }
        .story-wrapper {
            width: 100%;
            padding: 0;
            .profile-wrapper {
                display: block;
                .profile-picture-wrapper {
                    width: 100%;
                    margin-right: 0;
                    img {
                        width: 75px;
                        height: 75px;
                        margin: 0 auto;
                        display: block;
                    }
                }
                .profile-thought-wrapper {
                    padding: 10px 0;
                    width: 100%;
                    margin: 0 auto;
                    .container-root {
                        &.profile-quote {
                            span {
                                line-height: 12px;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
    .profile-section-wrapper {
        margin-top: 0px !important;
    }
}
@media (max-width: 576px) and (min-width:250px) {
    .mobile-view-wrapper{
        width: 100% !important;
    }
}
