.share-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
.share-memory-heading {
    margin-left: 10px;
    margin-top: 25px;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 1.25rem;
    //line-height: 40px;
    color: #000000;
}
.share-memory-container{
    display:flex;
    align-items:center;
}
.note-wrapper {
    font-family: "Lato";
    font-style: italic;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: $theme-note-color;
    margin-right: 20px;
}
    .cancel-button-memory {

        text-transform: uppercase;
            color: #000000;
            cursor: pointer;
            border: 0;
            padding: 15px 10px;
            font-size: 10px;
            line-height: 1.43;
            letter-spacing: 0.2px;
            font-family: "Lato";
            font-style: normal;
            font-size: 0.875rem;
            padding-left: 10px;
            width: 90px;
            background-color: rgb(224, 224, 224);
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
            border-radius: 6px;
            margin-right: 10px;
    }
    .share-button {
        text-transform: uppercase;
            color: #fff;
            cursor: pointer;
            border: 0;
            padding: 15px 10px;
            font-size: 10px;
            line-height: 1.43;
            letter-spacing: 0.2px;
            font-family: "Lato";
            font-style: normal;
            font-size: 0.875rem;
            padding-left: 10px;
            width: 90px;
            background-color: #337a2e;
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 10px;
            border-radius: 6px;
            &:disabled,
        &.disabled {
            background-color: #60cf80;
            cursor: not-allowed;
        }
    }
}
.cardboxs-wrapper {
    @include box-shadow(0 3px 10px 0 rgba(144, 137, 137, 0.18));
    margin-bottom: 30px;
    .share-memory-title {
        width: 100% !important;
    }
    .tinymce-container {
        padding: 40px 20px;
        .error-memory-input-msg {
            left: unset;
            color: red;
            overflow: hidden;
            font-size: 14px;
            line-height: 16px;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    .memory-label-text {
        white-space: normal !important;
        font-size: 14px;
        line-height: 19px;
        margin-bottom: 10px;
    }
    .cardbox-heading {
        height: 58px;
        padding: 0 40px;
        position: relative;
        background-color: $theme-table-nth;
        @include flex(space-between, center);

        @include box-shadow(0 3px 10px 0 rgba(144, 137, 137, 0.18));
        .cardbox-heading-name {
            color: $brand-primary;
            margin: 0;
            font-size: 20px;
            line-height: 24px;

            @extend .lato-bold;
        }
        .cardbox-heading-right-text {
            color: $brand-primary;
            margin: 0;
            font-size: 18px;
            line-height: 24px;
            font-weight: 400;

            @extend .lato-bold;
        }
    }
    .memory-gallery {
        padding: 20px;
        padding-left: 35px;
        .upload-wrapper {
            .dropzone {
                width: 100%;
                height: auto;
                left: 0px;
                top: 0px;
                background: #FFFFFF;
                border: 1px dashed rgba(0, 0, 0, 0.3);
                box-sizing: border-box;
                border-radius: 4px;
                padding: 10px;
                cursor: pointer;
                p {
                    margin-top: 80px;
                    font-family: Lato;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 22px;
                    text-align: center;
                    color: rgba(0, 0, 0, 0.6);
                    .upload-picture {
                        width: 163px;
                        height: 22px;
                        left: 489px;
                        top: 104px;
                        font-family: Lato;
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 22px;
                        text-decoration-line: underline;
                        color: #298A45;
                        padding-left: 30px;
                        white-space: nowrap;
                    }
                }
                .add-image {
                    width: 106px;
                        height: 103px;
                        position: relative;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        margin: 20px;
                        background: #C4C4C4;
                        border-radius: 8px;
                        align-items: center;
                        span {
                            display: table;
                            margin-top: 25px;
                            margin-right: auto;
                            margin-bottom: 0px;
                            margin-left: auto;
                            width: 2;
                            font-size: 30px;
                        }
                }
                    .upload-content-wrapper {
                        max-width: 106px;
                        height: 103px;
                        position: relative;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        margin: 20px;
                        span {
                            font-family: Lato;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 14px;
                            white-space: nowrap;
                            max-width: 103px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            color: rgba(0, 0, 0, 0.5);
                        }
                        .removeFile {
                            position: absolute;
                            top: -7px;
                            right: -7px;
                            border: none;
                            background-color: white;
                        }
                        .modal-close {
                            color: white;
                            background-color: black;
                            height: 16px;
                            width: 16px;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-content: center;
                            flex: 1;
                        }
                    }
            }
        }
        .video-upload {
            width: 100%;
            height: auto;
            left: 0px;
            top: 262px;
            background: #FFFFFF;
            border: 1px solid rgba(0, 0, 0, 0.15);
            box-sizing: border-box;
            border-radius: 4px;
            padding: 0px 10px;
            .label-input {
                margin-top: 50px;
                font-family: Lato;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                text-align: center;
                color: #000000;
            }
            // .inputcard-wrapper {
                // .inputcard-body {
                //     &.custom-inputcard-body {
                        .inputcard-group {
                            padding: 0;
                            .choose-reason {
                                width: 34.33%;
                                padding: 0;
                                padding-right: 48px;
                                padding-bottom: 48px;
        
                                &.full-width {
                                    padding-right: 0;
                                    padding-bottom: 30px;
                                    width: 100% !important;
                                }
        
                                &:nth-of-type(3) {
                                    padding-right: 0;
                                    width: 31.34%;
                                }
                                &:nth-of-type(4) {
                                    padding-right: 0;
                                    width: 100%;
                                }
                                &:nth-of-type(5) {
                                    padding-right: 0;
                                    width: 100%;
                                }
        
                                .inputtag-field {
                                    .error-msg {
                                        color: red;
                                        font-size: 14px;
                                    }
                                    margin-bottom: 30px;
                                    .add-video-button {
                                    text-transform: uppercase;
                                    color: #fff;
                                    cursor: pointer;
                                    border: 0;
                                    padding: 15px 10px;
                                    font-size: 10px;
                                    line-height: 1.43;
                                    letter-spacing: 0.2px;
                                    font-family: "Lato";
                                    font-style: normal;
                                    font-size: 12px;
                                    padding-left: 10px;
                                    width: 110px;
                                    background-color: #337a2e;
                                    height: 55px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    /* margin-top: 10px; */
                                    border-radius: 6px;
                                    }
        
                                    .input-tag {
                                        background: white;
                                        border: 1px solid #979797;
                                        border-radius: 4px;
                                        display: flex;
                                        flex-wrap: wrap;
                                        padding: 5px 5px 0;
                                        // width: 700px;
                                        max-height: 100px;
                                        overflow-y: scroll;
                                        margin-right: 10px;
                                    }
        
                                    .input-tag input {
                                        border: none;
                                        width: 700px;
                                    }
        
                                    .input-tag__tags {
                                        display: inline-flex;
                                        flex-wrap: wrap;
                                        margin: 0;
                                        padding: 0;
                                        width: 700px;
                                    }
        
                                    .input-tag__tags li {
                                        align-items: center;
                                        background: rgba(132, 176, 132, 0.4);
                                        border-radius: 4px;
                                        color: #000000;
                                        display: flex;
                                        font-weight: 300;
                                        list-style: none;
                                        margin-bottom: 5px;
                                        margin-right: 5px;
                                        padding: 0px 10px;
                                        height: 45px;
                                    }
        
                                    .input-tag__tags li button {
                                        align-items: center;
                                        appearance: none;
                                        background: #333333;
                                        border: none;
                                        border-radius: 50%;
                                        color: white;
                                        cursor: pointer;
                                        display: inline-flex;
                                        font-size: 12px;
                                        height: 15px;
                                        justify-content: center;
                                        line-height: 0;
                                        margin-left: 8px;
                                        padding: 0;
                                        transform: rotate(45deg);
                                        width: 15px;
                                    }
        
                                    .input-tag__tags li.input-tag__tags__input {
                                        background: none;
                                        flex-grow: 1;
                                        padding: 0;
                                        input {
                                            height: 45px;
                                        }
                                    }
                                }
                                .addVideoLink {
                                    margin-left: 0px !important;
                                }
                                .inputcard-withlabel {
                                    margin-left: 30px;
                                    .memory-input {
                                        height: 200px;
                                        display: block;
                                        padding: 1rem;
                                        width: 100%;
                                        background-color: #fff;
                                        background-clip: padding-box;
                                        border: 1px solid #979797;
                                        border-radius: 0.25rem;
                                        transition: border-color 0.15s ease-in-out,
                                            box-shadow 0.15s ease-in-out;
        
                                        color: #212121 !important;
                                        line-height: 26px;
        
                                        -webkit-writing-mode: horizontal-tb !important;
                                        text-rendering: auto;
                                        letter-spacing: normal;
                                        word-spacing: normal;
                                        text-transform: none;
                                        text-indent: 0px;
                                        text-shadow: none;
                                        appearance: textfield;
                                        -webkit-rtl-ordering: logical;
                                        cursor: text;
                                    }
                                    .error-memory-input-msg {
                                        margin-top: 10px;
                                        left: 0;
                                        color: red;
                                        display: block;
                                        overflow: hidden;
                                        /* position: absolute; */
                                        font-size: 14px;
                                        line-height: 16px;
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                    }
                                }
                            }
        
                            .thumbnail-wrapper {
                                width: 50%;
                                margin: 1rem;
                                border-radius: 8px;
        
                                .thumbnail-image {
                                    height: 100px;
                                    border-radius: 8px;
        
                                    img {
                                        max-width: unset;
                                        height: 100%;
                                        border-top-left-radius: 8px;
                                        border-bottom-left-radius: 8px;
                                    }
        
                                    svg {
                                        fill: dimgrey;
                                        background-color: lightgray;
                                        border: 5px solid gray;
                                        padding: 15px;
                                        max-width: unset;
                                        height: 100px;
                                        border-top-left-radius: 8px;
                                        border-bottom-left-radius: 8px;
                                    }
                                }
        
                                .video-content {
                                    background-color: #f1f1f1;
                                    border-top-right-radius: 8px;
                                    border-bottom-right-radius: 8px;
                                    .content {
                                        padding: 1rem 0.5rem 1rem 1rem;
                                        min-width: 220px;
                                    }
                                    .close {
                                        align-items: center;
                                        padding: 1rem 1rem 1rem 0.5rem;
                                    }
                                }
                            }
        
                            &.personal-details-wrapper {
                                .choose-reason {
                                    width: 34.33%;
                                    padding: 0;
                                    padding-right: 48px;
                                    padding-bottom: 48px;
                                    margin-bottom: 0;
                                    &:nth-of-type(3) {
                                        padding-right: 0;
                                        width: 31.34%;
                                    }
                                    &:nth-of-type(6) {
                                        padding-right: 0;
                                        width: 31.34%;
                                    }
                                    &:nth-of-type(9) {
                                        padding-right: 0;
                                        width: 31.34%;
                                    }
                                }
                            }
                        }
        
                        .label-input {
                            color: rgba(0, 0, 0, 0.5);
                            padding: 33px 0 25px;
                            margin: 0;
                            cursor: pointer;
                        }
                //     }
                // }
                
                .inputcard-footer {
                    padding: 21px 0 84px;
                    .btn-cancel-wrapper {
                        margin-right: 20px;
                        background: $brand-default;
                        color: $theme-cancel-btn-bg;
                    }
                }
            // }
        }
    }
    .cardbox-body {
        padding: 0 20px 24px;
        background-color: $white;
        &.cardbox-body-text {
            padding: 1.5rem 2.5rem;
        }
        .MuiSwitch-colorSecondary.Mui-checked {
            color: #1f870e;
        }
        .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
            background-color: #1f870e;
        }
    }
}
.terms-wrapper{
    padding-top:25px;
    .terms-container{
        height:250px;
        overflow-y: auto;
        margin-bottom:20px;
        padding:20px;
        border-bottom: 2px solid lightgray;
        &::-webkit-scrollbar {
            width:7px;
            border-radius: 5px;
        }
        &::-webkit-scrollbar-track {
            @include box-shadow(inset 0 0 4px rgba(0, 0, 0, 0.3));
            border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 1px solid slategrey;
            border-radius: 5px;
        }  
    }
    .terms-popup{
        text-align: center;
        margin-bottom: 20px;
        color:#007bff;
        cursor:pointer;
        font-size:16px;
    }
    .terms-header{
        font-size: 22px;
        text-align: center;
        font-weight: bold;
        padding-top:25px;
        margin-bottom:10px;
    }
}

.check-box-container{
    padding: 0px 20px;
    display:flex;
    align-items: center;
    margin-bottom: 10px;
    .checkbox-input{
        color:#000;
        font-size:16px;
        margin:0;
    }
    .checkbox{
        cursor: pointer;
        margin-right: 10px;
    }
}

.custom-search {
    position: relative;

    input {
        height: 55px;
        padding-left: 40px;
        font-size: 16px;
        line-height: 19px;
        color: $theme-placeholder-text;
        border: 1px solid $theme-placeholder-text;
        border-radius: 4px;
    }

    .fa-search {
        color: $theme-placeholder-text;
        position: absolute;
        z-index: 1;
        left: 16px;
        top: 50%;
        font-size: 18px;

        @include translate(0, -50%);
    }
}

// Auto Complete Wrapper

.custom-autocomplete-wrapper {
    width: 100%;
    display: block;
    position: relative;
    &.show-autocomplete-menu {
        // z-index: 1;
        .custom-autocomplete-menu {
            opacity: 1;
            width: 100%;
            @include transform(scale(1));
        }
    }
    input {
        background-color: transparent;
    }
    .custom-autocomplete-menu {
        opacity: 0;
        z-index: 1;
        position: absolute;
        max-width: 522px;
        max-height: 205px;
        overflow-y: auto;
        background-color: $white;
        transform-origin: top left;
        @include transform(scale(0));
        @include box-shadow(0 7px 10px 0 rgba(0, 0, 0, 0.19));
        @include transition(all 0.3s ease);
        .custom-autocomplete-menulist {
            height: 59px;
            cursor: pointer;
            padding-left: 31px;
            &:not(:last-child) {
                border-bottom: 1px solid $gainsboro;
            }
            span {
                color: $primary-text-color;
                font-size: 13px;
                line-height: 16px;
                &.create-new-organization {
                    color: $brand-primary;
                }
            }
        }
    }
}

// Custom Form inputs styles Starts

@media (max-width: 767px) and (min-width:250px) {
    @media (max-width: 767px) and (min-width: 250px) {
        .add-video-button {
            width: 60px !important;
            height: 30px !important;
            padding: 20px !important;
            font-weight: 10px !important;
        }
        .successalert-card {
            margin: 40px auto 0;
            padding: 13px 14px 13px 30px;
            max-width: none;
            @include flex(flex-start, center);
            > i.fas {
                top: 12px;
                left: 6px;
                font-size: 18px;
            }
            > span {
                font-size: 12px;
                > a {
                    padding-left: 5px;
                    text-decoration: underline;
                }
            }
            + .resend-wrapper {
                margin: 16px 0 0;
                text-align: center;
            }
        }
        // Custom Card inputs styles Starts
        .cardboxs-wrapper {
            .cardbox-heading {
                height: 50px;
                padding: 0 16px;
                .cardbox-heading-name {
                    font-size: 12px;
                    line-height: 14px;
                    font-weight: 600 !important;
                }
            }
            .memory-gallery {
                padding: 20px 0px;
                .upload-wrapper {
                    .dropzone {
                    width: 100%;
                    }
                }
                .video-upload {
                    width: 100%;
                    .inputcard-group {
                        width: 100%;
                        .thumbnail-wrapper {
                            display: flex !important;
                            flex-direction: column;
                            width: 100%;
                            margin: 0px;
                            .video-content {
                                width: 100%;
                                .content {
                                    padding: 1rem 0.5rem 1rem 1rem;
                                    width:100%;
                                }
                            }
                        }
                    }
                }
            }
            .cardbox-body {
                padding: 0 20px 24px;
                &.cardbox-body-text {
                    padding: 0.75rem;
                }
                .MuiSwitch-colorSecondary.Mui-checked {
                    color: #1f870e;
                }
                .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
                    background-color: #1f870e;
                }
            }
            .tinymce-container {
                padding: 40px 0px;
            }
        }
        .share-div {
            .share-memory-heading {
                //margin-right: 630px;
                margin-top: 25px;
                font-family: Lato;
                font-style: normal;
                font-weight: 600;
                font-size: 1rem;
                //line-height: 40px;
                color: #000000;
            }
            .cancel-button-memory,.share-button {
                font-size: 0.75rem;
            }
            
        }
    
        // Custom Form inputs styles Starts
        .inputcard-wrapper {
            .inputcard-body {
                &.custom-inputcard-body {
                    width: 100%;
                    .inputcard-group {
                        padding: 30px 0 0;
    
                        &.two-cardbox {
                            .choose-reason {
                                width: 100%;
                            }
                        }
    
                        .choose-reason {
                            width: 100%;
                            padding: 0;
                            &:not(:last-child) {
                                padding: 0 0 30px 0;
                            }
    
                            &.inputfield {
                                .custominputfield {
                                    width: 100%;
                                    height: 39px;
                                    padding: 0 1rem;
                                    font-size: 12px;
                                    line-height: 14px !important;
                                }
                                .custominputfieldfortext {
                                    width: 100%;
                                    height: 223px;
                                    border: 1px solid #979797;
                                    font-size: 12px;
                                    line-height: 14px !important;
                                    @include border-radius(4px);
                                    padding: 20px;
                                }
                                .custominputfieldforMemory {
                                    width:100%;
                                    border: 1px solid #979797;
                                    padding: 0 1rem;
                                    font-size: 16px;
                                    @include border-radius(4px);
                                }
                            }
                            &.textareafield {
                                height: 120px;
                                &.textareafield-label {
                                    height: auto;
                                    > .inputcard-withlabel {
                                        height: auto;
                                    }
                                    .customtextareafield {
                                        font-size: 14px;
                                    }
                                }
                            }
    
                            > span {
                                font-size: 14px;
                                line-height: 17px;
                                white-space: nowrap;
                            }
    
                            .inputcard-withlabel {
                                width: 100%;
                                position: relative;
    
                                &.add-new-feature-wrapper {
                                    .add-new-feature {
                                        font-size: 12px;
                                        line-height: 19px;
                                    }
                                }
                                input {
                                    height: 39px;
                                    font-size: 12px;
                                    line-height: 14px;
                                }
                                .inputcard-label {
                                    font-size: 14px;
                                    line-height: 19px;
                                    margin-bottom: 10px;
                                    .input-mandatory {
                                        line-height: 14px;
                                    }
                                }
                                .select-wrapper {
                                    position: relative;
    
                                    .select-dropdown__control {
                                        .select-dropdown__value-container {
                                            height: 37px;
    
                                            .select-dropdown__placeholder {
                                                font-size: 12px;
                                                line-height: 14px;
                                            }
    
                                            .select-dropdown__single-value {
                                                font-size: 14px;
                                            }
    
                                            .select-dropdown__input {
                                                > input {
                                                    font-size: 14px !important;
                                                }
                                            }
                                        }
                                    }
    
                                    .select-dropdown__menu {
                                        .select-dropdown__menu-list {
                                            .select-dropdown__option {
                                                height: 40px;
                                                padding: 8px 16px;
                                                font-size: 14px;
                                            }
                                        }
                                    }
                                }
                            }
    
                            &.multiselect-headings {
                                .multiselect-dropdown-container {
                                    width: 100%;
    
                                    .dropdown-heading {
                                        height: 39px !important;
    
                                        .dropdown-heading-value {
                                            height: 37px !important;
                                            padding: 0 16px !important;
    
                                            > span {
                                                font-size: 14px;
                                                line-height: 19px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
    
                    .common-address-wrapper {
                        .inputcard-body {
                            .inputcard-group {
                                .choose-reason-address {
                                    &:not(:last-of-type) {
                                        margin-bottom: 0px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
    
            .inputcard-footer {
                padding: 24px 0px 0;
    
                @include flex(flex-end, center);
            }
        }
        .btn-theme {
            padding: 15px 10px;
            font-size: 12px;
            font-weight: 400 !important;
            min-width: 75px;
            min-height: 34px;
            line-height: 14px;
            margin: 10px 0;
        }
        .back-btn-section {
            display: none;
        }
    }
}
@media (max-width: 480px) and (min-width: 320px) {
    .inputcard-wrapper {
        .inputcard-footer {
            .btn-search-wrapper {
                width: 100%;
                padding: 0;
                max-width: none;
                min-height: 38px;
            }
        }
    }
    .cardboxs-wrapper {
        .memory-gallery {
            .video-upload {
                width: 100%;
                .inputcard-group {
                    width: 100%;
                    .thumbnail-wrapper {
                        display: flex !important;
                        flex-direction: column;
                        .video-content {
                            width: 100%;
                            .content {
                                padding: 1rem 0.5rem 1rem 1rem;
                                width:100%;
                            }
                        }
                    }
                }
            }
        }
    }
    .share-div {
        .share-memory-heading {
            margin-right: 10px;
            margin-top: 25px;
            font-family: Lato;
            font-style: normal;
            font-weight: 600;
            font-size: 1rem;
            //line-height: 40px;
            color: #000000;
        }
        .cancel-button-memory,.share-button {
            font-size: 0.75rem;
        }
        .note-wrapper {
            margin-bottom: 20px;
            font-size: 16px;
        }
    }
    .back-btn-section {
        display: none;
    }
}