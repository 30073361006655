.main-content-wrapper {
    .navigationTop-content-wrapper {
        top: 0;
        height: 65px;
        z-index: 150;
        position: sticky;
        background-color: $white;
        @include box-shadow(0 4px 15px rgba($black, 0.1));
        + .loader-section {
            + .navigationBottom-content-wrapper {
                margin-top: calc(100vh - 65px - 137px);
            }
        }

        + .navigationBottom-content-wrapper {
            margin-top: calc(100vh - 65px - 137px);
        }
    }
    .navigationCenter-content-wrapper {
        padding-bottom: 24px;
        min-height: calc(100vh - 65px - 130px);
        @media (max-width: 991px) and (min-width: 768px) {
            min-height: calc(100vh - 65px - 301px);
        }
        .banner-avatar-editor {
            border: 1px solid black;
        }
        .mainBannerImageContainer {
            .container {
                max-width: 1500px !important;
                padding: 0px !important;
            }
           
            .avatar-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                .cancelSaveOptionsContainer {
                    margin-bottom: 6px;
                }
            }
        }
        .landing-banner-wrapper {
            position: relative;
            display: block;
            
            &.banner-unloaded {
                background: url("/assets/gifs/spinning-loading.gif")
                    no-repeat;
                background-position: center;
                background-size: contain !important;
            }

            &.banner-loaded {
                background: url("/assets/images/CLFAA-banner.JPG") no-repeat;
                background-size: cover !important;
            }
            .displayLoader {
                height: 350px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .bannerImage{
                object-fit: cover;
                height: 350px;
                width: 100%;
            }
            .deafultBannerImage {
                width : 1500px;
                height: 350px;
                object-fit: fill;
            }
            .landing-banner-decedentdetails {
            //    width: 100%;
            //    height: 350px;
            //    z-index: 1;
            //    position: relative;
                top:92px;
                left:182px;
                position: absolute;
                background-position: bottom center;
                background-size: cover !important;

               
                .primaryForm-save {
                    position: absolute;
                    bottom: 10px;
                    right: 10px;
                    cursor: pointer;
                    font-size: 32px;

                    &:hover {
                        font-size: 36px;
                    }
                    .update-banner {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: white;
                        font-size: 12px;
                        padding: 7px;
                        margin-bottom: 16px;
                        margin-right: 24px;
                        .camera-icon {
                            margin: 0;
                            margin-right: 7px;
                            height: 19px;
                            width: 15px;
                        }
                        p {
                            margin: 0;
                        }
                    }
                }

                
                .Icons {
                    border: none;
                }
                .editBannerImage {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: white;
                    font-size: 12px;
                    padding: 7px;
                    position: absolute;
                    top: 10px;
                    margin-right: 24px;
                    right: 0px;
                }
                .downloadBannerImage {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: white;
                    font-size: 12px;
                    padding: 7px;
                    position: absolute;
                    top: 45px;
                    margin-right: 24px;
                    right: 0px;
                }
                .edit-icon {
                    margin: 0px;
                    margin-right: 7px;
                }
                .download-icon {
                    margin: 0px;
                    margin-right: 7px;
                }
                .description {
                    margin: 0px;
                }
                .static-text{
                    // bottom:18px;
                    // right:185px;
                }

                @include flex(flex-start, center);
                    .landing-banner-decedent {
                        background-color: rgba($black, 0.5);
                        .dashboard-card-decedentinfo {
                            padding: 21px;
                            .dashboard-card-name {
                                color: $white;
                                margin: 0;
                                font-size: 24px;
                                line-height: 36px;
                                @extend .lato-bold;
                            }
                            .dashboard-card-keyvalue {
                                color: $white;
                                margin: 0;
                                font-size: 18px;
                                margin-top: 13px;
                                .dashboard-card-key {
                                    white-space: nowrap;
                                }
                                .dashboard-card-value {
                                    padding-left: 5px;
                                }
                            }
                        }
                    }
                
            }
            .banner-info{
                position: absolute;
                bottom:18px;
                // right:85px;
                right: 72px;
                cursor:pointer;
                // font-size:36px;
            }
            .toolTipButton {
                height: 28px;
                width: 28px;
                border: none;
                border-radius: 50%;
            }
            .optionsMenuContainer {
                position: absolute;
                // top: 18px;
                bottom: 18px;
                right: 19px;
            }
        }
        .navigationCenter-header-wrapper {
            margin: 24px 0;
            padding: 16px;
            background-color: $white;
            .navigationCenter-header-name {
                margin-top: 8px;
                h4 {
                    color: $brand-primary;
                    margin: 0;
                    font-size: 32px;
                    line-height: 40px;
                    @extend .lato-bold;
                }
            }
        }
    }
    .navigationBottom-content-wrapper {
        height: 130px;
        @media (max-width: 991px) and (min-width: 768px) {
            height: auto;
            background-color: $white;
        }
        background-color: $white;
    }
}

.disable-cursor {
    cursor: not-allowed;
}

.disable-link{
    pointer-events: none;
}

.modal-dialog{
    &.banner-modal-wrapper{
        width:95%;
        max-width:1200px;
        @media (max-width:767px){
            display:none;
        }
        .modal-content{
            background: unset !important;
            .modal-header{
                //background-color: transparent !important;
                padding: 0px !important;
                .close-icon-hg{
                    color: white !important;
                }
                .custom-close-button {
                    width: 30px;
                    height: 30px;
                    z-index: 1;
                    position: absolute;
                    top:5px;
                    right: 8px;
                    cursor: pointer;
                    object-fit: contain;
                    color: #212529;
                }
                .modal-close{
                    right: -30px !important;
                }
            }
            .modal-body{
                padding:20px 0 !important;
                background-color: white;
                border-radius: 5px;
            }
        }
        .avatar-editor{
            border: 1px solid black;
            overflow-x: auto;
            margin-top: 20px;
            margin-right:10px;
            margin-left: 10px;
            padding: 0px !important;
        }
    }
}

@media (max-width: 996px) {
    .main-content-wrapper {
        .navigationCenter-content-wrapper {
            .landing-banner-wrapper {
                .landing-banner-decedentdetails {
                    .container {
                        .landing-banner-decedent {
                            .dashboard-card-decedentinfo {
                                .dashboard-card-name {
                                    font-size: 18px;
                                    line-height: 24px;
                                }
                                .dashboard-card-keyvalue {
                                    font-size: 16px;
                                    margin-top: 13px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .main-content-wrapper {
        .navigationTop-content-wrapper {
            height: 58px;

            + .loader-section {
                + .navigationBottom-content-wrapper {
                    margin-top: calc(100vh - 58px - 305px);
                }
            }

            + .navigationBottom-content-wrapper {
                margin-top: calc(100vh - 58px - 305px);
            }
        }
        .navigationCenter-content-wrapper {
            min-height: calc(100vh - 58px - 301px);
            .landing-banner-wrapper {
                display: none;
                .landing-banner-decedentdetails {
                    height: auto;
                    .container {
                        .landing-banner-decedent {
                            margin-top: 16px;
                            .dashboard-card-decedentinfo {
                                padding: 12px;
                                .dashboard-card-name {
                                    font-size: 16px;
                                    line-height: 18px;
                                }
                                .dashboard-card-keyvalue {
                                    margin: 0;
                                    font-size: 14px;
                                    margin-top: 8px;
                                    line-height: 17px;
                                }
                            }
                        }
                    }
                }
            }
            .navigationCenter-header-wrapper {
                margin: 16px 0;
                padding: 7px 12px;
                .navigationCenter-header-name {
                    margin: 0;
                    h4 {
                        font-size: 18px;
                        line-height: 22px;
                    }
                }
            }
        }
        .navigationBottom-content-wrapper {
            height: auto;
            background-color: $white;
        }
    }
}
